

// import { IndexClient } from "candb-client-typescript/dist/IndexClient";

import { /*Actor,*/ randomNumber } from "@dfinity/agent";

// import * as agent_1 from "@dfinity/agent";
import * as createActor_1 from "candb-client-typescript/dist/createActor";
import MailAddressUtils from "../utils/MailAddressUtils";
import { MailEncryptionType } from "../utils/Types";
import { mailAddressDomain, mailAddressSubdomain, mailAddressSuffix, mailAddressToSignalIdentity, signalIdentityToMailAddress } from "../common/constants";

// import { idlFactory as IndexCanisterIDL } from "../../../declarations/pleximail_index/index";
// import { idlFactory as PlexiMailServiceCanisterIDL } from "../../../declarations/pleximail_backend/index";
// import { IndexCanister } from "../../../declarations/pleximail_index/pleximail_index.did";
// import { PlexiMailService } from "../../../declarations/pleximail_backend/pleximail_backend.did";


import * as vetkd from "ic-vetkd-utils";
import { Actor } from "@dfinity/agent";
import { Principal } from "@dfinity/principal";
// import { idlFactory as IndexCanisterIDL } from "../../../declarations/pleximail_index/index";
// import { idlFactory as PlexiMailServiceCanisterIDL } from "../../../declarations/pleximail_backend/index";
// import { IndexCanister } from "../../../declarations/pleximail_index/pleximail_index.did";
// import { PlexiMailService } from "../../../declarations/pleximail_backend/pleximail_backend.did";


const hex_decode = (hexString) =>
    Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
const hex_encode = (bytes) =>
    bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');

const vkd = vetkd;

vkd.default();
export class PlexiMailService {

    static CRYPTON_PREFIX = 'crypton.';
    static MAILLIST_PREFIX = 'maillist.';
    static ENVELOPE_PREFIX = "envelope.";
    static USER_PREFIX = 'user.';
    static DEVICE_PREFIX = 'devices.';

    #indexClient = null;
    #plexiMailServiceClient = null;
    #group = null;
    #principal = null;
    actorCacheMap = {
        
    };
    #vetkeysCanister = null;
    #isRegistered = false;
    #canisterIBelong = null;
    #enterpriseResourceCanister = null;

    constructor(principal, group, indexClient, plexiMailServiceClient) {
        this.#principal = principal;
        this.#group = group;

        this.#indexClient = indexClient;

        this.#plexiMailServiceClient = plexiMailServiceClient;
        
    }

    getPrincipal() {
        return this.#principal;
    }


    async saveBusinessEntity(entity) {
        const newEntity = {
            name: entity.name,
            address: entity.address || '',
            tel: entity.tel || '',
            tex: entity.tex || '',
            email: entity.email || '',
            zip: entity.zip || '',
            domain: entity.domain,
            capacity: entity.capacity,
            features: entity.features || ['mail', 'contract', 'multi-address', 'contacts', 'notes']
        };
        const success = await this.#indexClient.indexCanisterActor.saveBusinessEntity(entity.domain, newEntity);
        return success;
    }
    
    async getBusinessEntity(domain) {
        const entity = await this.#indexClient.indexCanisterActor.getBusinessEntity(domain);
        return entity;
    }
    async createCanisterByGroupIfNeeded() {
        const result = await this.#indexClient.indexCanisterActor.createPlexiMailServiceCanisterByGroup(this.#group);
        return result;
    }
    async beginVerifyingDomain(domain) {
        const result = await this.#indexClient.indexCanisterActor.beginVerifyingDomain(domain);
        return result;
    }
    async verifyDomain(domain) {
        const result = await this.#indexClient.indexCanisterActor.verifyDomain(domain);
        return result;
    }

    async createPaymentSession(domain, plan='business') {
        const result = await this.#indexClient.indexCanisterActor.createPaymentSession(domain, plan);
        return result;
    }
    
    async retrieveCheckoutSession(domain) {
        const result = await this.#indexClient.indexCanisterActor.retrieveCheckoutSession(domain);
        return result;
    }

    async subscribe(domain) {
        const result = await this.#indexClient.indexCanisterActor.subscribe(domain);
        if (result.code === 0) {
            this.#canisterIBelong = result.data;
        }
        return result;
    }

    async getContacts(domain, path) {
        
        let pk = `group#${domain}`;

        if (!this.#enterpriseResourceCanister) {
            const canisterIds = await this.#indexClient.getCanistersForPK(pk, false);
            if (!canisterIds || canisterIds.length === 0) {
                // throw new Error('Canister not found in this partition');
                return null;
            }
            const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterIds[0] }));
            this.#enterpriseResourceCanister = canisterActor;
        }


        const result = await this.#enterpriseResourceCanister.getContacts(path, false);
        return result;
    }

    async addContactNode(domain, path, node) {
        
        let pk = `group#${domain}`;

        if (!this.#enterpriseResourceCanister) {
            const canisterIds = await this.#indexClient.getCanistersForPK(pk, false);
            if (!canisterIds || canisterIds.length === 0) {
                // throw new Error('Canister not found in this partition');
                return null;
            }
            const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterIds[0] }));
            this.#enterpriseResourceCanister = canisterActor;
        }
        const result = await this.#enterpriseResourceCanister.addContactNode(path, node);
        return result;
    }

    async deleteContactNode(domain, path) {

        
        let pk = `group#${domain}`;

        if (!this.#enterpriseResourceCanister) {
            const canisterIds = await this.#indexClient.getCanistersForPK(pk, false);
            if (!canisterIds || canisterIds.length === 0) {
                // throw new Error('Canister not found in this partition');
                return null;
            }
            const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterIds[0] }));
            this.#enterpriseResourceCanister = canisterActor;
        }
        
        const result = await this.#enterpriseResourceCanister.deleteContactNode(path);
        return result;
    }
    

    // async update(pk, sk, updateFn) {
    //     let canisterIds = await this.#indexClient.getCanistersForPK(pk, true);
    //     // Can fail here if the incorrect primary key is used or if the index canister does not permit access to the PK for the calling principal
    //     if (canisterIds.length === 0) {
    //         canisterIds = await this.#indexClient.getCanistersForPK(pk, true);
    //         if (canisterIds.length === 0) {
    //             throw new Error("Unable to update this record. Please ensure the entity you are trying to update has the appropriate primary key (PK)");
    //         }
    //     }

    //     // array to store error multiple messages that could happen when calling multiple canisters
    //     const errors = [];
    //     // for update calls, never use the cache
    //     const matchingSK = (await this.query(pk, (a) => a.skExists(sk))).reduce((acc, settledResult, index) => {
    //         const canisterId = canisterIds[index];
    //         if (settledResult.status === "rejected") {
    //             errors.push(`A call to canister: ${canisterId} was rejected due to ${settledResult.reason}`);
    //             return acc;
    //         }
    //         if (settledResult.value === true)
    //             return acc.concat(canisterId);
    //         return acc;
    //     }, []);
    //     if (matchingSK.length > 1) {
    //         errors.push(`Uniqueness constraint violation error. Found multiple occurences of the same PK + SK combination: ${matchingSK}`);
    //     }
    //     // If any errors exist, throw here before an update is made
    //     if (errors.length > 0) {
    //         throw new Error(errors.toString());
    //     }
    //     // the canister that the update call will be made to
    //     const canisterToMakeUpdateCallTo = matchingSK.length === 0
    //         ? // no matching sks meaning that the pk + sk was combination not found, so insert into most recent canister
    //             canisterIds[canisterIds.length - 1]
    //         : // otherwise insert into the first (and only) matching canisterId
    //             matchingSK[0];
    //     const updateCanisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.actorOptions), { canisterId: canisterToMakeUpdateCallTo }));
    //     return updateFn(updateCanisterActor);
    // }

    async query(queryFn) {
        let pk = `group#${this.#group}`;
        let results = await this.#plexiMailServiceClient.query(
            pk,
            queryFn,
            true
        );
        return results;
    }

    async randomQueryOne(queryFn) {
        let pk = `group#${this.#group}`;


        const canisterIds = await this.#indexClient.getCanistersForPK(pk, true);
        if (!canisterIds || canisterIds.length === 0) {
            throw new Error('Canister not found in this partition');
        }

        const canisterToMakeQueryCallTo = canisterIds[randomNumber() % canisterIds.length];

        const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterToMakeQueryCallTo }));
        return queryFn(canisterActor);
    }

    async queryOne(queryFn) {
        const results = await this.query(queryFn);

        for (let result of results) {
            // handle settled result if fulfilled
            if (result.status === "fulfilled" && result.value) {
                // handle candid returned optional type (string[] or string)
                return Array.isArray(result.value) ? result.value[0] : result.value
            } 
        }
        return null;
    }
    async queryList(queryFn) {
        const results = await this.query(queryFn);

        var array = [];
        for (let result of results) {
            // handle settled result if fulfilled
            if (result.status === "fulfilled" && result.value) {
                if (Array.isArray(result.value)) {
                    array = array.concat(array, result.value);
                } else {
                    array.push(result.value);
                }
            } 
        }

        return array;
    }

    async update(sk, updateFn) {
        let pk = `group#${this.#group}`;
        let result = await this.#plexiMailServiceClient.update(
            pk,
            sk,
            updateFn
        );
        return result;
    }

    async getEnterpriseCanisterByMailAddress(address) {

        // const signalAccount = mailAddressToSignalIdentity(address);
        const actor = this.actorCacheMap[address];
        if (actor) {
            return actor;
        }

        const addr = MailAddressUtils.parseOneAddress(address);
        const signalIdentity = mailAddressToSignalIdentity(address);
        const subDomain = mailAddressSubdomain();
        let domain = addr.domain;
        if (domain.startsWith(subDomain)) {
            domain = domain.substring(subDomain.length);
        }
        
        let pk = `group#${domain}`;

        const canisterIds = await this.#indexClient.getCanistersForPK(pk, false);
        if (!canisterIds || canisterIds.length === 0) {
            // throw new Error('Canister not found in this partition');
            return null;
        }
        for(const canisterId of canisterIds) {

            const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterId }));
            const exists = await canisterActor.isPendingUserExists(signalIdentity);
            if (exists) {
                canisterActor.canisterId = canisterId;
                this.actorCacheMap[signalIdentity] = canisterActor;
                return canisterActor;
            }
        }
        return null;
    }

    async getVetkeysCanister(domain) {
        if (this.#vetkeysCanister) {
            return this.#vetkeysCanister;
        }

        let pk = `group#${domain}`;
        const canisterIds = await this.#indexClient.getCanistersForPK(pk, false);
        if (!canisterIds || canisterIds.length === 0) {
            // throw new Error('Canister not found in this partition');
            return null;
        }
        const canisterId = canisterIds[0];
        const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterId }));
        this.#vetkeysCanister = canisterActor;
        return canisterActor;
    }

    async getCanisterByMailAddress(address, withDefault=false) {

        const actor = this.actorCacheMap[address];
        if (actor) {
            return actor;
        }

        const addr = MailAddressUtils.parseOneAddress(address);
        const signalIdentity = mailAddressToSignalIdentity(address);
        
        const subDomain = mailAddressSubdomain();
        let domain = addr.domain;
        if (domain.startsWith(subDomain)) {
            domain = domain.substring(subDomain.length);
        }
        
        let pk = `group#${domain}`;

        const canisterIds = await this.#indexClient.getCanistersForPK(pk, false);
        if (!canisterIds || canisterIds.length === 0) {
            // throw new Error('Canister not found in this partition');
            return null;
        }
        for(const canisterId of canisterIds) {

            const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterId }));
            const exists = await canisterActor.userExists(signalIdentity);
            if (exists) {
                canisterActor.canisterId = canisterId;
                this.actorCacheMap[signalIdentity] = canisterActor;
                return canisterActor;
            }
        }
        if (withDefault) {

            const canisterId = canisterIds[canisterIds.length - 1];
            const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterId }));
            canisterActor.canisterId = canisterId;
            this.actorCacheMap[address] = canisterActor;
            return canisterActor;
        }
        return null;
        // throw new Error('User not found');

        // const result = await this.queryList(async(actor) => {
        //     const exists = await actor.userExists(address);
        //     if (exists) {
        //         const canisterId = Actor.canisterIdOf(actor);
        //         console.log("canister id: ", canisterId.toText());
        //         return canisterId;
        //     }
        // });
        // return result;
    }

    async isKeyExists(sk){
        const result = await this.queryList((actor) => {
            return actor.skExists(sk);
        });
        return result;
    }


    async dbInsight() {
        const result = await this.queryList((actor) => {
            return actor.allEntries();
        });
        return result;
    }
    async p2c(p) {

        let pk = `group#${this.#group}`;


        const canisterIds = await this.#indexClient.getCanistersForPK(pk, false);
        if (!canisterIds || canisterIds.length === 0) {
            throw new Error('Canister not found in this partition');
        }

        const canisterToMakeQueryCallTo = canisterIds[randomNumber() % canisterIds.length];

        const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterToMakeQueryCallTo }));
        const result = await canisterActor.p2c(p);
        return result;
    }
    async c2p(c) {

        let pk = `group#${this.#group}`;


        const canisterIds = await this.#indexClient.getCanistersForPK(pk, false);
        if (!canisterIds || canisterIds.length === 0) {
            throw new Error('Canister not found in this partition');
        }

        const canisterToMakeQueryCallTo = canisterIds[randomNumber() % canisterIds.length];

        const canisterActor = (0, createActor_1.createActor)(Object.assign(Object.assign({}, this.#plexiMailServiceClient.actorOptions), { canisterId: canisterToMakeQueryCallTo }));
        const result = await canisterActor.c2p(c);
        return result;
    }
    

    async randomVerificationCode(length, simple=true) {
        // const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const chars = simple ? '123456789abcdefghijkmnpqrstuvwxyz' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*_-=+';
        // const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*_-=+";
        let password = "";
        for (let i = 0; i < length; i++) {
            password += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return password;
    };

    async addUser(address) {
        address = address.toLowerCase();
        const signalIdentity = mailAddressToSignalIdentity(address);
        const canisterActor = await this.getCanisterByMailAddress(address, true);
        const code = await this.randomVerificationCode(16, false);
        const res = await canisterActor.addUser(signalIdentity, code);
        // res.data = canisterActor.canisterId;
        
        return [res, canisterActor.canisterId];
    };

    async  getPendingUser(address) {
        address = address.toLowerCase();

        const canisterActor = await this.getEnterpriseCanisterByMailAddress(address);

        const signalIdentity = mailAddressToSignalIdentity(address)
        const code = await canisterActor.getPendingUser(signalIdentity);
        return (code && code.length === 1) ? code[0] : null;
    };
    
    async activateUser(address) {
        address = address.toLowerCase();
        const signalIdentity = mailAddressToSignalIdentity(address);
        const code = await this.getPendingUser(address);
        if (!code ) {
            throw new Error('Access Denied');
        }
        const web3 = window.web3Helper.web3;

        const canisterActor = await this.getEnterpriseCanisterByMailAddress(address);
        const signature = await web3.eth.personal.sign(
            code,
            address,
            "--sign-the-message-silently--"
        );
        const res = await canisterActor.activateUser(signature, signalIdentity);
        return res ;
    }

    async deleteCanisters() {

        let pk = `group#${this.#group}`;

        const canisters = await this.#indexClient.indexCanisterActor.getCanistersByPK(pk);
        for(const canister of canisters) {
            await this.#indexClient.indexCanisterActor.deleteServiceCanisterById(pk, canister);
        }
    }

    async getCrypton(name) {
        const cid = await this.queryOne((actor) => {
            return actor.getCrypton(name)
        });
        return cid;
    }
    
    async saveCrypton(form) {
        const sk = PlexiMailService.CRYPTON_PREFIX + this.#principal + '.' + form.name;
        const result = await this.update(sk, (actor) => {
            return actor.saveCrypton(form);
        });
        return result;
    }

    async deleteCrypton(name) {
        
        const sk = PlexiMailService.CRYPTON_PREFIX + this.#principal + '.' + name;
        const result = await this.update(sk, (actor) => {
            return actor.deleteCrypton(name);
        });
        return result;
    }

    get alreadyRegistered() {
        return this.#isRegistered;
    }
    
    async isRegistered() {

        this.#isRegistered = false;
        const results = await this.isKeyExists(PlexiMailService.USER_PREFIX + 'users.' + this.#principal);
        if (!results || results.length === 0) {
            return false;
        }

        for(const result of results) {
            if (result) {
                this.#isRegistered = true;
                return result;
            }
        }

        return false;
        // const reg = await this.queryOne((actor) => {
        //     return actor.isRegistered();
        // });
        // return reg;
    }
    async closeAccount() {
        const actor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!actor) {
            return false;
        }

        try {
            const res = await actor.closeAccount();
            return res;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
    
    async getMailList(deviceId) {
        const mailList = await this.queryOne((actor) => {
            return actor.getMailList(deviceId);
        });
        return mailList;
    }

    async saveMailList(deviceId, cid) {
        const self = this;
        if (window.appConfig.recentActiveAccount && window.appConfig.recentActiveAccount.length > 0) {
            const actor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
            if (actor) {
                const success = await actor.saveMailList(deviceId, cid);
                return success;
            }
        }

        const sk = PlexiMailService.MAILLIST_PREFIX + this.#principal + '.' + deviceId;
        const success = await this.update(sk, (actor) => {
            if (window.appConfig.recentActiveAccount && window.appConfig.recentActiveAccount.length > 0) {
                self.actorCacheMap[window.appConfig.recentActiveAccount + mailAddressSuffix()] = actor;
            }
            return actor.saveMailList(deviceId, cid);
        });
        return success;
    }

    async deleteMailList(deviceId) {
        const sk = PlexiMailService.MAILLIST_PREFIX + this.#principal + '.' + deviceId;
        const result = await this.update(sk, (actor) => {
            return actor.deleteMailList(deviceId);
        });
        return result;
    }

    async sendMail(appMessage) {
        // appMessage.sender.account 
        const sk = PlexiMailService.ENVELOPE_PREFIX + this.#principal + '.' + appMessage.sender.deviceId;
        const success = await this.update(sk, (actor) => {
            return actor.sendMail(appMessage);
        });
        return success;
    }

    async sendMails(appMessage) {
        const {sender, envelopes} = appMessage.notify;
        const envelope = envelopes[0];
        console.log('sendMails: ', envelope);

        /*
        signalEnvelope = {
            am: 0,
            ct: [],
            to: [],
            mid: message.mid,
            ref: [],
            uid: message.uid,
            expires: 0,
            from: message.from,
            salt: [],
            type: message.type,
            encryptedHeader: encryptedHeaderJSONString, // sig
            address: [myAddress],
            deviceId: [myDeviceId],
            signal: [signal],
            sendDate: header.sendDate,
        };
        */

        if (envelope.type === MailEncryptionType.plaintext) {
            throw new Error('no longer supported');
        } else if (envelope.type === MailEncryptionType.password) {
            
            const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());

            // const canisterActor = await this.getCanisterByMailAddress(to);
            let result = await canisterActor.sendMail(appMessage);
            if (!result.sentFeedback && (result.code !== 0 || result.error)) {
                if (result.error) {
                    return result.error;
                }
                throw result;
            }

            /*
            const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
            const results = [];
            for(const to of envelope.to[0]) {
                
                const appMessage = {
                    notify: {
                        sender,
                        envelopes: [{
                            ...envelope,
                            to: [[to]]
                        }]
                    }
                };

                // const canisterActor = await this.getCanisterByMailAddress(to);
                let result = await canisterActor.sendMail(appMessage);
                results.push(result);
            }

            console.log(results);
            */

        } else if (envelope.type === MailEncryptionType.signal) {

            
            let groups = null;

            for(const account of envelope.signal[0].accounts) {
                if (!groups) {
                    groups = {};
                }
                const address = signalIdentityToMailAddress(account.account);
                const canisterActor = await this.getCanisterByMailAddress(address);
                // const canisterId = canisterActor[Symbol.for('ic-agent-metadata')].config.canisterId.toString()
                if (groups[canisterActor.canisterId]) {
                    groups[canisterActor.canisterId].signal.push(account)
                } else {
                    groups[canisterActor.canisterId] = {
                        canisterActor: canisterActor,
                        signal: [account],
                        to: []
                    }
                };
            }

            
            if (groups) {
                for(const key in groups) {
                    const group = groups[key];

                    const appMessage = {
                        notify: {
                            sender,
                            envelopes: [{
                                ...envelope,
                                signal: [{...envelope.signal[0], accounts: group.signal}]
                            }]
                        }
                    };

                    // const canisterActor = await this.getCanisterByMailAddress(account.account + mailAddressSuffix());
                    const result = await group.canisterActor.sendMail(appMessage);
                    if (!result.sentFeedback && result.code !== 0) {
                        throw result;
                    }
                }
            }

            /*
            for(const account of envelope.signal[0].accounts) {
                const appMessage = {
                    notify: {
                        sender,
                        envelopes: [{
                            ...envelope,
                            signal: [{...envelope.signal[0], accounts: [account]}]
                        }]
                    }
                };
                const canisterActor = await this.getCanisterByMailAddress(account.account + mailAddressSuffix());
                const result = await canisterActor.sendMail(appMessage);
                if (!result.sentFeedback && result.code !== 0) {
                    throw result;
                }
            }
            */
        } else if (envelope.type === MailEncryptionType.mixed) {
            

            let groups = null;

            for(const account of envelope.signal[0].accounts) {
                if (!groups) {
                    groups = {};
                }
                const address = signalIdentityToMailAddress(account.account);

                const canisterActor = await this.getCanisterByMailAddress(address);
                if (groups[canisterActor.canisterId]) {
                    groups[canisterActor.canisterId].signal.push(account)
                } else {
                    groups[canisterActor.canisterId] = {
                        canisterActor: canisterActor,
                        signal: [account],
                        to: []
                    }
                };
            }

            const myCanisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
            if (groups[myCanisterActor.canisterId]) {
                groups[myCanisterActor.canisterId].to = envelope.to[0];
            } else {
                groups[myCanisterActor.canisterId] = {
                    canisterActor: myCanisterActor,
                    signal: [],
                    to: envelope.to[0]
                }
            }
            const results = [];
            if (groups) {
                for(const key in groups) {
                    const group = groups[key];

                    const appMessage = {
                        notify: {
                            sender,
                            envelopes: [{
                                ...envelope,
                                to: [group.to],
                                signal: [{...envelope.signal[0], accounts: group.signal}]
                            }]
                        }
                    };
                    console.log('sendMail: #group: ', group, ', message: ', appMessage);
                    // const canisterActor = await this.getCanisterByMailAddress(account.account + mailAddressSuffix());
                    const result = await group.canisterActor.sendMail(appMessage);
                    if (!result.sentFeedback && result.code !== 0) {
                        throw result;
                    }
                    results.push(result);
                }
            }
            /*
            for(const account of envelope.signal[0].accounts) {

                const appMessage = {
                    notify: {
                        sender,
                        envelopes: [{
                            ...envelope,
                            to: [],
                            signal: [{...envelope.signal[0], accounts: [account]}]
                        }]
                    }
                };
                console.log('sendMails #appMessage: ', appMessage);
                const canisterActor = await this.getCanisterByMailAddress(account.account + mailAddressSuffix());
                const result = await canisterActor.sendMail(appMessage);
                if (!result.sentFeedback && result.code !== 0) {
                    throw result;
                }
            }

            const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
            const results = [];
            for(const to of envelope.to[0]) {
                
                const appMessage = {
                    notify: {
                        sender,
                        envelopes: [{
                            ...envelope,
                            signal: [],
                            to: [[to]]
                        }]
                    }
                };

                // const canisterActor = await this.getCanisterByMailAddress(to);
                let result = await canisterActor.sendMail(appMessage);
                results.push(result);
            }
            */
        } else {
            throw new Error('Invalid message type');
        }
        
        // appMessage.sender.account 
        // const sk = PlexiMailService.ENVELOPE_PREFIX + this.#principal + '.' + appMessage.sender.deviceId;
        // const success = await this.update(sk, (actor) => {
        //     return actor.sendMail(appMessage);
        // });
        return true;
    }

    async getMails(deviceId) {
        const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const mails = await canisterActor.getMails(deviceId);
        return mails;

        // const mails = this.queryList((actor) => {
        //     return actor.getMails(deviceId);
        // });
        // return mails;
    }

    async removeMails(appMessage) {
        // appMessage.sender.address = this.#principal;
        const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const result =  await canisterActor.removeMails(appMessage.copied || appMessage);
        if (result.error && result.error.code !== 0) {
            throw new Error(result.error.message);
        }
    }
    
    async getTimestamp() {
        return await this.randomQueryOne((actor) => {
            return actor.getTimestamp();
        });
    }

    async monitorAccount(address) {
        const emailAddress = signalIdentityToMailAddress(address);
        const canisterActor = await this.getCanisterByMailAddress(emailAddress);
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const devices = await canisterActor.monitorAccount(address);
        return devices;

        // const devices = this.queryList((actor) => {
        //     return actor.monitorAccount(address);
        // });
        // return devices;
    }

    async queryAccount(address) {
        const emailAddress = signalIdentityToMailAddress(address);
        const canisterActor = await this.getCanisterByMailAddress(emailAddress);
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const keyBundle = await canisterActor.queryAccount(address);
        return keyBundle;

        // const keyBundle = this.queryOne((actor) => {
        //     return actor.queryAccount(address);
        // });
        // return keyBundle;
    }

    async queryPrekeyCount(deviceId) {
        let address = window.appConfig.recentActiveAccount + mailAddressSuffix();
        const canisterActor = await this.getCanisterByMailAddress(address);
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const countResult = await canisterActor.queryPrekeyCount(deviceId);
        return countResult;

        // const countResult = this.queryOne((actor) => {
        //     return actor.queryPrekeyCount(deviceId);
        // });
        // return countResult;
    }

    async registerDevice(encodedBundle) {
        const sk = PlexiMailService.USER_PREFIX + PlexiMailService.DEVICE_PREFIX + this.#principal; 
        
        const success = await this.update(sk, (actor) => {
            return actor.registerDevice(encodedBundle);
        });
        return success;
    }
    
    async retrievePreKey(address, deviceId) {
        const emailAddress = signalIdentityToMailAddress(address);
        const canisterActor = await this.getCanisterByMailAddress(emailAddress);
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const preKey = await canisterActor.retrievePreKey(address, deviceId);
        return preKey;

        // const result = this.queryOne((actor) => {
        //     return actor.retrievePreKey(address, deviceId);
        // });
        // return result;
    }

    async retrievePiiInfo() {
        return null;
    }
    



    async saveContractArchive(deviceId, meta) {
        meta = JSON.stringify(meta);
        const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!canisterActor) {
            throw new Error('user not found');
        }
        let success = await canisterActor.saveContractArchive(deviceId, meta);
        return success;

    }

    async getContractArchives(deviceId) {
        const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const archives = await canisterActor.getContractArchives(deviceId);
        const res = archives.map(a => {
            return JSON.parse(a);
        });
        return res;
    }

    async deleteContractArchives(deviceId) {
        const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const success = await canisterActor.deleteContractArchives(deviceId);
        return success;
    }

    async addAddress(address, opt) {
        const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const info = typeof opt === 'string' ? opt : JSON.stringify(opt);
        const success = await canisterActor.addAddress(address, info);
        return success;
    }

    async removeAddress(address) {
        const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const success = await canisterActor.removeAddress(address);
        return success;
    }

    async getAddresses() {
        const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const addresses = await canisterActor.getAddresses();
        if (addresses) {
            return addresses.map(addr => {
                return signalIdentityToMailAddress(addr);
            });
        }
        return addresses;

    }

    async getAddresseEntities() {
        const canisterActor = await this.getCanisterByMailAddress(window.appConfig.recentActiveAccount + mailAddressSuffix());
        if (!canisterActor) {
            throw new Error('user not found');
        }
        const addresses = await canisterActor.getAddresseEntities();
        if (addresses) {
            return addresses.map(addr => {
                return signalIdentityToMailAddress(addr);
            });
        }
        return addresses;

    }



  async getPrincipalOfActor(actor) {
    let app_backend_principal = await Actor.agentOf(actor).getPrincipal();
    return app_backend_principal;
  }
  
  async get_aes_256_gcm_key() {

    const canisterActor = await this.getVetkeysCanister(mailAddressDomain());

    const seed = window.crypto.getRandomValues(new Uint8Array(32));
    const tsk = new vetkd.TransportSecretKey(seed);
    const ek_bytes_hex = await canisterActor.encrypted_symmetric_key_for_caller(tsk.public_key());
    const pk_bytes_hex = await canisterActor.symmetric_key_verification_key();
    const app_backend_principal = await this.getPrincipalOfActor(canisterActor);
    return tsk.decrypt_and_hash(
      hex_decode(ek_bytes_hex),
      hex_decode(pk_bytes_hex),
      app_backend_principal.toUint8Array(),
      32,
      new TextEncoder().encode("aes-256-gcm")
    );
  }
  
  async get_aes_256_gcm_key_in_hex() {
    const canisterActor = await this.getVetkeysCanister(mailAddressDomain());


    const seed = window.crypto.getRandomValues(new Uint8Array(32));
    const tsk = new vetkd.TransportSecretKey(seed);
    const ek_bytes_hex = await canisterActor.encrypted_symmetric_key_for_caller(tsk.public_key());
    const pk_bytes_hex = await canisterActor.symmetric_key_verification_key();
    const app_backend_principal = await this.getPrincipalOfActor(canisterActor);
    const res = tsk.decrypt_and_hash(
      hex_decode(ek_bytes_hex),
      hex_decode(pk_bytes_hex),
      app_backend_principal.toUint8Array(),
      32,
      new TextEncoder().encode("aes-256-gcm")
    );
    return hex_encode(res);
  }
  async aes_gcm_encrypt(message, rawKey) {

    const iv = window.crypto.getRandomValues(new Uint8Array(12)); // 96-bits; unique per message
    const aes_key = await window.crypto.subtle.importKey("raw", rawKey, "AES-GCM", false, ["encrypt"]);
    const message_encoded = new TextEncoder().encode(message);
    const ciphertext_buffer = await window.crypto.subtle.encrypt(
      { name: "AES-GCM", iv: iv },
      aes_key,
      message_encoded
    );
    const ciphertext = new Uint8Array(ciphertext_buffer);
    var iv_and_ciphertext = new Uint8Array(iv.length + ciphertext.length);
    iv_and_ciphertext.set(iv, 0);
    iv_and_ciphertext.set(ciphertext, iv.length);
    return hex_encode(iv_and_ciphertext);
  }
  
  async aes_gcm_decrypt(ciphertext_hex, rawKey) {
    const canisterActor = await this.getVetkeysCanister(mailAddressDomain());

    const iv_and_ciphertext = hex_decode(ciphertext_hex);
    const iv = iv_and_ciphertext.subarray(0, 12); // 96-bits; unique per message
    const ciphertext = iv_and_ciphertext.subarray(12);
    const aes_key = await window.crypto.subtle.importKey("raw", rawKey, "AES-GCM", false, ["decrypt"]);
    let decrypted = await window.crypto.subtle.decrypt(
      { name: "AES-GCM", iv: iv },
      aes_key,
      ciphertext
    );
    return new TextDecoder().decode(decrypted);
  }
  
  async ibe_encrypt(message) {
    const canisterActor = await this.getVetkeysCanister(mailAddressDomain());

    document.getElementById("ibe_encrypt_result").innerText =
      "Fetching IBE encryption key...";
    const pk_bytes_hex = await canisterActor.ibe_encryption_key();

    document.getElementById("ibe_encrypt_result").innerText =
      "Preparing IBE-encryption...";
    const message_encoded = new TextEncoder().encode(message);
    const seed = window.crypto.getRandomValues(new Uint8Array(32));
    let ibe_principal = Principal.fromText(
      document.getElementById("ibe_principal").value
    );

    document.getElementById("ibe_encrypt_result").innerText =
      "IBE-encrypting for principal" + ibe_principal.toText() + "...";
    const ibe_ciphertext = vetkd.IBECiphertext.encrypt(
      hex_decode(pk_bytes_hex),
      ibe_principal.toUint8Array(),
      message_encoded,
      seed
    );
    return hex_encode(ibe_ciphertext.serialize());
  }

  async ibe_decrypt(ibe_ciphertext_hex) {
    const canisterActor = await this.getVetkeysCanister(mailAddressDomain());
    const app_backend_principal = await this.getPrincipalOfActor(canisterActor);

    document.getElementById("ibe_decrypt_result").innerText =
      "Preparing IBE-decryption...";
    const tsk_seed = window.crypto.getRandomValues(new Uint8Array(32));
    const tsk = new vetkd.TransportSecretKey(tsk_seed);
    document.getElementById("ibe_decrypt_result").innerText =
      "Fetching IBE decryption key...";
    const ek_bytes_hex =
      await canisterActor.encrypted_ibe_decryption_key_for_caller(
        tsk.public_key()
      );
    document.getElementById("ibe_decrypt_result").innerText =
      "Fetching IBE enryption key (needed for verification)...";
    const pk_bytes_hex = await canisterActor.ibe_encryption_key();

    const k_bytes = tsk.decrypt(
      hex_decode(ek_bytes_hex),
      hex_decode(pk_bytes_hex),
      app_backend_principal.toUint8Array()
    );

    const ibe_ciphertext = vetkd.IBECiphertext.deserialize(
      hex_decode(ibe_ciphertext_hex)
    );
    const ibe_plaintext = ibe_ciphertext.decrypt(k_bytes);
    return new TextDecoder().decode(ibe_plaintext);
  }
} 