// import { /*getFilesFromPath, */Web3Storage } from "web3.storage";
// import * as Name from 'web3.storage/name';

// import { encrypt } from '@metamask/eth-sig-util';
import { ethers } from 'ethers';
// import appConfig from "../config/AppConfig";
// import * as libsignal from '@privacyresearch/libsignal-protocol-typescript';

import SignalService from './SignalService';
import { ClientError, ServerError } from "../common/errors";
import { CryptoService } from "./CryptoService";
import { FolderType, MailEncryptionType, MessageFlag, SignalInitStep, SignalMessageType, EthereumChains, ContractConstants, StorageProvider } from '../utils/Types';
// import TypeUtils from "../utils/TypeUtils";
import { SignalProtocolAddress } from "@privacyresearch/libsignal-protocol-typescript";
import { BACKUP_FOLDER, CONTACT_NAME_ME, CONTACT_NAME_ME_LOWERCASED, CONTRACT_FOLDER, INBOX_FOLDER, SENT_FOLDER, TRASH_FOLDER, aifiDomain, mailAddressDomain, mailAddressSubdomain, mailAddressSuffix, mailAddressToSignalIdentity, signalIdentityToMailAddress } from "../common/constants";
import { binaryStringToArrayBuffer } from "@privacyresearch/libsignal-protocol-typescript/lib/helpers";
import { PreKeyWhisperMessage } from '@privacyresearch/libsignal-protocol-protobuf-ts'
// import SignalProtocolStoreIndexedDB from "./SignalProtocolStoreIndexedDB";
import axios from "axios";
import MailAddressUtils from "../utils/MailAddressUtils";
import fetchProgress from 'fetch-progress';
import Strings from "../config/Strings";
import TaskQueue from "./TaskQueue";
import SecuritySignalProtocolStoreIndexedDB from "./SecuritySignalProtocolStoreIndexedDB";
import { keys } from 'libp2p-crypto';
import W3UpWeb3Storage from "../components/w3ui/W3UpWeb3Storage";

import * as crypt from "crypto-browserify";
import {USE_DAG_FILE} from '../common/constants';

import { identity } from 'multiformats/hashes/identity';
import * as Digest from 'multiformats/hashes/digest';
import { base36 } from 'multiformats/bases/base36';
import { CID } from 'multiformats/cid';
import * as zip from "@zip.js/zip.js";
import LighthouseWeb3Storage from '../components/w3ui/LighthouseWeb3Storage';
// const ed = require('@noble/ed25519')


const libp2pKeyCode = 0x72;
export const PLEXISIGN_IS_ENABLED = true;
// const PUSH_SERVER_ENABLE_TLS = false;
// const PUSH_SERVER_HOST = window.location.hostname; // const PUSH_SERVER_HOST = '127.0.0.1';
// // const PUSH_SERVER_PORT = ':3001';
// const PUSH_SERVER_PORT = '';
// const PUSH_SERVER_ROOT_PATH = '';

// const PUSH_SERVER_PROTOCOL = 'https:';
// const PUSH_SERVER_HOST = 'ai-fi.cc';
// const PUSH_SERVER_PROTOCOL = window.location.protocol;
// const PUSH_SERVER_HOST = window.location.hostname //'ai-fi.cc';


/*
const IS_RUNNING_IN_IPFS = (window.location.href.indexOf('ipns') !== -1 || window.location.href.indexOf('ipfs') !== -1);
const PUSH_SERVER_PROTOCOL = (IS_RUNNING_IN_IPFS) ? 'https:' : window.location.protocol;
const PUSH_SERVER_HOST = (IS_RUNNING_IN_IPFS) ? 'ai-fi.cc' : window.location.hostname;

const PUSH_SERVER_PORT = '';
const PUSH_SERVER_ROOT_PATH = '';

const PUSH_SERVER_ADDRESS = PUSH_SERVER_PROTOCOL + '//' + PUSH_SERVER_HOST + PUSH_SERVER_PORT + PUSH_SERVER_ROOT_PATH;
const PUSH_SERVER_WS_ADDRESS = ((PUSH_SERVER_PROTOCOL === 'https:') ? 'wss://' : 'ws://') + PUSH_SERVER_HOST + PUSH_SERVER_PORT + PUSH_SERVER_ROOT_PATH + '/api/notify';
*/

const IS_RUNNING_IN_IPFS = (window.location.href.indexOf('ipns') !== -1 || window.location.href.indexOf('ipfs') !== -1);

// console.log('ENV:', process.env)
// console.log('PUBLIC_URL:', process.env.PUBLIC_URL, ', BACKEND_SERVER:', process.env.REACT_APP_BACKEND_SERVER, ', STORE_SERVER:', process.env.REACT_APP_STORE_SERVER)
const BACKEND_SERVER = new URL(process.env.REACT_APP_BACKEND_SERVER);
const PUSH_SERVER_PROTOCOL = BACKEND_SERVER.protocol;
const PUSH_SERVER_HOST = BACKEND_SERVER.hostname;

// const BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
// const PUSH_SERVER_PROTOCOL = BACKEND_SERVER_STR.startsWith('https://') ? 'https' : 'http';
// const PUSH_SERVER_HOST = BACKEND_SERVER_STR.substring(PUSH_SERVER_PROTOCOL.length + 3);

const PUSH_SERVER_PORT = '';
const PUSH_SERVER_ROOT_PATH = '';
const PUSH_SERVER_ADDRESS = PUSH_SERVER_PROTOCOL + '//' + PUSH_SERVER_HOST + PUSH_SERVER_PORT + PUSH_SERVER_ROOT_PATH;
// const PUSH_SERVER_WS_ADDRESS = ((PUSH_SERVER_PROTOCOL === 'https:') ? 'wss://' : 'ws://') + PUSH_SERVER_HOST + PUSH_SERVER_PORT + PUSH_SERVER_ROOT_PATH + '/api/notify';



const ENCRYPT_FOLDERS_ENABLED = true;

class MailService {

    // web3StorageClient;
    // web3StorageClientWithoutCache;
    web3StorageClientMap;
    web3StorageClientWithoutCacheMap;
    
    get web3StorageClient() {
        const provider = window.appConfig.storageProvider;
        if (provider === StorageProvider.Unspecified) {
            return this.web3StorageClientMap[StorageProvider.LightHouse];
        }
        const client = this.web3StorageClientMap[provider];
        return client;
    }
    get web3StorageClientWithoutCache() {
        const provider = window.appConfig.storageProvider;
        if (provider === StorageProvider.Unspecified) {
            return this.web3StorageClientWithoutCacheMap[StorageProvider.LightHouse];
        }
        const client = this.web3StorageClientWithoutCacheMap[provider];
        return client;
    }

    folders;
    foldersChangedListener;
    expectedAckNumber = 0;
    ackNumber = 0;
    currentFolder = INBOX_FOLDER;
    editMode = 'new';
    currentMessage = null; 
    signalService = null;
    cryptoService = null;
    getAbortController = null;
    contacts = [];
    users = [];
    enterpriseProfile = null;
    selectedAccount = null;
    selectedAccountIndex = 0;
    __mailFoldersIpnsSignKey = null;
    getFunctions = [
        this.web_storage_get.bind(this),
        this.w3s_get.bind(this),
        this.dweb_get.bind(this),
        this.ipfs_get.bind(this),
        this.pinata_get.bind(this),
        this.ipfs_gateway_get.bind(this),
        this.ipfs_eternum_io_get.bind(this),
        this.cloudflare_ipfs_get.bind(this),
        this.cf_ipfs_get.bind(this)
    ];
    progressOptions = {};
    taskQueue = null;
    shouldSyncContacts=false;
    isRecovering = false;
    notifyTemplate = null;
    stopRetrying = false;
    uiState = null;
    contractFilter = null;
    
    constructor(){
        console.log('> MailService - constructor')
        
        this.folders = null;
        this.foldersChangedListener = null; // (success) => {}
        // this.getAbortController = new AbortController();
        if (window.appConfig.prioritizeUseAiFiGateway) {
            // this.getFunctions.unshift(this.aifi_cc_get.bind(this));
            this.getFunctions.splice(1, 0, this.aifi_cc_get.bind(this));
        } else {
            this.getFunctions.push(this.aifi_cc_get.bind(this));
        }
        this.isBrave().then(isBrave => {
            if (isBrave) {
                // this.getFunctions.unshift(this.ipfs_direct_get.bind(this));
                this.getFunctions.splice(1, 0, this.ipfs_direct_get.bind(this));
                // this.getFunctions.push(this.ipfs_direct_get.bind(this));
            }
        })
        // if (this.isBrave()) {
        //     // this.getFunctions.unshift(this.ipfs_direct_get.bind(this));
        //     this.getFunctions.push(this.ipfs_direct_get.bind(this));
        // }

        
        // this.web3StorageClientMap = new W3UpWeb3Storage();
        // this.web3StorageClientWithoutCache = this.web3StorageClient;

        // const map = {
        //     'web3.storage': new W3UpWeb3Storage(),
        //     'lighthouse': new LighthouseWeb3Storage()
        // };

        const map = {
            1: new LighthouseWeb3Storage(),
            2: new W3UpWeb3Storage(),
        };


        this.web3StorageClientMap = map;
        this.web3StorageClientWithoutCacheMap = map;

        this.cryptoService = new CryptoService();
        if (window.wallet && window.wallet.asDefaultWallet) {
            this.cryptoService.init()
        } else {
            if (window.appConfig.mnemonic && window.appConfig.mnemonic.length > 0) {
                this.cryptoService.init(window.appConfig.mnemonic)
            }
        }
        // createIdIfNeeded
        this.taskQueue = new TaskQueue();
        // this.taskQueue.start();
    } 

    #signalServiceMap = {};
    signalServiceFor(account = null) {
        
        if (account === null || account === window.appConfig.recentActiveAccount) {
            return this.signalService;
        }

        const ss = this.#signalServiceMap[account];
        if (ss) {
            return ss;
        }

        if (!this.#signalEncryptionKey) {
            throw new Error('Signal Encryption Key is uninitialized');
        }
        const keyStore = PUSH_SERVER_ADDRESS + '/api/signal';
        
        // const encryptionKey = await this.cryptoService.indexedDBEncryptionKey();
        const encryptionKey = this.#signalEncryptionKey;
        const signalService = new SignalService(window.appConfig.recentActiveAccount, account, keyStore, window.appConfig.pushApiToken, encryptionKey);
        this.#signalServiceMap[account] = signalService;
        return signalService;
    }
    
    cancel() {
        if (this.getAbortController) {
            this.getAbortController.abort();
            this.getAbortController = null;
        }
    }

    async axios_get(url, name, progress) {
        // this.cancel();
        this.getAbortController = new AbortController();

        // this.getAbortController = new AbortController();
        try {
            const res = await axios.get(
                url,
                {
                    signal: this.getAbortController.signal,
                    // responseType: "arraybuffer",
                    responseType: 'blob',
                    // maxContentLength: 10000000,
                    onDownloadProgress: (event) => {
                        if (progress) {
                            progress(event);
                        }
                    }
                });
            if (res.status === 404) {
                throw ServerError.notFoundError(Strings.error.client.file_not_found + url);
            }
            if (res.status !== 200) {
                throw ServerError.httpError(res);
            }
            this.getAbortController = null;
            const blob = res.data;
            const file = new File([blob], name);
            return file;
        } catch (e) {
            this.getAbortController = null;
            throw e;
        }
    }

    // https://bafybeifkpqlpwh6ko2scnpsjv4eqbzawerpnmkfkcyz64wu2jeet26p4ve.ipfs.cf-ipfs.com/folders.json
    // https://ipfs.io/ipfs/bafybeifkpqlpwh6ko2scnpsjv4eqbzawerpnmkfkcyz64wu2jeet26p4ve/folders.json
    // https://cloudflare-ipfs.com/ipfs/bafybeifkpqlpwh6ko2scnpsjv4eqbzawerpnmkfkcyz64wu2jeet26p4ve/folders.json
    async dweb_get(cid, name, mode, progress) {
        let url = 'https://dweb.link/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.axios_get(url, name, progress);
        return file;
    }

    async w3s_get(cid, name, mode, progress) {
        // const url = 'https://' + cid + '.ipfs.w3s.link/' + name;
        let url = 'https://w3s.link/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url +=  ('/' + name);
        }
        const file = await this.axios_get(url, name, progress);
        return file;
    }

    async cf_ipfs_get(cid, name, mode, progress) {
        // const url = 'https://' + cid + '.pfs.cf-ipfs.com/' + name;
        let url = 'https://cf-ipfs.com/ipfs/' + cid;

        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.axios_get(url, name, progress);
        return file;
    }

    async ipfs_get(cid, name, mode, progress) {
        let url = 'https://ipfs.io/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.axios_get(url, name, progress);
        return file;
    }

    async cloudflare_ipfs_get(cid, name, mode, progress) {
        let url = 'https://cloudflare-ipfs.com/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.axios_get(url, name, progress);
        return file;
    }
    async pinata_get(cid, name, mode, progress) {
        let url = 'https://gateway.pinata.cloud/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.axios_get(url, name, progress);
        return file;
    }
    async ipfs_gateway_get(cid, name, mode, progress) {
        // https://ipfs-gateway.cloud/ipfs/bafybeifx7yeb55armcsxwwitkymga5xf53dxiarykms3ygqic223w5sk3m#x-ipfs-companion-no-redirect
        let url = 'https://ipfs-gateway.cloud/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.axios_get(url, name, progress);
        return file;
    }

    async ipfs_eternum_io_get(cid, name, mode, progress) {
        // https://ipfs.eternum.io/ipfs/bafybeifx7yeb55armcsxwwitkymga5xf53dxiarykms3ygqic223w5sk3m#x-ipfs-companion-no-redirect
        let url = 'https://ipfs.eternum.io/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.axios_get(url, name, progress);
        return file;
    }
    async aifi_cc_get(cid, name, mode, progress) {
        // https://ipfs.eternum.io/ipfs/bafybeifx7yeb55armcsxwwitkymga5xf53dxiarykms3ygqic223w5sk3m#x-ipfs-companion-no-redirect
        let url = 'https://ai-fi.cc/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.axios_get(url, name, progress);
        return file;
    }

    async clearCache(url) {
        try {
            const cache = await caches.open('ipfs-cache-v1');
            if (cache) {
                await cache.delete(url)
            }
        } catch (e) {
            // throw ClientError.databaseError(Strings.error.client.failed_clear_cache);
        }
    }
    async clearCacheStorage() {
        // const CACHE_VERSION = 1;
        // const CURRENT_CACHES = {
        //   font: 'ipfs-cache-v' + CACHE_VERSION
        // };
        // const expectedCacheNamesSet = new Set(Object.values(CURRENT_CACHES));
        try {
            const cacheNames = await caches.keys();

            for (let cacheName of cacheNames) {
                const success = await caches.delete(cacheName);
                if (!success) {
                    return false;
                }
                // if (expectedCacheNamesSet.has(cacheName)) {
                //     // If this cache name isn't present in the set of "expected" cache names, then delete it.
                //     console.log('Clear cache:', cacheName);
                //     const success = await caches.delete(cacheName);
                //     if (!success) {
                //         return false;
                //     }
                // }
            }
        } catch (e) {
            console.error(e);
        }
        return true;
    }
    async __myweb_storage_get(cid, options, sp, name) {
        const opts = options || {};
        const withName = opts.withName || false;
        let url = null;
        
        const isV0 = cid.startsWith('Qm');
        if (sp === StorageProvider.LightHouse || isV0) {
            url = `https://gateway.lighthouse.storage/ipfs/${cid}`
        } else {
            url = withName ? `https://${cid}.ipfs.w3s.link/${name}` : `https://${cid}.ipfs.w3s.link`;
        }
        const response = await fetch(url);

        const reader = response.body.getReader();

        // Step 2: get total length
        const contentLength = response.headers.get('Content-Length') || 0;// eslint-disable-line

        // Step 3: read the data
        let receivedLength = 0; // received that many bytes at the moment
        const chunks = []; // array of received binary chunks (comprises the body)
        while(true) {
            const {done, value} = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;// eslint-disable-line 
            if (opts.progress) {
                opts.progress({total: contentLength, loaded: receivedLength});
            }
        }
        const file = new File(chunks, name || 'Unknown.dat');
        return {response, files:[file]};
    }

    async web_storage_get(cid, name, sp, progress) {
        // mode: sender accessing web3storage method
        let url = null;
        
        try {

            this.getAbortController = new AbortController();
            const res = await this.__myweb_storage_get(cid, {signal: this.getAbortController.signal, withName: USE_DAG_FILE, progress: progress}, sp, name);
            url = res.response.url;
            if (!res.response.ok) {
                if (res.response.status === 504 || res.response.status === 524) {
                    throw ServerError.httpError({statusText: Strings.error.client.timeout_retry})
                }
            }
            this.getAbortController = null;
            // const files = await res.files();
            const file = res.files[0];
            return file;
        } catch (e) {
            this.getAbortController = null;
            if (e.message && e.message.startsWith('block with cid') && e.message.endsWith('no found')) {
                await this.clearCache(url);
            }
            throw e;
        }
    }
    async ipns_direct_get(ipnsName, fileName, sp, progress) {
        const url = 'ipns://' + ipnsName + '/' + fileName;
        let response = await fetch(url, {mode: 'cors'});
        if (response.code !== 200) {
            throw ServerError.httpError(response);
        }

        const reader = response.body.getReader();

        // Step 2: get total length
        const contentLength = +response.headers.get('Content-Length');

        // Step 3: read the data
        let receivedLength = 0; // received that many bytes at the moment
        const chunks = []; // array of received binary chunks (comprises the body)
        while(true) {
            const {done, value} = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;

            // console.log(`Received ${receivedLength} of ${contentLength}`);
            if (progress) {
                progress({loaded: receivedLength, total: contentLength, ipnsName: ipnsName, fileName: fileName})
            }
        }

        // Step 4: concatenate chunks into single Uint8Array
        // const chunksAll = new Uint8Array(receivedLength); // (4.1)
        // let position = 0;
        // for(let chunk of chunks) {
        //     chunksAll.set(chunk, position); // (4.2)
        //     position += chunk.length;
        // }

        const file = new File(chunks, fileName);
        // const file = await this.axios_get(url, name, progress);
        return file;
    }

    async ipfs_direct_get(cid, name, sp, progress) {
        const url = 'ipfs://' + cid + '/' + name;
        let response = await fetch(url, {mode: 'cors'});
        if (response.status !== 200) {
            throw ServerError.httpError(response);
        }

        const reader = response.body.getReader();

        // Step 2: get total length
        const contentLength = +response.headers.get('Content-Length');

        // Step 3: read the data
        let receivedLength = 0; // received that many bytes at the moment
        const chunks = []; // array of received binary chunks (comprises the body)
        while(true) {
            const {done, value} = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;

            // console.log(`Received ${receivedLength} of ${contentLength}`);
            if (progress) {
                progress({loaded: receivedLength, total: contentLength, cid: cid, name: name})
            }
        }

        // Step 4: concatenate chunks into single Uint8Array
        // const chunksAll = new Uint8Array(receivedLength); // (4.1)
        // let position = 0;
        // for(let chunk of chunks) {
        //     chunksAll.set(chunk, position); // (4.2)
        //     position += chunk.length;
        // }

        const file = new File(chunks, name);
        // const file = await this.axios_get(url, name, progress);
        return file;
    }

    async do_get(cid, name = 'message.json', sp, progress, fnIndex = 0, ignoreIPFS = false) {

        // web_storage_get
        // cf_ipfs_get
        // cloudflare_ipfs_get
        // w3s_get
        // ipfs_get
        // dweb_get
        const fnLen = ignoreIPFS ? this.getFunctions.length - 1 : this.getFunctions.length;
        const getFn = this.getFunctions[fnIndex++];
        try {
            // const file = await getFn.call(this, cid, name, progress);
            const file = await getFn(cid, name, sp, progress);
            return file;
        } catch (e) {
            console.error(e);
            const cancelled = (e.code === "ERR_CANCELED") ||  (e.code === DOMException.ABORT_ERR);
            if (cancelled) {
                throw e;
            }
            if (fnIndex >= fnLen) {
                throw e;
            }
            if (e.name === 'ServerError' && e.code === ServerError.CODE_NOT_FOUND) {
                throw e;
            }
            const file = await this.do_get(cid, name, sp, progress, fnIndex, ignoreIPFS);
            return file;
        }
    }

    async isBrave() {
        return (window.navigator.brave && await window.navigator.brave.isBrave()) || false;
    }

    async __resolveEnterpriseContacts(name) {
        let resp = await fetch(`${PUSH_SERVER_ADDRESS}/api/enterprise-contacts/${name}`, {
            method: "GET", 
            mode: "cors",
            headers: {
                'Authorization': `Bearer ${window.appConfig.pushApiToken}`
            },
        })

        if (resp.status !== 200) {
            console.log('http error, code:', resp.status, ', msg:', resp.statusText);
            throw ServerError.httpError(resp);
        }
        const res = await resp.json();
        if (res.code !== 0) {
            throw ServerError.from(res);
        }
        return res.data;
    }

    async __updateEnterpriseContacts(key, cid) {

        const digest = Digest.create(identity.code, key.public.bytes)
        const nameCid = CID.createV1(libp2pKeyCode, digest).toString(base36);
        const name = nameCid.toString();
        // publicKey, crypton, signature

        
        const publicKey = await this.base64Encode(key.public.bytes);
        const msg = new TextEncoder().encode(cid);
        const signatureBuffer = await key.sign(msg);
        const signature = await this.base64Encode(signatureBuffer);

        let resp = await fetch(`${PUSH_SERVER_ADDRESS}/api/enterprise-contacts`, {
            method: "POST", 
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.appConfig.pushApiToken}`
            },
            body: JSON.stringify({ name, crypton: cid, signature, publicKey })
        })

        if (resp.status !== 200) {
            console.log('http error, code:', resp.status, ', msg:', resp.statusText);
            throw ServerError.httpError(resp);
        }
        const res = await resp.json();
        if (res.code !== 0) {
            throw ServerError.from(res);
        }
        return name;
    }
    async __resolveMailList1() {
        let resp = await fetch(`${PUSH_SERVER_ADDRESS}/api/mail-list/1`, {
            method: "GET", 
            mode: "cors",
            headers: {
                'Authorization': `Bearer ${window.appConfig.pushApiToken}`
            },
        })

        if (resp.status !== 200) {
            console.log('http error, code:', resp.status, ', msg:', resp.statusText);
            throw ServerError.httpError(resp);
        }
        const res = await resp.json();
        if (res.code !== 0) {
            throw ServerError.from(res);
        }
        return res.data;
    }

    async __updateMailList1(cid) {
        let resp = await fetch(`${PUSH_SERVER_ADDRESS}/api/mail-list`, {
            method: "POST", 
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.appConfig.pushApiToken}`
            },
            body: JSON.stringify({cid: cid, deviceId: 1}) 
        })

        if (resp.status !== 200) {
            console.log('http error, code:', resp.status, ', msg:', resp.statusText);
            throw ServerError.httpError(resp);
        }
        const res = await resp.json();
        if (res.code !== 0) {
            throw ServerError.from(res);
        }
    }
    async __resolveMailList() {
        try {
            const mailList = await window.plexiMailService.getMailList(1);
            if (!mailList || mailList.length === 0) {
                ServerError.notFoundError('MailList not found');
            }
            if (Array.isArray(mailList)) {
                return mailList[0];
            }
            return mailList;
        } catch (e) {
            console.error(e);
            ServerError.notFoundError('MailList not found');
        }
    }
    async __updateMailList(cid) {
        // const address = await this.getMySignalAddress();
        await window.plexiMailService.saveMailList(1, cid);
    }

    async deleteMailList() {
        const success =  await window.plexiMailService.deleteMailList(1);
        if (!success) {
            throw new Error('Failed to remove old mail list.');
        }

        await this.removeFile(window.appConfig.mailFoldersCid);
        this.folders = null;
        window.appConfig.mailFoldersCid = null;

        // await this.__updateMailList(cid);
        // window.appConfig.

    }

    async get(cid, name = 'message.json', sp, progress, fnIndex = 0, ignoreIPFS = false) {
        if (typeof sp === 'undefined' || sp === null || sp === 0) {
            sp = StorageProvider.LightHouse;
        }

        /*
        if (await this.isBrave()) {
            try {
                const file = await this.ipfs_direct_get(cid, name, progress);
                return file;
            } catch (e) {
                console.error(e);
                if (e.name === 'ServerError' && e.code === ServerError.CODE_NOT_FOUND) {
                    throw e;
                }
                const file = await this.do_get(cid, name, progress, fnIndex);
                return file;
            }
        } else {
            const file = await this.do_get(cid, name, progress, fnIndex);
            return file;
        }*/
        const file = await this.do_get(cid, name, sp, progress, fnIndex, ignoreIPFS);
        return file;
    }

    async getWithoutCache(cid, name = 'message.json', progress, fnIndex = 0, ignoreIPFS = false) {
        let url = null;

        try {
            this.getAbortController = new AbortController();
            if (progress) {
                this.progressOptions[cid] = { onProgress: (progressInfo) => {
                    progress({total: progressInfo.total, loaded: progressInfo.transferred})
                }};
            }

            const res = await this.web3StorageClientWithoutCache.get(cid, {signal: this.getAbortController.signal});
            // if (!res.ok()) {
            //     return res.status
            // }
            url = res.url;

            if (!res.ok) {
                if (res.status === 504 || res.status === 524) {
                    throw ServerError.httpError({statusText: Strings.error.client.timeout_retry})
                }
            }

            const files = await res.files();
            const exists = Boolean(files);
            if (!exists) {
                throw ServerError.notFoundError('block with cid ' + cid + ' without content');
            }
            this.getAbortController = null;

            delete this.progressOptions[cid];
            const file = files[0];
            return file;
        } catch (e) {
            this.getAbortController = null;
            
            delete this.progressOptions[cid];
            if (e.message && e.message.startsWith('block with cid') && e.message.endsWith('no found')) {
                await this.clearCache(url);
            }
            throw e;
        }
    }

    async progressFetch(resource, options) {
        const self = this;
        let cid = null;
        if (typeof resource === 'string') {
            const idx = resource.lastIndexOf('/');
            if (idx >= 0) {
                cid = resource.substring(idx + 1);
            }
        }
        const progressOption = (cid && cid.length > 0) ? self.progressOptions[cid] : null;
        return fetch(resource, options).then(fetchProgress({
            onProgress: (progress) => {
                // console.log('download progress: ', progress);
          // A possible progress report you will get
          // {
          //    total: 3333,
          //    transferred: 3333,
          //    speed: 3333,
          //    eta: 33,
          //    percentage: 33
          //    remaining: 3333,
          
          // }
                if (progressOption && progressOption.onProgress) {
                    progressOption.onProgress(progress);
                }
            },
            onComplete:  () => {
                if (progressOption && progressOption.onComplete) {
                    progressOption.onComplete();
                }
                if (cid) {
                    delete self.progressOptions[cid];
                }
            },
            onError: (error) => {
                if (progressOption && progressOption.onError) {
                    progressOption.onError(error);
                }
                
                if (cid) {
                    delete self.progressOptions[cid];
                }
            }
        }))
    }

    async nocacheFetch(resource, options) {
        const self = this;
        if (!options.headers) {
            options.headers = {};
        }
        options['mode'] = 'cors';
        options['cache'] = 'no-cache';
        options.headers['pragma'] = 'no-cache';
        options.headers['cache-control'] = 'no-cache';
        return await self.progressFetch(resource, options);
        // return await fetch(resource, options, progressOptions);
        // fetch
    }
    #signalEncryptionKey = null;
    async initSignalService(selectedAccount, progress=null) {
        this.selectedAccount = selectedAccount;
        await this.initCryptServiceIfNeeded();
        if ((window.appConfig.web3StorageApiToken && window.appConfig.web3StorageApiToken.length > 0)
            || (window.appConfig.lightHouseApiKey && window.appConfig.lightHouseApiKey.length > 0)) {
            if (!this.signalService) {
                const keyStore = PUSH_SERVER_ADDRESS + '/api/signal';
                const encryptionKey = await this.cryptoService.indexedDBEncryptionKey();
                this.#signalEncryptionKey = encryptionKey;
                this.signalService = new SignalService(selectedAccount, selectedAccount, keyStore, window.appConfig.pushApiToken, encryptionKey);
                this.#signalServiceMap[selectedAccount] = this.signalService;
                const txHash = await this.signalService.createIdIfNeeded(progress);
                
                if (txHash) {
                    if (window.appConfig.mailAddressNeedToBeVerified) {
                        window.appConfig.txHash = txHash;
                    }
                    if (progress) {
                        progress(SignalInitStep.SaveSignalProtocolStore);
                    }
                    await this.getFolders();
                    await this.syncFoldersFile();
                }
                if (progress) {
                    progress(SignalInitStep.Completed);
                }
            } else {
                await this.signalService.checkPreKeys(progress);
            }
            const cachedFolders = await this.signalService.signalProtocolStore.getFolders();
            if (!cachedFolders && this.folders) {
                const folderWithOutBackup = {...this.folders};
                delete folderWithOutBackup[BACKUP_FOLDER];
                await this.signalService.signalProtocolStore.saveFolders(folderWithOutBackup);
            }
        }
    }

    async waitTransactionReceiptMined(selectedAccount, interval=5000) {
        if (this.signalService === null) {
            await this.initSignalService(selectedAccount)
        }
        const hash = window.appConfig.txHash;
        if (!hash || hash === '') {
            if (!this.folders) {
                await this.getFolders();
            }
            return;
        }
        const result = await this.signalService.waitTransactionReceiptMined(hash, interval);
        if (result.status) {
            if (!this.folders) {
                await this.getFolders();
            }
            await this.syncFoldersFile()
            return;
        }
        throw ClientError.txError('Trasaction is rejected');
    }

    async reset(currentOnly=false) {
        const wallets = await window.wallet.getWalletList();
        if (!wallets || wallets.length <= 1 || !window.appConfig.privacyLevelIsNormal) {
            currentOnly = false;
        }
        if (currentOnly) {

            const currentAccount = this.selectedAccount || window.appConfig.recentActiveAccount
            window.appConfig.mnemonic = null;
            window.appConfig.masterKey = null;

            const keys = Object.getOwnPropertyNames(localStorage).filter(key => { 
                return key.startsWith(`${currentAccount}.`)
            });
            for(const key of keys) {
                localStorage.removeItem(key);
            }
            await this.resetSignalServiceFor(currentAccount);
            // await this.clearCacheStorage();
            this.cryptoService = new CryptoService();
            this.taskQueue = new TaskQueue();

        } else {
            window.appConfig.mnemonic = null;
            window.appConfig.masterKey = null;
            localStorage.clear();
            await this.resetSignalService();
            await this.clearCacheStorage();
            this.cryptoService = new CryptoService();
            this.taskQueue = new TaskQueue();

            await this.web3StorageClient.reset();
        }
    }

    async signalIsInitialized(account) {
        if (this.signalService) {
            return await this.signalService.isInitialized();
        } else {
            return SignalService.isInitializedFor(account);
        }
    }
    async resetSignalService() {
        if (this.signalService) {
            await this.signalService.reset();
            this.signalService = null;
        } else {
            await SignalService.clear();
        }
    }

    async resetSignalServiceFor(account) {
        if (this.signalService) {
            await this.signalService.resetFor(account);
            this.signalService = null;
        } else {
            await SignalService.clearFor(account);
        }
    }

    async sendAck(account, deviceId, envelopes) {
        await window.plexiMailService.removeMails({
            copied: {
                sender: {account, deviceId}, 
                envelopes
            }
        });
    }

    stopNewMessageListener() {
        
    }


    doStartNewMessageListener(account, deviceId, onNewMessage, onKickOut, onError) {
        const self = this;

        window.plexiMailService.getMails(deviceId).then(recievedMessages => {
            if (!Array.isArray(recievedMessages)) {
                recievedMessages = [recievedMessages];
            }
            for(const recievedMessage of recievedMessages) {
                if ('notify' in recievedMessage) {
                    const newMessages = self.formatEnvelopes(recievedMessage.notify.envelopes || []);
                    if (newMessages.length > 0) {
                        let changed = false;
                        newMessages.forEach((newMessage) => {
                            newMessage.flags = MessageFlag.MessageFlagNone;
                            if (newMessage.type === MailEncryptionType.plaintext) {
                                newMessage.decryptedHeader = JSON.parse(newMessage.encryptedHeader);
                            }
                            
                            if(!(self.folders[INBOX_FOLDER].messages[newMessage.uid])) {
                                self.folders[INBOX_FOLDER].messages[newMessage.uid] = newMessage;
                                changed = true;
                            }

                            if (newMessage.ct) {
                                if (typeof newMessage.ct === 'string') {
                                    newMessage.ct = JSON.parse(newMessage.ct);
                                } else if (Array.isArray(newMessage.ct)) {
                                    newMessage.ct = JSON.parse(newMessage.ct[0]);
                                } else {
                                    newMessage.ct = {}
                                }
                                if (newMessage.ct.id && newMessage.ct.id.length > 0) {
                                    self.processContractMessage(newMessage);
                                }
                            }
                        })
                        if (changed) {

                            console.log('--- syncFoldersFile: new message coming');
                            self.syncFoldersFile().then(() => {
                                return self.getMySignalAddress();
                            }).then((myAddress) => {
                                const envelopes = newMessages.map((message) => { return {uid: message.uid, type: message.type} });
                                return self.sendAck(account, myAddress.deviceId, envelopes)
                            }).then(() => {
                                onNewMessage(self.folders, null);
                                setTimeout(() => {
                                    self.doStartNewMessageListener(account, deviceId, onNewMessage, onKickOut, onError)
                                }, 10000);
                            }).catch((err) => {
                                onNewMessage(null, err);
                                setTimeout(() => {
                                    self.doStartNewMessageListener(account, deviceId, onNewMessage, onKickOut, onError)
                                }, 10000);
                            });
                        } else {
                            self.getMySignalAddress().then((myAddress) => {
                                const envelopes = newMessages.map((message) => { return {uid: message.uid, type: message.type}});
                                return self.sendAck(account, myAddress.deviceId, envelopes)
                            }).then(() => {
                                setTimeout(() => {
                                    self.doStartNewMessageListener(account, deviceId, onNewMessage, onKickOut, onError)
                                }, 10000);
                            }).catch(e => {
                                console.log(e);
                                setTimeout(() => {
                                    self.doStartNewMessageListener(account, deviceId, onNewMessage, onKickOut, onError)
                                }, 10000);
                            });
                        }
                    } else {
                        setTimeout(() => {
                            self.doStartNewMessageListener(account, deviceId, onNewMessage, onKickOut, onError)
                        }, 10000);
                    }
                } else if ('kick-out' in recievedMessage) {
                    onKickOut();
                } else if ('sentFeedback' in recievedMessage) {
                    // ignore
                } else if ('error' in recievedMessage) {
                    console.error('WsErrorMessage: ', recievedMessage);
                    if (recievedMessage.error.code !== 0) {
                        const error = ServerError.from(recievedMessage.error)
                        onError(error);
                    }

                    setTimeout(() => {
                        self.doStartNewMessageListener(account, deviceId, onNewMessage, onKickOut, onError)
                    }, 10000);
                } else {
                    console.error('invalid msg: ', recievedMessage);
                }
            }
        }).catch(e => {
            onError(e);

            setTimeout(() => {
                self.doStartNewMessageListener(account, deviceId, onNewMessage, onKickOut, onError)
            }, 10000);
        });
    }
    startNewMessageListener(account, onNewMessage, onKickOut, onError) {
        const self = this;
        
        self.signalService.signalProtocolStore.getDeviceId().then(deviceId => {
            return self.doStartNewMessageListener(account, deviceId, onNewMessage, onKickOut, onError);
        })
    }

    processContractMessage(message) {
        
        /* 
        message.ct = {
            id: "xxxxxx",
            mode: 'quick-sign|contract-thread',
            action: "create-discussion|reply-discussion|create-signature-round|sign-signature-round|end-signature-round",
            quick-sign-data: {
                id: <thread-id>,
                required: []
            },
            create-new-thread: {
                
            },
            create-discussion: {
                id: <>
            },
            reply-discussion: {
                id: <>
            },
            create-signature-round: {
                id: <>,
                required: []
            }
            sign-signature-round: {
                id: <>,
            }
            end-signature-round: {
                id: <>
                reason: ...
            }
        }
        */
        if (!this.folders[CONTRACT_FOLDER]) {
            this.folders[CONTRACT_FOLDER] = { name: 'PlexiSign', type: FolderType.contract, icon: '', order: 2, contracts: {}, archived: {} };
        }
        
        if (!this.folders[CONTRACT_FOLDER]['contracts'][message.ct.id]) {
            if (this.folders[CONTRACT_FOLDER]['archived'][message.ct.id]) {
                message.flags |= MessageFlag.MessageFlagSeen;
                message.flags |= MessageFlag.MessageFlagDeleted;
                console.error('ignored, need to be processed');
                return;
            }
            const contract = {
                mode: message.ct.mode, 
                id: message.ct.id,
                discussions: {},
                created: message.sendDate,
                signatureRounds: {},
            };
            this.folders[CONTRACT_FOLDER]['contracts'][message.ct.id] = contract;
        }
        const contract = this.folders[CONTRACT_FOLDER]['contracts'][message.ct.id];

        switch(message.ct.action) {
            case ContractConstants.Actions.CreateThread: {

                contract.moderator = message.from;
                contract.discussions[message.uid] = {...message};
                break;
            }
            case ContractConstants.Actions.CreateDiscussion: {
                // let discussion = contract.discussions[message.ct.data.id];
                // if (!discussion) {
                //     discussion = {
                //         id: message.ct.data.id,
                //         messages: {}
                //     };
                //     contract.discussions[message.ct.data.id] = discussion;
                // }
                // discussion.created = message.sendDate
                // discussion.messages[message.uid] = {...message};
                contract.discussions[message.uid] = {...message};
                if (!contract.moderator) {
                    contract.moderator = message.from;
                }
                break;
            }
            case ContractConstants.Actions.ReplyDiscussion: {
                // let discussion = contract.discussions[message.ct.data.id];
                // if (!discussion) {
                //     discussion = {
                //         id: message.ct.data.id,
                //         created: message.sendDate,
                //         messages: {}
                //     };
                //     contract.discussions[message.ct.data.id] = discussion;
                // }
                // discussion.messages[message.uid] = {...message};
                contract.discussions[message.uid] = {...message};
                break;
            }
            case ContractConstants.Actions.CreateSignatureRound: {
                if (contract.mode === ContractConstants.ThreadMode.QuickSign) {
                    contract.moderator = message.from;
                }
                let signatureRound = contract.signatureRounds[message.ct.data.id];
                if (!signatureRound) {
                    signatureRound = {
                        id: message.ct.data.id,
                        timeline: {}
                    };
                    contract.signatureRounds[message.ct.data.id] = signatureRound;
                }
                signatureRound.signOptions = message.ct.data.signOptions;
                signatureRound.moderator = message.from;
                signatureRound.created = message.sendDate;
                signatureRound.timeline[message.uid] = {...message};
                break;
            }
            case ContractConstants.Actions.SignSignatureRound: 
            case ContractConstants.Actions.EndSignatureRound:
            case ContractConstants.Actions.CancelSignatureRound:  {
                let signatureRound = contract.signatureRounds[message.ct.data.id];
                if (!signatureRound) {
                    signatureRound = {
                        id: message.ct.data.id,
                        created: message.sendDate,
                        timeline: {}
                    };
                    contract.signatureRounds[message.ct.data.id] = signatureRound;
                }
                signatureRound.timeline[message.uid] = {...message};
                break;
            }
            default:
                console.error('Unknown action: ', message.ct.action);
                break;
        }
        message.flags |= MessageFlag.MessageFlagSeen;
        message.flags |= MessageFlag.MessageFlagDeleted;

    }
    async initWeb3StorageClient(apiToken) {
        // this.web3StorageClient = new W3UpWeb3Storage();
        // this.web3StorageClientWithoutCache = this.web3StorageClient;
        await this.initCryptServiceIfNeeded();
    }
    
    async removeFile(cid, ignoreError=true) {
        
        if (!cid || cid.length === 0) {
            return;
        }
        try {
            await this.web3StorageClient.remove(cid);

         } catch (e) {
            if (!ignoreError) {
                throw e;
            } else {
                console.warn('failed to remove file:', e);
            }
        }
        
    }
    async publishIpnsRecord(cid) {
        console.log('>> MailService - publishIpnsRecord');
        
        await this.removeFile(window.appConfig.mailFoldersCid);

        window.appConfig.mailFoldersCid = cid;

        await this.__updateMailList(cid);
    }

    async folderIPNSName() {
        return await this.__resolveMailList();
    }

    async resolveIpnsRecord() {
        const cid = await this.__resolveMailList();
        if (!cid || cid.length === 0) {
            throw new Error('Not found')
        }
        return cid;
    }
      
    async initCryptServiceIfNeeded() {
        if (window.wallet && window.wallet.asDefaultWallet) {
            await this.cryptoService.init()
            return;
        } 
        if (!window.appConfig.encryptedMnemonic || window.appConfig.encryptedMnemonic.length === 0) {
            if (!window.appConfig.mnemonic || window.appConfig.mnemonic === '') {
                window.appConfig.mnemonic = await this.cryptoService.generateMnemonic();
            }
            // const encryptedMnemonic = await this.encrypt(this.selectedAccount, window.appConfig.mnemonic);
            const encryptedMnemonic = await this.encryptMnemonic(window.appConfig.masterKey, window.appConfig.mnemonic);
            window.appConfig.encryptedMnemonic = encryptedMnemonic;
        } else {
            if (!window.appConfig.mnemonic || window.appConfig.mnemonic.length === 0) {
                // const mnemonic = await this.decrypt(this.selectedAccount, window.appConfig.encryptedMnemonic);
                const mnemonic = await this.decryptMnemonic(window.appConfig.masterKey, window.appConfig.encryptedMnemonic);
                window.appConfig.mnemonic = mnemonic;
            }
        }

        if (window.appConfig.mnemonic && window.appConfig.mnemonic.length > 0 && !this.cryptoService.isInitialized) {
            await this.cryptoService.init(window.appConfig.mnemonic)
        }
    }
    
    async displayMnemonicV1() {
        if (window.appConfig.encryptedMnemonic && window.appConfig.encryptedMnemonic.length > 0) {
            const mnemonic = await this.decryptMnemonic(window.appConfig.masterKey, window.appConfig.encryptedMnemonic);
            return mnemonic;
        }
        throw ClientError.invalidParameterError('mnemonic not found');
    }

    async displayMnemonic() {
        const mnemonic = window.wallet ? (await window.wallet.getMnemonicPhrase()) : '';
        return mnemonic;
    }

    async validateMnemonic(mnemonic) {
        return await this.cryptoService.validateMnemonic(mnemonic);
    }

    async initFoldersIfNeeded(recovery=true, sync=true) {
        console.log('>> MailService - initFoldersIfNeeded');
        let folders = null;
        
        if (recovery) {
            try {
                folders = await this.getFolders();
            } catch (e) {
                console.error(e);
            }
            if (folders) {
                // this.folders = folders;
                if (this.foldersChangedListener) {
                    this.foldersChangedListener(folders);
                }
                return;
            }
        }
        await this.initCryptServiceIfNeeded();

        folders = { 
            backup: {},
            inbox: { name: 'INBOX', type: FolderType.inbox, icon: '', order: 1, messages: {} }, 
            contract: { name: 'PlexiSign', type: FolderType.contract, icon: '', order: 2, contracts: {}, archived: {} }, 
            sent: { name: 'Sent', type: FolderType.sent, icon: '', order: 3, next: 1, messages: {} }, 
            trash: { name: 'Trash', type: FolderType.trash, icon: '', order: 4, messages: {} }

        };
        
        this.folders = folders;
        

        if (sync) {
            console.log('syncFoldersFile: initial folders');
            await this.syncFoldersFile();
        }
        if (this.foldersChangedListener) {
            this.foldersChangedListener(folders);
        }
    }
    async createFolder(folder) {
        const uuid = await this.generateUUID();
        const newFolder = {name: folder.name, type: FolderType.custom, icon: '', order: 4, messages: {}};
        if (!folder.path || folder.path === '') {
            // this.folders = {...this.folders};
            this.folders[uuid] = newFolder;
        } else {
            const components = folder.path.split('/')
            let parent = {folders: this.folders};
            for(let segment of components) {
                parent = parent.folders[segment];
            }
            if (!parent.folders) {
                parent.folders = {};
            }
            parent.folders[uuid] = newFolder;
        }

        console.log('syncFoldersFile: create new folder');
        await this.syncFoldersFile();
    }

    async doGetFolderV1(progress = null) {
        const cid = await this.resolveIpnsRecord();
        const status = await this.web3StorageClient.status(cid);
        if (status) {
            console.log('Folder status: ', status);
        }
        const res = await this.web3StorageClient.get(cid);
        const files = await res.files();
        const exists = Boolean(files);
        if (!exists) {
            return null;
        }
        const folderFile = files[0];
        const content = await folderFile.text() || '';
        const folders = JSON.parse(content);
        return folders;
    }

    async doGetFolder(progress = null) {
        
        if (!window.appConfig.mailFoldersCid || window.appConfig.mailFoldersCid.length === 0) {
            window.appConfig.mailFoldersCid = await this.resolveIpnsRecord();
        }
        
        const ignoreIPFS = !IS_RUNNING_IN_IPFS;
        const folderFile = await this.get(window.appConfig.mailFoldersCid, 'folders.json', window.appConfig.storageProvider, progress, 0, ignoreIPFS);
        if (ENCRYPT_FOLDERS_ENABLED) {
            const ciphertext = await folderFile.arrayBuffer();
            const content = await this.decryptFolders(ciphertext);
            const folders = JSON.parse(content);
            return folders;
        } else {
            const content = await folderFile.text() || '';
            const folders = JSON.parse(content);
            return folders;
        }
    }

    async getFolders(progress = null) {
        console.log('>> MailService - getFolders');
        const cachedFolders = (this.signalService && this.signalService.signalProtocolStore) ? await this.signalService.signalProtocolStore.getFolders() : null;
        if (cachedFolders) {
            this.folders = cachedFolders;
            
            return this.folders;
        }

        const isBrave = await this.isBrave();
        if (isBrave) {
            try {

                const folders = await this.doGetFolder(progress);
                this.folders = folders;
                if (this.signalService && this.signalService.signalProtocolStore) {
                    const folderWithOutBackup = {...folders};
                    delete folderWithOutBackup[BACKUP_FOLDER];
                    await this.signalService.signalProtocolStore.saveFolders(folderWithOutBackup);
                }
                return this.folders;
            } catch (e) {
                if (e.name === 'ServerError' && e.code === ServerError.CODE_NOT_FOUND) {
                    throw e;
                }
                if (IS_RUNNING_IN_IPFS) {
                    const name = await this.folderIPNSName();
                    const folderFile = await this.ipns_direct_get(name, 'folders.json', progress);
                    const content = await folderFile.text() || '';
                    const folders = JSON.parse(content);
                    this.folders = folders;
                    return this.folders;
                }
                throw e;
            }
        }

        const folders = await this.doGetFolder(progress);
        this.folders = folders;

        if (this.signalService && this.signalService.signalProtocolStore) {
            const folderWithOutBackup = {...folders};
            delete folderWithOutBackup[BACKUP_FOLDER];
            await this.signalService.signalProtocolStore.saveFolders(folderWithOutBackup);
        }
        return this.folders;
    }

    async getNonce(account) {
        // let resp = await fetch(PUSH_SERVER_ADDRESS + '/api/snonce/' + account, {mode: "cors"}).then(resp => {
        //     if (resp.status === 200) {
        //         return resp.json();
        //     }
        //     return {code: (resp.status > 0 ? -resp.status : resp.status), msg: resp.statusText};
        // })
        // console.log(resp);
        // if (resp.code !== 0) {
        //     throw ServerError.from(resp);
        // }
        // return resp.data;
        return await this.generateUUID();
    }
    async checkToken(token) {
        
        // let resp = await fetch(PUSH_SERVER_ADDRESS + '/api/token-status?token=' + token, {mode: "cors"}).then(resp => {
        //     if (resp.status === 200) {
        //         return resp.json();
        //     }
        //     return {code: (resp.status > 0 ? -resp.status : resp.status), msg: resp.statusText};
        // })
        // console.log(resp);
        // if (resp.code !== 0) {
        //     throw ServerError.from(resp);
        // }
    }
    async login(form) {
        // let resp = await fetch(PUSH_SERVER_ADDRESS + '/api/login', {
        //     method: "POST", 
        //     mode: "cors",
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(form) 
        // }).then(resp => {
        //     if (resp.status === 200) {
        //         return resp.json();
        //     }
        //     return {code: (resp.status > 0 ? -resp.status : resp.status), msg: resp.statusText};
        // });
        // if (resp.code !== 0) {
        //     throw ServerError.from(resp);
        // }
        // return resp.data;

        return 'deprecated';
    }

    async getTimestamp() {
        // let resp = await fetch(PUSH_SERVER_ADDRESS + '/api/timestamp?token=' + window.appConfig.pushApiToken, {mode: "cors"}).then(resp => {
        //     if (resp.status === 200) {
        //         return resp.json();
        //     }
        //     return {code: (resp.status > 0 ? -resp.status : resp.status), msg: resp.statusText};
        // })
        // console.log(resp);
        // if (resp.code !== 0) {
        //     throw ServerError.from(resp);
        // }
        // return resp.data;
        const res = await window.plexiMailService.getTimestamp();
        const timestamp = Number(res);
        return timestamp;
        // return res;
    }

    async sendNotify(envelope) {
        console.log('>> sendNotify: ', envelope);
        // const result = await window.plexiMailService.sendMail({
        //     notify: {
        //         sender: {
        //             account: envelope.address[0],
        //             deviceId: envelope.deviceId[0],
        //         },
        //         envelopes: [envelope]
        //     }
        // });
        
        await window.plexiMailService.sendMails({
            notify: {
                sender: {
                    account: envelope.address[0],
                    deviceId: envelope.deviceId[0],
                },
                envelopes: [envelope]
            }
        });
        console.log('>> sendNotify: sent');

        // const result = await window.plexiMailService.sendMails({
        //     notify: {
        //         sender: {
        //             account: envelope.address[0],
        //             deviceId: envelope.deviceId[0],
        //         },
        //         envelopes: [envelope]
        //     }
        // });
        // if ('sentFeedback' in result) {
        //     // ignore
        // } else if ('error' in result) {
        //     console.error('WsErrorMessage: ', result);
        //     if (result.error.code !== 0) {
        //         const error = ServerError.from(result.error)
        //         throw error;
        //     }
        // }
    }

    // async storeAttachments(attachments) {
    //     console.log('>> MailService - storeAttachments');
    //     if (!attachments || attachments.length === 0) {
    //         return []
    //     }
    //     let attachmentMetas = [];
    //     for(let i=0; i<attachments.length; i++) {
    //         const files = getFilesFromPath(attachments[i].path);
    //         const cid = await this.web3StorageClient.put(files);
    //         attachmentMetas.push({
    //             cid: cid,
    //             name: files[0].name,
    //             size: files[0].size
    //         });
    //     }
    //     return attachmentMetas;
    // }
    
    async downloadAttachmentWithAxios(mid, uid, id, cid, name, sp, fromMe = false, progress) {
        const ignoreIPFS = !IS_RUNNING_IN_IPFS;
        const encryptedFile = await this.get(cid, name, sp, progress, 0, ignoreIPFS);
        const decryptedAttchment = await this.decryptAttachment(mid, uid, id, encryptedFile, fromMe);
        return decryptedAttchment;
        // 'https://' + attachment.cid + '.ipfs.dweb.link/' + attachment.name,
    }
    async downloadAttachmentWithoutCache(mid, uid, id, cid, name, fromMe = false, progress) {
        const ignoreIPFS = !IS_RUNNING_IN_IPFS;
        const encryptedFile = await this.getWithoutCache(cid, name, progress, 0, ignoreIPFS);
        const decryptedAttchment = await this.decryptAttachment(mid, uid, id, encryptedFile, fromMe);
        return decryptedAttchment;
    }
    
    async downloadAttachment(mid, uid, id, cid, name, sp, fromMe = false, progress = null) {
        // let attachmentResponse = await this.web3StorageClient.get(cid);
        // const attachments = await attachmentResponse.files();
        // const exists = Boolean(attachments);
        // if (!exists) {
        //     return {code: -1, msg: "Not found"};
        // }
        // const attachment = attachments[0];

        const ignoreIPFS = !IS_RUNNING_IN_IPFS;
        const attachment = await this.get(cid, name, sp, progress, 0, ignoreIPFS);
        const decryptedAttchment = await this.decryptAttachment(mid, uid, id, attachment)
        return decryptedAttchment;
    }
    async uploadAttachments(nextIndex, attachments, encryptionKey, options) {
        console.log('>> MailService - storeAttachments');
        if (!attachments || attachments.length === 0) {
            return []
        }
        let attachmentMetas = [];
        for(let i=0; i<attachments.length; i++) {
            const attachment = attachments[i];
            const attID = i + nextIndex;
            
            const key = encryptionKey ? encryptionKey : await this.createEncryptionKey(attID);

            if (options && options.preEncrypt) {
                options.preEncrypt(i, attachment, attID, key);
            }

            const encryptedAttachment = await this.encryptAttachment(key, attachment);

            const abortController = new AbortController();
            if (options && options.postEncrypt) {
                options.postEncrypt(i, abortController);
            }
            if (options && options.onRootCidReady) {
                options.onRootCidReady(i, attachment, null, attID, key)
            }
            try {
                const cid = await this.web3StorageClient.put([encryptedAttachment], {
                        signal: abortController.signal || null,
                        onRootCidReady: (cid) => {
                            if (options) {
                                options.onRootCidReady(i, attachment, cid, attID, key)
                            }
                        },
                        onUploadProgress: (event) => {
                            console.log('---onUploadProgress:', event);
                            options.onUploadProgress(i, attachment, event);
                        },
                        onShardStored: (meta/*: CARMetadata*/) => {
                            console.log('---onShardStored:', meta);
                        },
                        onStoredChunk: (size) => {
                            if (options) {
                                options.onStoredChunk(i, attachment, size)
                            }
                            
                        },
                        name: attachment.name || 'Unnamed Attachment'
                });

                if (options.onStoredFile) {
                    options.onStoredFile(i, attachment, cid);
                }
                attachmentMetas.push({
                    cid: cid,
                    name: attachment.name,
                    size: attachment.size
                });
            } catch (e) {
                if (e.message === 'upload aborted') {
                    console.error(e);
                    return
                } else {
                    throw e;
                }

            }
        }
        return attachmentMetas;
    }
    async storeAttachments(key, attachments) {
        console.info('>> MailService - storeAttachments');
        if (!attachments || attachments.length === 0) {
            return []
        }
        let attachmentMetas = [];
        for(let i=0; i<attachments.length; i++) {
            const attachment = attachments[i];
            const encryptedAttachment = await this.encryptAttachment(key, attachment);
            const cid = await this.web3StorageClient.put([encryptedAttachment], {
                name: attachment.name || 'Unnamed Attachment'
            });
            attachmentMetas.push({
                cid: cid,
                name: attachment.name,
                size: attachment.size
            });
        }
        return attachmentMetas;
    }

    async storeMessage(message, key=null) {
        const timestamp = await this.getTimestamp();
        console.log('>> MailService - storeMessage');

        // const attachments = await this.storeAttachments(message.attachments);
        const to = MailAddressUtils.formatAddresses(message.to);
        const attachments = message.attachments;
        const header = {uid: message.uid, mid: message.mid, from: message.from, sp: message.sp, to: to, subject: message.subject, attachments: attachments, sendDate: timestamp, sharedSpace: message.sharedSpace, am: window.appConfig.storageProvider};
        if (message.ct) {
            
            if (message.ct.data && message.ct.data.signOptions && message.ct.data.signOptions.phone) {
                const phoneMap = {}
                
                for(const key in message.ct.data.signOptions.phoneMap) {
                    phoneMap[key] = {hmac: message.ct.data.signOptions.phoneMap[key].hmac, phone: message.ct.data.signOptions.phoneMap[key].phone};
                }

                header.ct = {...message.ct, data: {...message.ct.data, signOptions: {...message.ct.data.signOptions, phoneMap}}};    
            } else {
                header.ct = message.ct;    
            }
            // header.ct = {...message.ct, data: {...message.ct.data, signOptions: {...message.ct.data.signOptions, }}};
            if (message.ref) {
                header.ref = message.ref;
            }
            // if (header.ct.data && header.ct.data.signOptions && header.ct.data.signOptions.phone) {
            //     for(const key in header.ct.data.signOptions.phoneMap) {
            //         header.ct.data.signOptions.phoneMap[key].code;
            //     }
            // }
        }
        const headerString = JSON.stringify(header);
        const bodyString = message.body;
        if (!key) {
            const files = [new File([headerString, "\r\n\r\n", bodyString], 'message.json')];
            const cid = await this.web3StorageClient.put(files, {name: 'message.json'});
            return {cid: cid, header: header};
        }
        const plaintext = headerString + "\r\n\r\n" + bodyString;
        const res = await this.cryptoService.encryptMailPart(key, plaintext);
        
        const files = [new File([res.iv, res.ciphertext], 'message.json')];
        const cid = await this.web3StorageClient.put(files, {name: 'message.json'});
        return {cid: cid, header: header};
    }

    async createEncryptionKey(pid=0, password=null) {
        const mid = await this.getNextMessageId();
        const key = await this.cryptoService.createEncryptionKey(this.selectedAccountIndex, mid, pid, password);
        return key;
    }
    
    async getNextMessageId() {
        // const store = new SignalProtocolStoreIndexedDB(selectedAccount);
        if (!this.folders) {
            throw ClientError.invalidParameterError('Message ID is invalid');
        }
        if (!this.folders[SENT_FOLDER]) {
            throw ClientError.invalidParameterError('Message ID is invalid');
        }
        const nextId = this.folders[SENT_FOLDER].next || 1;
        if (!nextId || nextId <= 0) {
            throw ClientError.invalidParameterError('Message ID is invalid');
        }

        return nextId;
    }

    async makeNextMessageId(selectedAccount) {
        if (!this.folders) {
            await this.getFolders()
            if (!this.folders) {
                return null;
            }
        }
        
        if (!this.folders[SENT_FOLDER]) {
            return null;
        }
        const nextId = this.folders[SENT_FOLDER].next || 0;
        return nextId;
    }
    async generateUUID() {
        const uuid = await this.cryptoService.generateUUID();
        return uuid;
    }
    generateUUIDSync() {
        const uuid = this.cryptoService.generateUUIDSync();
        return uuid;
    }
    async mailFoldersIpnsSignKey() {
        if (this.__mailFoldersIpnsSignKey) {
            return this.__mailFoldersIpnsSignKey;
        }
        const foldersPriKey = await this.cryptoService.mailFoldersIpnsSignKey(this.selectedAccountIndex);
        const foldersPriKeyBuffer = keys.marshalPrivateKey(foldersPriKey);
        
        return foldersPriKeyBuffer;
    }
    async mailFoldersEncryptionKey() {
        const key = await this.cryptoService.mailFoldersEncryptionKey(this.selectedAccountIndex);
        return key
    }
    async generateKeyV2(mid, pid=0) {
        const key = await this.cryptoService.generateKeyV2(this.selectedAccountIndex, mid, pid);
        return key
    }
    
    async encryptMessage(key, message) {
        // const header = {from: message.from, to: message.to, subject: message.subject, salt: key.salt, attachments: attachments, sendDate: timestamp};
        
    }
    async encryptAttachment(key, attachment) {
        let fileName = 'unknown';
        if (attachment.constructor === Blob || attachment.constructor === File) {
            fileName = attachment.name
            attachment = await attachment.arrayBuffer();
        }

        const res = await this.cryptoService.encryptMailPart(key.key, attachment);

        const encryptedAttachment = new File([res.iv, res.ciphertext], fileName, {type : 'application/json'});
        res.iv = {iv: null, ciphertext: null};

        return encryptedAttachment;
    }

    async decryptAttachment_v1(uid, id, encryptedAttachment) {
        if (encryptedAttachment.constructor !== Blob && encryptedAttachment.constructor !== File) {
            throw ClientError.invalidParameterError(Strings.error.client.att_not_bob);
        }

        const self = this;
        
        const encryptionKeyBundle = await self.signalService.getEncryptionKeyBundle(uid);
        if (!encryptionKeyBundle) {
            throw ClientError.invalidParameterError(Strings.error.client.enckey_bundle_not_found);
        }
        const encryptionKey = encryptionKeyBundle.parties[id] ? encryptionKeyBundle.parties[id].key : null;
        if (!encryptionKeyBundle) {
            throw ClientError.invalidParameterError(Strings.error.client.enckey_for_att_not_found_1 + id + Strings.error.client.enckey_for_att_not_found_2);
        }

        const key = await self.cryptoService.importKey(encryptionKey);

        const attachmentJSONString = await encryptedAttachment.text();
        const attachmentJSON = JSON.parse(attachmentJSONString);

        const decodedAttachmentJSON = {
            iv: ethers.decodeBase64(attachmentJSON.iv),
            ciphertext: ethers.decodeBase64(attachmentJSON.ciphertext)
        };
        const res = await this.cryptoService.decryptMailPart(key, decodedAttachmentJSON);
        const attachment = new Blob([res], {type : 'application/json'});
        return attachment;
    }

    async decryptAttachment(mid, uid, id, encryptedAttachment, fromMe = false) {
        if (encryptedAttachment.constructor !== Blob && encryptedAttachment.constructor !== File) {
            throw ClientError.invalidParameterError(Strings.error.client.att_not_bob);
        }

        const self = this;
        const encryptionKeyBundle = await self.signalService.getEncryptionKeyBundle(uid);
        let encryptionKey = null;
        if (!encryptionKeyBundle) {
            if (fromMe || self.currentFolder === SENT_FOLDER) {
                encryptionKey = await self.generateKeyV2(mid, id);
                if (!encryptionKey) {
                    throw ClientError.invalidParameterError(Strings.error.client.enckey_bundle_not_found);
                }
            } else {
                throw ClientError.invalidParameterError(Strings.error.client.enckey_bundle_not_found);

            }
        }

        if (!encryptionKey && encryptionKeyBundle) {
            encryptionKey = encryptionKeyBundle.parties[id] ? encryptionKeyBundle.parties[id].key : null;
        }
        if (!encryptionKey) {
            throw ClientError.invalidParameterError(Strings.error.client.enckey_for_att_not_found_1 + id + Strings.error.client.enckey_for_att_not_found_1);
        }

        const key = await self.cryptoService.importKey(encryptionKey);
        encryptedAttachment = await encryptedAttachment.arrayBuffer();
        encryptedAttachment = new Uint8Array(encryptedAttachment);

        const decodedAttachmentJSON = {
            iv: encryptedAttachment.slice(0, CryptoService.BYTES_12).buffer,
            ciphertext: encryptedAttachment.slice(CryptoService.BYTES_12).buffer
        };
        encryptedAttachment = null;
        const res = await this.cryptoService.decryptMailPart(key, decodedAttachmentJSON);
        const attachment = new Blob([res]);// , {type : 'application/json'}
        return attachment;
    }

    async exportKeyBundle(keyBundle, includePwd=false) {
        const mainKey = await this.cryptoService.exportKey(keyBundle.main.key);
        const mainKeyObj = {...keyBundle.main, key: mainKey};

        const partKeys = {}
        for(const attID in keyBundle.parties) {
            const partKey = keyBundle.parties[attID];
            const exportedKey = await this.cryptoService.exportKey(partKey.key);
        
            const keyObj = { ...partKey, key: exportedKey };

            partKeys[attID] = keyObj;
        }
        const pwd = includePwd ? keyBundle.pwd : null;
        const exportedKeyBundle = {pwd, main: mainKeyObj, parties: partKeys};
        return exportedKeyBundle;
    }

    async hasDraft(type='mail') {
        const has = await this.signalService.hasDraft(type);
        return has;
    }
    async removeDraft(type='mail') {
        await this.signalService.removeDraft(type);
    }
    async saveDraft(draft, type='mail') {
        const partKeys = {...draft.partKeys};

        for(const keyId in partKeys) {
            const partKey = partKeys[keyId]
            const exportedKey = await this.cryptoService.exportKey(partKey.key);
            partKeys[keyId] = {...partKey, key: exportedKey};
        }


        await this.signalService.saveDraft(type, {...draft, partKeys});
    }
    async getDraft(type='mail') {
        const draft = await this.signalService.getDraft(type);
        if (draft) {
            for(const keyId in draft.partKeys) {
                const partKey = draft.partKeys[keyId]
                const key = await this.cryptoService.importKey(partKey.key);
                draft.partKeys[keyId].key = key;
            }
        }
        return draft;
    }

    // {id: 'alice@gmail.com', key: 'base64', delegation: 'base64'}
    async saveDelegation(delegation) {
        return await this.signalService.saveDelegation(delegation);
    }

    async loadDelegation(email) {
        const delegation = await this.signalService.loadDelegation(email);
        return delegation;
    }

    async loadAllDelegations() {
        const delegations = await this.signalService.loadAllDelegations();
        return delegations;
    }

    async deleteDelegation(email) {
        await this.signalService.deleteDelegation(email);
    }

    async deleteDelegations(delegations) {
        const delegationArray = Object.values(delegations).map(o => o.value);
        for(const encodedDelegation of delegationArray) {
            const delegation = await this.web3StorageClient.toDelegation(encodedDelegation.space);
            // const cid = delegation.cid.toString();
            const cid = delegation.cid;
            await this.web3StorageClient.revokeDelegation(cid);
            await this.deleteDelegation(encodedDelegation.id);
        }
    }

    async getMySignalAddress() {
        return await this.signalService.getMyAddress();
    }

    async isMe(address) {
        const myAddresses = this.getOrderedAddresses();
        return myAddresses.includes(address);
    }


    isPlexiMailAddress(address) {

        if (address.domain.toLowerCase() === aifiDomain()) {
            return true;
        } else if (address.domain.toLowerCase().startsWith(mailAddressSubdomain())) {
            return true;
        }
        return false;
          
    }

    async sendMessage(keyBundle, message) {
        console.log('>> MailService - sendMessage');
        const myAddressObj = await this.getMySignalAddress();
        
        message.mid = await this.getNextMessageId();
        const addressObject = typeof message.from === 'string' ? this.mapContact(message.from) : message.from;
        
        if (addressObject.name && addressObject.name.length>0) {
            message.from = "\"" + addressObject.name +  "\" <" + addressObject.address + ">";
        } else {
            message.from = addressObject.address;
        }

        message.sp = window.appConfig.storageProvider;

        let {cid, header} = await this.storeMessage(message, keyBundle.main.key);
        if (header) {}

        const to = MailAddressUtils.formatAddresses(message.to);
        const envelope = {cid: cid, uid: message.uid, mid: message.mid, subject: message.subject, from: message.from, to: to /*to: message.to, attachments: header.attachments*/, sendDate: header.sendDate}
        if (message.ct) {
            envelope.ct = message.ct;
        }
        // const exportedKey = await this.cryptoService.exportKey(keyBundle.main.key);
        const exportedKeyBundle = await this.exportKeyBundle(keyBundle, message.type === MailEncryptionType.mixed);

        let signalEnvelope = null;
        if (message.type === MailEncryptionType.signal) {

            // const myAddress = fromEmailAddress.substring(0, fromEmailAddress.indexOf('@'));
            // const myAddress = addressObject.local;
            const myAddress = mailAddressToSignalIdentity(addressObject.address);
            const myDeviceId = await this.signalService.signalProtocolStore.getDeviceId();
            const secureHeader = {
                cid: cid,
                subject: message.subject
            };
            const secureHeaderJSONString = JSON.stringify(secureHeader);
            const encryptedHeader = await this.cryptoService.encryptMailPart(keyBundle.main.key, secureHeaderJSONString);

            const encodedIV = ethers.encodeBase64(encryptedHeader.iv);
            const encodedCiphertext = ethers.encodeBase64(encryptedHeader.ciphertext);
            const encodedRes = {iv: encodedIV, ciphertext: encodedCiphertext};
            const encryptedHeaderJSONString = JSON.stringify(encodedRes);

            const signal = {
                fromAddress: myAddress,
                fromDeviceId: myDeviceId,
                accounts: [],
            };
            // ;
        //    [{
        //         account:
        //         fromAddress:
        //         fromDeviceId:
        //         devices: [{
        //             deviceId:,
        //             ciphertext:
        //         }]
        //    }];

            const exportedKeyJSONString = JSON.stringify(exportedKeyBundle);
            for(let i=0; i<message.to.length; i++) {
                const rcp = message.to[i];
               
               // const account = rcp.substring(0, rcp.indexOf('@'));
                // const account = rcp.local.toLowerCase();
                const account = mailAddressToSignalIdentity(rcp.address);
                const deviceIds = await this.signalService.getExistedDevices(account);
                const signalAccount = {
                    account: account,
                    devices: []
                };
                for(let j=0; j<deviceIds.length; j++) {
                    const device = deviceIds[j];
                    // TODO: Multi-Account
                    const res = await this.signalServiceFor(addressObject.local).encryptEnvelope(account, device.id, exportedKeyJSONString);
                    signalAccount.devices.push({deviceId: device.id, ciphertext: res.ciphertext});
                };
                signal.accounts.push(signalAccount);
           }

            // const exportedKeyJSONString = JSON.stringify(exportedKeyBundle);
            // for(let i=0; i<message.to.length; i++) {
            //     const rcp = message.to[i];
            //     const signalAccount = {};
            //     // const account = rcp.substring(0, rcp.indexOf('@'));
            //     const account = rcp.local.toLowerCase();
            //     const devices = await this.signalService.getExistedDevices(account);
            //     for(let j=0; j<devices.length; j++) {
            //         const device = devices[j];
            //         const res = await this.signalService.encryptEnvelope(account, device.id, exportedKeyJSONString)
            //         signalAccount[device.id] = {
            //             fromAddress: myAddress,
            //             fromDeviceId: myDeviceId,
            //             ciphertext: res.ciphertext
            //         }
            //     };
            //     signal[account] = signalAccount;
            // }
            
            signalEnvelope = {
                am: window.appConfig.storageProvider,
                ct: [],
                to: [],
                mid: message.mid,
                ref: [],
                uid: message.uid,
                expires: 0,
                from: message.from,
                salt: [],
                type: message.type,
                encryptedHeader: encryptedHeaderJSONString, // sig
                address: [myAddress],
                deviceId: [myDeviceId],
                signal: [signal],
                sendDate: header.sendDate,
            };
            /*
            export interface PlexiEnvelope {
                'am' : number,
                'to' : [] | [Array<string>],
                'mid' : bigint,
                'ref' : [] | [string],
                'uid' : string,
                'expires' : bigint,
                'from' : string,
                'salt' : [] | [string],
                'type' : string,
                'encryptedHeader' : string,
                'address' : [] | [string],
                'deviceId' : [] | [SignalDeviceID],
                'signal' : Array<SignalAccount>,
                'sendDate' : bigint,
                'ct' : [] | [string],
            }
            */
            await this.signalService.saveEncryptionKeyBundle(message.uid, exportedKeyBundle);
            
            // ---- debug
            const key = await this.signalService.getEncryptionKeyBundle(message.uid);
            if (key) {
                const decodedKey = await this.cryptoService.importKey(key.main.key);
                const header = await this.cryptoService.decryptMailPart(decodedKey, encryptedHeader, true);
                console.log(header);
            }

        } else if (message.type === MailEncryptionType.mixed) {

            const myAddress = mailAddressToSignalIdentity(addressObject.address);
            const myDeviceId = await this.signalService.signalProtocolStore.getDeviceId();
            // const myAddress = addressObject.local;
            // const myDeviceId = await this.signalService.signalProtocolStore.getDeviceId();
            const secureHeader = {
                cid: cid,
                subject: message.subject
            };
            const secureHeaderJSONString = JSON.stringify(secureHeader);
            const encryptedHeader = await this.cryptoService.encryptMailPart(keyBundle.main.key, secureHeaderJSONString);

            const encodedIV = ethers.encodeBase64(encryptedHeader.iv);
            const encodedCiphertext = ethers.encodeBase64(encryptedHeader.ciphertext);
            const encodedRes = {iv: encodedIV, ciphertext: encodedCiphertext};
            const encryptedHeaderJSONString = JSON.stringify(encodedRes);

            const passkey = await this.createEncryptionKey(0, keyBundle.pwd);

            const exportedKeyJSONString = JSON.stringify(exportedKeyBundle);
            const encryptedEncryptionKey = await this.cryptoService.encryptMailPart(passkey.key, exportedKeyJSONString);
            

            const encodedEncryptionKeyIV = ethers.encodeBase64(encryptedEncryptionKey.iv);
            const encodedEncryptionKeyCiphertext = ethers.encodeBase64(encryptedEncryptionKey.ciphertext);
            const encodedEncryptionKeyRes = {salt: passkey.salt, iv: encodedEncryptionKeyIV, ciphertext: encodedEncryptionKeyCiphertext};
            const encodedEncryptionKeyJSONString = JSON.stringify(encodedEncryptionKeyRes);


            const signal = {
                fromAddress: myAddress,
                fromDeviceId: myDeviceId,
                accounts: [],
            };

            // const exportedKeyJSONString = JSON.stringify(exportedKeyBundle);
            const pwdTo = [];
            for(let i=0; i<message.to.length; i++) {
                const rcp = message.to[i];

                /*
                const isEthereumAddress = MailAddressUtils.isValidEthereumAddress(
                    rcp.local
                );
                */

                if (this.isPlexiMailAddress(rcp)) {
                    // const account = rcp.substring(0, rcp.indexOf('@'));
                    // const account = rcp.local.toLowerCase();
                    const account = mailAddressToSignalIdentity(rcp.address);
                    const deviceIds = await this.signalService.getExistedDevices(account);
                    const signalAccount = {
                        account: account,
                        devices: []
                    };
                    for(let j=0; j<deviceIds.length; j++) {
                        const device = deviceIds[j];
                        // TODO: Multi-Account
                        const res = await this.signalServiceFor(addressObject.local).encryptEnvelope(account, device.id, exportedKeyJSONString);
                        signalAccount.devices.push({deviceId: device.id, ciphertext: res.ciphertext});
                    };
                    signal.accounts.push(signalAccount);
                } else {
                    pwdTo.push(rcp);
                }
           }

            const formattedPwdTo = MailAddressUtils.formatAddresses(pwdTo);
            signalEnvelope = {
                am: window.appConfig.storageProvider,
                ct: [],
                to: [formattedPwdTo],
                mid: message.mid,
                ref: [],
                uid: message.uid,
                expires: 0,
                from: message.from,
                salt: [encodedEncryptionKeyJSONString],
                type: message.type,
                encryptedHeader: encryptedHeaderJSONString, // sig
                address: [myAddress],
                deviceId: [myDeviceId],
                signal: [signal],
                sendDate: header.sendDate,
            };

            await this.signalService.saveEncryptionKeyBundle(message.uid, exportedKeyBundle);
            
            // ---- debug
            const key = await this.signalService.getEncryptionKeyBundle(message.uid);
            if (key) {
                const decodedKey = await this.cryptoService.importKey(key.main.key);
                const header = await this.cryptoService.decryptMailPart(decodedKey, encryptedHeader, true);
                console.log(header);
            }

        } else if (message.type === MailEncryptionType.password) {
            const secureHeader = {
                cid: cid,
                subject: message.subject
            };
            const secureHeaderJSONString = JSON.stringify(secureHeader);
            const encryptedHeader = await this.cryptoService.encryptMailPart(keyBundle.main.key, secureHeaderJSONString);
            
            const encodedIV = ethers.encodeBase64(encryptedHeader.iv);
            const encodedCiphertext = ethers.encodeBase64(encryptedHeader.ciphertext);
            const encodedRes = {iv: encodedIV, ciphertext: encodedCiphertext};
            const encryptedHeaderJSONString = JSON.stringify(encodedRes);

            const key = await this.createEncryptionKey(0, keyBundle.pwd);
            const exportedKeyJSONString = JSON.stringify(exportedKeyBundle);

            const encryptedEncryptionKey = await this.cryptoService.encryptMailPart(key.key, exportedKeyJSONString);
            

            const encodedEncryptionKeyIV = ethers.encodeBase64(encryptedEncryptionKey.iv);
            const encodedEncryptionKeyCiphertext = ethers.encodeBase64(encryptedEncryptionKey.ciphertext);
            const encodedEncryptionKeyRes = {salt: key.salt, iv: encodedEncryptionKeyIV, ciphertext: encodedEncryptionKeyCiphertext};
            const encodedEncryptionKeyJSONString = JSON.stringify(encodedEncryptionKeyRes);


            signalEnvelope = {
                am: window.appConfig.storageProvider,
                to: [to],
                mid: message.mid, 
                ref: [],
                uid: message.uid,
                expires: 0,
                from: message.from,
                // salt: message.salt,
                salt: [encodedEncryptionKeyJSONString],
                encryptedHeader: encryptedHeaderJSONString,
                address: [myAddressObj.account],
                deviceId: [myAddressObj.deviceId],
                signal: [],
                type: message.type, // pwd
                sendDate: header.sendDate,
                ct: [],
            };
            /*
            export interface PlexiEnvelope {
                'am' : number,
                'to' : [] | [Array<string>],
                'mid' : bigint,
                'ref' : [] | [string],
                'uid' : string,
                'expires' : bigint,
                'from' : string,
                'salt' : [] | [string],
                'type' : string,
                'encryptedHeader' : string,
                'address' : [] | [string],
                'deviceId' : [] | [SignalDeviceID],
                'signal' : Array<SignalAccount>,
                'sendDate' : bigint,
                'ct' : [] | [string],
            }
            */
            
            const exportedKeyBundleWithPassword = {...exportedKeyBundle};
            if (keyBundle.pwd && keyBundle.pwd.length > 0) {
                exportedKeyBundleWithPassword.pwd = keyBundle.pwd;
            }
            await this.signalService.saveEncryptionKeyBundle(message.uid, exportedKeyBundleWithPassword);
        } else if (message.type === MailEncryptionType.plaintext) {

            /*
            export interface PlexiEnvelope {
                'am' : number,
                'to' : [] | [Array<string>],
                'mid' : bigint,
                'ref' : [] | [string],
                'uid' : string,
                'expires' : bigint,
                'from' : string,
                'salt' : [] | [string],
                'type' : string,
                'encryptedHeader' : string,
                'address' : [] | [string],
                'deviceId' : [] | [SignalDeviceID],
                'signal' : Array<SignalAccount>,
                'sendDate' : bigint,
                'ct' : [] | [string],
            }
            */

            signalEnvelope = {
                am: window.appConfig.storageProvider,
                to: [to],
                mid: message.mid, 
                ref: [],
                uid: message.uid,
                expires: 0,
                from: message.from,
                salt: [],
                type: message.type, // pt
                encryptedHeader: JSON.stringify({
                    cid: cid,
                    subject: message.subject
                }),
                address: [myAddressObj.account],
                deviceId: [myAddressObj.deviceId],
                signal: [],
                sendDate: header.sendDate,
                ct: [],
            };
        }

        
        // const signalEnvelope = {
        //     uid: uid,
        //     from: message.from,
        //     type: 'signal', // passwd
        //     signal: signal,
        //     header: JSON.stringify(envelope)
        // };
        
        
        

        // let envelope = {cid, uuid, signal, decrypted, decryptedHeader:{}, header: {cid: cid, subject: message.subject, from: message.from, to: message.to, sendDate: sendDate.toISOString()}}
        if (message.ct) {
            // signalEnvelope.ct = {...message.ct};
            // signalEnvelope.ct = {...message.ct, data: {...message.ct.data, signOptions: null}};
            signalEnvelope.ct = [JSON.stringify({...message.ct, data: {...message.ct.data, signOptions: null, piiOptions: null}})]
            if (message.ref) {
                signalEnvelope.ref = [message.ref];
            }

            // if (signalEnvelope.ct.data && signalEnvelope.ct.data.signOptions) {
            //     delete signalEnvelope.ct.data.signOptions;
            // }
        }

        // signalEnvelope.sp = window.appConfig.storageProvider;
        await this.sendNotify(signalEnvelope);

        signalEnvelope.decryptedHeader = envelope;
        delete signalEnvelope.address;
        delete signalEnvelope.deviceId;
        
        this.folders[SENT_FOLDER].next = this.folders[SENT_FOLDER].next + 1;
        this.folders[SENT_FOLDER].messages[signalEnvelope.uid] = signalEnvelope;

        console.log('syncFoldersFile: message is sent');
        await this.syncFoldersFile();
    }

    async encryptFolders(plaintext) {
        const key = await this.mailFoldersEncryptionKey();
        const res = await this.cryptoService.encryptMailPart(key, plaintext);
        return res;
    }

    async decryptFolders(ciphertext) {
        const key = await this.mailFoldersEncryptionKey();
        let content = new Uint8Array(ciphertext);
        content = {
            iv: content.slice(0, CryptoService.BYTES_12).buffer,
            ciphertext: content.slice(CryptoService.BYTES_12).buffer
        };
        const plaintext = await this.cryptoService.decryptMailPart(key, content, true);
        return plaintext;
    }

    async saveFolderLocally() {
        if (this.signalService && this.signalService.signalProtocolStore) {
            const folderWithOutBackup = {...this.folders};
            delete folderWithOutBackup[BACKUP_FOLDER];
            await this.signalService.signalProtocolStore.saveFolders(folderWithOutBackup);
        }
    }

    async doSyncFoldersFile(id) {
        console.log('>> MailService - doSyncFoldersFile, task#', id);
        const indexedDB = await this.exportIndexedDB();
        // this.folders[BACKUP_FOLDER] = indexedDB;
        const localStorage = window.appConfig.exportConfig(false, true);
        // if (localStorage) {
        //     delete localStorage[window.appConfig.encryptedMnemonicKey];
        // }
        this.folders[BACKUP_FOLDER] = {localStorage, indexedDB};
        const foldersJSONString = JSON.stringify(this.folders);

        await this.saveFolderLocally();

        if (ENCRYPT_FOLDERS_ENABLED) {
            const ciphertext = await this.encryptFolders(foldersJSONString);
            const files = [new File([ciphertext.iv, ciphertext.ciphertext], 'folders.json')];
            const cid = await this.web3StorageClient.put(files, {name: 'folders.json'});
            await this.publishIpnsRecord(cid);
        } else {
            const files = [new File([foldersJSONString], 'folders.json')];
            const cid = await this.web3StorageClient.put(files, {name: 'folders.json'});
            await this.publishIpnsRecord(cid);
        }
    }
    async syncFoldersFile() {
        const self = this;
        const id = (new Date()).getTime()
        await self.taskQueue.add(() => {
            console.log('-------- run sync folder task #', id);
            return self.doSyncFoldersFile(id)
        });

    }
    async testTaskQueue() {
        const self = this;
        const p1 = self.taskQueue.add(() => {
            console.log('-------- run sync folder task #1');
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve();
                    console.log('-------- complete sync folder task #1');

                }, 3000);
            });
        });
        const p2 = self.taskQueue.add(() => {
            console.log('-------- run sync folder task #2');
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve();
                    console.log('-------- complete sync folder task #2');

                }, 2000);
            });
        });
        const p3 = self.taskQueue.add(() => {
            console.log('-------- run sync folder task #3');
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve();
                    console.log('-------- complete sync folder task #3');
                }, 1000);
            });
        });
        const p4 = self.taskQueue.add(() => {
            console.log('-------- run sync folder task #4');
            return new Promise((resolve, reject) => {
                resolve();
                console.log('-------- complete sync folder task #4');
            });
        });
        const p5 = self.taskQueue.add(() => {
            console.log('-------- run sync folder task #4');
            return new Promise((resolve, reject) => {
                reject(new Error('biaji'));
                console.log('-------- complete sync folder task #4');
            });
        });

        const promise = Promise.all([
            p1, p2, p3, p4, p5
        ]);
        return promise;
    }

    async copyMessage(srcMessageCid, messageObj, messageFiles, progress = null) {
        const self = this;
        
        const cid = await self.web3StorageClient.put(messageFiles, {name: "Copied message.json"});
        console.log('srcMessageCid: ', srcMessageCid, ', DestMessageCid: ', cid, ', isEqual: ', (srcMessageCid === cid))
        if(messageObj.header.attachments) {
            for (let i=0; i<messageObj.header.attachments.length; i++) {
                const attachment = messageObj.header.attachments[i];
                // const attachmentResponse = await self.web3StorageClient.get(attachment.cid);
                // const srcAttachments = await attachmentResponse.files();
                
                const file = await self.get(attachment.cid, attachment.name, window.appConfig.storageProvider, progress);
                const srcAttachments = [file];

                const attachmentCid = await self.web3StorageClient.put(srcAttachments, {name: 'Copied ' + attachment.name || 'Copied Unnamed attachment'});
                console.log('srcMessageCid: ', attachment.cid, ', DestMessageCid: ', attachmentCid, ', isEqual: ', (attachment.cid === attachmentCid))
            }
        }
    }

    folderID() {
        const folder = this.currentFolder === CONTRACT_FOLDER ? INBOX_FOLDER : this.currentFolder;
        return folder;
    }
    messageIsSeen(uid) {
        const self = this;
        const folder = self.folderID();
        const message = self.folders[folder].messages[uid];
        if (!message) {
            return false;
        }
        // var message = self.folders[folder].messages.find((message) => { return message.cid === cid;});
        // if (!message) {
        //     return false;
        // }
        const flags = message.flags || MessageFlag.MessageFlagNone;
        const seen = (flags & MessageFlag.MessageFlagSeen) === MessageFlag.MessageFlagSeen;
        return seen;
    }
    
    setMessageIsSeen(uid) {
        const self = this;
        const folder = self.folderID();
        if (self.folders[folder].messages[uid]) {
            if (typeof self.folders[folder].messages[uid].flags === 'undefined') {
                self.folders[folder].messages[uid].flags = MessageFlag.MessageFlagSeen;
            } else {
                self.folders[folder].messages[uid].flags |= MessageFlag.MessageFlagSeen;
            }
        }
    }

    messageIsDecrypted(uid) {
        const self = this;
        const folder = self.folderID();
        
        const message = self.folders[folder].messages[uid];
        if (!message) {
            return false;
        }
        // var message = self.folders[folder].messages.find((message) => { return message.cid === cid;});
        // if (!message) {
        //     return false;
        // }
        const flags = message.flags || MessageFlag.MessageFlagNone;
        const decrypted = (flags & MessageFlag.MessageFlagSeen) === MessageFlag.MessageFlagDecrypted;
        return decrypted;
    }

    setMessageIsDecrypted(uid) {
        const self = this;
        const folder = self.folderID();
        
        if (self.folders[folder].messages[uid]) {
            if (typeof self.folders[folder].messages[uid].flags === 'undefined') {
                self.folders[folder].messages[uid].flags = (MessageFlag.MessageFlagSeen | MessageFlag.MessageFlagDecrypted);
            } else {
                self.folders[folder].messages[uid].flags |= (MessageFlag.MessageFlagSeen | MessageFlag.MessageFlagDecrypted);
            }

            delete self.folders[folder].messages[uid].address;
            delete self.folders[folder].messages[uid].deviceId;
            delete self.folders[folder].messages[uid].encryptedHeader;
            if (self.folders[folder].messages[uid].signal) {
                if (self.folders[folder].messages[uid].signal.length > 0) {
                    self.folders[folder].messages[uid].localSignalAddress= self.folders[folder].messages[uid].signal[0].accounts[0].account;
                } 
                if (self.folders[folder].messages[uid].signal.toAddress) {
                    self.folders[folder].messages[uid].localSignalAddress = self.folders[folder].messages[uid].signal.toAddress;
                }
            }
            delete self.folders[folder].messages[uid].signal;
            // delete self.folders[folder].messages[uid].type;

        }

        /*
        const message = self.folders[folder].messages[uid];
        let flags = MessageFlag.MessageFlagNone;

        if (message) {
            if (typeof message.flags === 'undefined') {
                flags = MessageFlag.MessageFlagSeen | MessageFlag.MessageFlagDecrypted;
            } else {
                flags = message.flags | MessageFlag.MessageFlagSeen | MessageFlag.MessageFlagDecrypted;
            }
        }

        self.folders[folder].messages[uid] = {
            decryptedHeader: message.decryptedHeader,
            flags: flags,
            from: message.from,
            sendDate: message.sendDate,
            uid: message.uid,
            mid: message.mid || 0,
        }
        */
    }

    messageIsDeleted(uid) {
        const self = this;
        const folder = self.folderID();
        if (!self.folders) {
            return true;
        }
        const message = self.folders[folder].messages[uid];
        if (!message) {
            return false;
        }
        // var message = self.folders[folder].messages.find((message) => { return message.cid === cid;});
        // if (!message) {
        //     return false;
        // }
        const flags = message.flags || MessageFlag.MessageFlagNone;
        const deleted = (flags & MessageFlag.MessageFlagDeleted) === MessageFlag.MessageFlagDeleted;
        return deleted;
    }

    setMessageIsDeleted(uid) {
        const self = this;
        const folder = self.folderID();
        
        if (self.folders[folder].messages[uid]) {
            if (typeof self.folders[folder].messages[uid].flags === 'undefined') {
                self.folders[folder].messages[uid].flags = MessageFlag.MessageFlagDeleted;
            } else {
                self.folders[folder].messages[uid].flags |= MessageFlag.MessageFlagDeleted;
            }
        }
    }

    async getMessage(cid, uid, mid, sp, fromMe = false, threadContext, progress = null) {
        const self = this;
        console.info('>> MailService - getMessage');

        const message = await self.get(cid, 'message.json', sp, progress);
        
        let messageContent = await message.arrayBuffer();
        const encryptedMessageContent = messageContent;
        messageContent = new Uint8Array(messageContent);
        messageContent = {
            iv: messageContent.slice(0, CryptoService.BYTES_12).buffer,
            ciphertext: messageContent.slice(CryptoService.BYTES_12).buffer
        };
        const encryptionKeyBundle = await self.signalService.getEncryptionKeyBundle(uid);
        let key = null;
        if (!encryptionKeyBundle || !encryptionKeyBundle.main || !encryptionKeyBundle.main.key) {
            if (fromMe || self.currentFolder === SENT_FOLDER) {
                key = await self.generateKeyV2(mid, 0);
                if (!key) {
                    throw ClientError.invalidParameterError(Strings.error.client.enckey_for_msg_not_found_1 + uid + Strings.error.client.enckey_for_msg_not_found_2);
                }
            } else {
                throw ClientError.invalidParameterError(Strings.error.client.enckey_for_msg_not_found_1 + uid + Strings.error.client.enckey_for_msg_not_found_2);
            }
            // await self.signalService.decryptEnvelope();
        }
        // const key = await self.cryptoService.importKey(encryptionKeyBundle.main.key);
        if (!key) {
            key = await self.cryptoService.importKey(encryptionKeyBundle.main.key);
        }


        const decryptedMessageContent = await self.cryptoService.decryptMailPart(key, messageContent, true);
        const splitPos = decryptedMessageContent.indexOf('\r\n\r\n');
        const header = decryptedMessageContent.substring(0, splitPos);
        const body = decryptedMessageContent.substring(splitPos + 4);

        // console.log('header: ', header);
        // console.log('body: ', body);

        const messageObj = {header: JSON.parse(header), body: body};
        if (!uid) {
            uid = messageObj.header.uid;
        }
        if (self.currentFolder === CONTRACT_FOLDER) {
            const segments = threadContext.location ? threadContext.location.split('/') : []
            let msg = null;
            let category = segments[1];
            let contract = self.folders[self.currentFolder].contracts[segments[0]];
            if (!contract) {
                self.currentMessage = messageObj;
                return [messageObj, encryptedMessageContent, decryptedMessageContent];
            }
            let signatureRound = null;
            if (category === 'discussions') {
                // contract-id/discussions/message-id
                msg = contract[category][segments[2]];
            } else if (category === 'signatureRounds') {
                // contract-id/signatureRounds/signature-round-id
                signatureRound = contract[category][segments[2]];
                msg = contract[category][segments[2]]['timeline'][threadContext.messageUID];
            }
            // const msg = self.folders[self.currentFolder].contracts[threadContext.contract.id].discussions[uid]
             

            // if (msg.ct && msg.ct.action === ContractConstants.Actions.CreateThread) {
            //     contract.title = messageObj.header.subject;
            //     contract.moderator = messageObj.header.from;
            //     contract.created = messageObj.header.sendDate;
            //     await this.syncFoldersFile();
            // }

            const flags = msg.flags || MessageFlag.MessageFlagNone;
            const decrypted = (flags & MessageFlag.MessageFlagDecrypted) === MessageFlag.MessageFlagDecrypted;
            if (!decrypted) {
                if (typeof msg.flags === 'undefined') {
                    msg.flags = (MessageFlag.MessageFlagSeen | MessageFlag.MessageFlagDecrypted);
                } else {
                    msg.flags |= (MessageFlag.MessageFlagSeen | MessageFlag.MessageFlagDecrypted);
                }
    
                if (msg.ct) {
                    if (msg.ct.mode === ContractConstants.ThreadMode.ContractThread) {

                        if (msg.ct.action === ContractConstants.Actions.CreateThread) {
                            contract.title = messageObj.header.subject;
                            contract.moderator = messageObj.header.from;
                            contract.created = messageObj.header.sendDate;
                        } else if (msg.ct.action === ContractConstants.Actions.CreateSignatureRound) {
                            signatureRound.title = messageObj.header.subject;
                            signatureRound.moderator = messageObj.header.from;
                            signatureRound.created = messageObj.header.sendDate;
                        }
                    } else if (msg.ct.mode === ContractConstants.ThreadMode.QuickSign) {
                        if (msg.ct.action === ContractConstants.Actions.CreateSignatureRound) {
                            contract.title = messageObj.header.subject;
                            contract.moderator = messageObj.header.from;
                            contract.created = messageObj.header.sendDate;

                            signatureRound.title = messageObj.header.subject;
                            signatureRound.moderator = messageObj.header.from;
                            signatureRound.created = messageObj.header.sendDate;
                        }
                    }
                }

                delete msg.address;
                delete msg.deviceId;
                delete msg.encryptedHeader;
                if (msg.signal && (msg.signal.length !== 0)) {
                    if (msg.signal.accounts) {
                        msg.localSignalAddress= msg.signal.accounts[0].account;
                    } else if (msg.signal.toAddress){
                        msg.localSignalAddress= msg.signal.toAddress;
                    } else {
                        throw new Error('invalid message: signal header is incomplete');
                    }
                }
                delete msg.signal;
                // delete msg.type;
    
                if (self.folders[INBOX_FOLDER].messages[uid]) {
                    delete self.folders[INBOX_FOLDER].messages[uid].address;
                    delete self.folders[INBOX_FOLDER].messages[uid].deviceId;
                    delete self.folders[INBOX_FOLDER].messages[uid].encryptedHeader;
                    if (self.folders[INBOX_FOLDER].messages[uid].signal) {
                        if (self.folders[INBOX_FOLDER].messages[uid].signal.length > 0) {
                            self.folders[INBOX_FOLDER].messages[uid].localSignalAddress = self.folders[INBOX_FOLDER].messages[uid].signal[0].accounts[0].account;
                        }


                        if (self.folders[INBOX_FOLDER].messages[uid].signal.toAddress) {
                            self.folders[INBOX_FOLDER].messages[uid].localSignalAddress = self.folders[INBOX_FOLDER].messages[uid].signal.toAddress;
                        }
                    }
                    delete self.folders[INBOX_FOLDER].messages[uid].signal;
                    
                    // delete self.folders[INBOX_FOLDER].messages[uid].type;
                }
                await this.saveFolderLocally();
                await this.syncFoldersFile();
            }



        } else {

            if (!self.messageIsDecrypted(uid)) {
                // await self.copyMessage(cid, messageObj, messages);
                self.setMessageIsDecrypted(uid);
                console.log('syncFoldersFile: add seen flag and decrypted flag');
                await this.saveFolderLocally();
                // await this.syncFoldersFile();
            }
        }

        self.currentMessage = messageObj;
        return [messageObj, encryptedMessageContent, decryptedMessageContent];
    }


    async moveMessageV1(message, fromFolder, toFolder) {
        const self = this;
        self.folders[toFolder].messages[message.uid] = message;
        delete self.folders[fromFolder].messages[message.uid];

        // const newMessages = self.folders[fromFolder].messages.filter((m)=> {
        //     return (m.cid !== message.cid);
        // });
        // self.folders[fromFolder].messages = newMessages;
        // self.folders[toFolder].message.unshift(message);

        await self.syncFoldersFile();
    }
    async moveMessage(message, toFolder) {
        const self = this;
        self.folders[toFolder].messages[message.uid] = message;
        delete self.folders[self.currentFolder].messages[message.uid];

        // const newMessages = self.folders[fromFolder].messages.filter((m)=> {
        //     return (m.cid !== message.cid);
        // });
        // self.folders[fromFolder].messages = newMessages;
        // self.folders[toFolder].message.unshift(message);

        console.log('syncFoldersFile: move message');
        await self.syncFoldersFile();
    }

    async deleteMessageFromIPFS(message, folder) {
        const self = this;

        await self.web3StorageClient.delete(message.cid);
        const attachments = message.attachments || []
        for (let i=0; i<attachments.length; i++) {
            await self.web3StorageClient.delete(attachments[i].cid);
        }
    }

    async deleteMessage(message) {
        const self = this;
        // delete self.folders[folder].messages[message.uid];
        self.setMessageIsDeleted(message.uid);
        
        if (self.folders[self.currentFolder].messages[message.uid]) {
            // self.folders[self.currentFolder].messages[message.uid].cid="";
        }

        console.log('syncFoldersFile: delete message');
        await self.syncFoldersFile();

        // await self.deleteMessageFromIPFS(message);

    }

    async deleteMessageV1(message, folder) {
        const self = this;

        delete self.folders[folder].messages[message.uid];
        await self.syncFoldersFile();

        // await self.deleteMessageFromIPFS(message);
        // const newMessages = self.folders[fromFolder].messages.filter((m)=> {
        //     return (m.cid !== message.cid);
        // });
        // self.folders[fromFolder].messages = newMessages;
        // await self.syncFoldersFile();
    }



    // signal helper functions
     
    async getLocalIdentityKey() {
        const identityKeyPair = await this.signalService.signalProtocolStore.getIdentityKeyPair();
        const identityKey = identityKeyPair.pubKey;
        return identityKey;
    }
    async getVerifiedInfo(address) {
        const isVerified = await this.signalService.getVerifiedInfo(address);
        return isVerified;
    }
    async getIdentityKeyBundle(address) {
        const signalIdentity = mailAddressToSignalIdentity(address);
        console.info('retrieve identity key of "' + signalIdentity + '" from smart contract');
        const bundle = await this.signalService.getIdentityKeyBundle(signalIdentity);
        return bundle;
    }
    async checkIdentityKey(address, identityKeyBundle=null) {
        const signalIdentity = mailAddressToSignalIdentity(address);
        await this.signalService.checkIdentityKey(signalIdentity, identityKeyBundle);
    }
    async getPreKeyBundle(address, identityKeyBundle=null) {
        const signalIdentity = mailAddressToSignalIdentity(address);
        const res = await this.signalService.getPreKeyBundle(signalIdentity, identityKeyBundle);
        return res;
    }
    async getExistedDevices(address, identityKeyBundle=null) {
        const signalIdentity = mailAddressToSignalIdentity(address);
        const devices = await this.signalService.getExistedDevices(signalIdentity, identityKeyBundle);
        return devices;
    }

    async sessionIsReady(remoteAddress, deviceId, localAddress=null) {
        const localAddressString = signalIdentityToMailAddress(localAddress);
        let myLocalAddress = localAddressString;
        if (localAddressString && localAddressString.indexOf('@') !== -1) {
            const localAddressObj = MailAddressUtils.parseOneAddress(localAddressString);
            myLocalAddress = localAddressObj.local;
        }
        const signalIdentity = mailAddressToSignalIdentity(remoteAddress);
        const isReady = await this.signalServiceFor(myLocalAddress).sessionIsReady(signalIdentity, deviceId);
        return isReady;
    }

    async removeSession(address, deviceId, localAddress=null) {
        // TODO: Multi-Account
        const signalIdentity = mailAddressToSignalIdentity(address);
        const remoteAddress = new SignalProtocolAddress(signalIdentity, deviceId);
        await this.signalServiceFor(localAddress).signalProtocolStore.removeSession(remoteAddress.toString());
    }

    async getTrustedIdentityKey(address) {
        console.info('load identity key of "' + address + '" from local indexedDB');

        const signalIdentity = mailAddressToSignalIdentity(address);
        const identityKey = await this.signalService.getTrustedIdentityKey(signalIdentity);
        if (!identityKey) {
            return null;
        }
        const identityKeyArray = (identityKey.constructor === ArrayBuffer) ? new Uint8Array(identityKey) : identityKey;
        const encodedIdentityKey = ethers.encodeBase64(identityKeyArray);
        return encodedIdentityKey;
    }

    extractIdentityKeyFromPreKeyMessage(buff) {
        
        const buffer = typeof buff === 'string' ? binaryStringToArrayBuffer(buff) : buff;
        const view = new Uint8Array(buffer);
        const version = view[0];
        const messageData = view.slice(1);
        if ((version & 0xf) > 3 || version >> 4 < 3) {
            // min version > 3 or max version < 3
            throw new Error('Incompatible version number on PreKeyWhisperMessage');
        }
        const preKeyMessage = PreKeyWhisperMessage.decode(messageData);

        return new Uint8Array(preKeyMessage.identityKey)
    }

    getPreKeyMessage(address, deviceId, sendDate) {
        // const messages = Object.values(this.folders[INBOX_FOLDER].messages).sort((a, b) => { 
        //     return (b.sendDate - a.sendDate);
        // });

        // let preKeyMessage = null;
        // for (const message of messages) {
        //     if (message.type === MailEncryptionType.signal 
        //         && message.signal.ciphertext.type === SignalMessageType.PreKeyWhisperMessage
        //         && message.sendDate < sendDate
        //         && message.signal.fromAddress === address 
        //         && message.signal.fromDeviceId === deviceId ) {
        //         preKeyMessage = message;
        //         break;
        //     }
        // }
        // return preKeyMessage;

        const signalIdentity = mailAddressToSignalIdentity(address);

        const self = this;
        let prekeyMessages = [];

        for (const folder in self.folders) {
            if (folder === SENT_FOLDER || folder === CONTRACT_FOLDER) {
                continue;
            }
            const prekeyMessagesInFolder = Object.values(this.folders[folder].messages).filter(message => {
                if (message.type !== MailEncryptionType.password 
                    && message.signal
                    && message.signal.ciphertext.type === SignalMessageType.PreKeyWhisperMessage
                    && message.sendDate < sendDate
                    && message.signal.fromAddress === signalIdentity 
                    && message.signal.fromDeviceId === deviceId ) {
                    return true;
                }
                return false;
            });
            prekeyMessages = prekeyMessages.concat(prekeyMessagesInFolder);
        }
        if (prekeyMessages.length === 0) {
            return null;
        }

        prekeyMessages.sort((a, b) => { 
            return (b.sendDate - a.sendDate);
        })

        return prekeyMessages[0];
    }
    async removeIdentityKeys(recipients) {
        for(let i=0; i<recipients.length; i++) {
            const rcp = recipients[i];
            // const address = rcp.local + '.0';
            // const idKey = base64.decode(rcp.identityKey).buffer;
            const signalIdentity = mailAddressToSignalIdentity(rcp.address);
            await this.signalService.signalProtocolStore.removeIdentity(signalIdentity);
        }
    }

    async startSessionsIfNeeded(identityKeyBundles, localAddress=null) {

        console.info('start session(s) if needed');
        for(const address in identityKeyBundles) {
            let identityKeyBundle = identityKeyBundles[address];
            if (!identityKeyBundle) {
                identityKeyBundle = await this.getIdentityKeyBundle(address);
            }
            const signalIdentity = mailAddressToSignalIdentity(address);
            await this.checkIdentityKey(signalIdentity, identityKeyBundle);
            const devices = await this.signalService.getExistedDevices(address, identityKeyBundle);
            for(const device of devices) {
                const isReady = await this.signalServiceFor(localAddress).sessionIsReady(signalIdentity, device.id, identityKeyBundle.identityKey);
                if (!isReady) {
                    console.info('start session with ' + signalIdentity + '.' + device.id);
                    // TODO: Multi-Account
                    await this.signalServiceFor(localAddress).startSession(signalIdentity, device.id, identityKeyBundle);
                }
            }
        }
    }
    async processEncryptedHeader(encryptionKey, message) {
        const self = this;
        const key = (encryptionKey.constructor === CryptoKey) ? encryptionKey : (await self.cryptoService.importKey(encryptionKey))
        // const key = await self.cryptoService.importKey(encryptionKey);
        const encodedEncryptedHeader = JSON.parse(message.encryptedHeader);
        const encryptedHeader = {
            iv: ethers.decodeBase64(encodedEncryptedHeader.iv).buffer,
            ciphertext: ethers.decodeBase64(encodedEncryptedHeader.ciphertext).buffer,
        }
        try {
            const decryptedHeaderJSONString = await self.cryptoService.decryptMailPart(key, encryptedHeader, true);
        // const decryptedHeaderJSONString = (new TextDecoder()).decode(decryptedHeaderBytes);
            const decryptedHeader = JSON.parse(decryptedHeaderJSONString);
            message.decryptedHeader = decryptedHeader;
        } catch (e) {
            console.error(message.uid, encryptionKey, e);
            throw new Error('Failed to decrypt encrypted headers');
        }
    }

    async getEncodedEncryptionKeyBundle(uid) {
        const self = this;
        const encodedKeyBundle = await self.signalService.getEncryptionKeyBundle(uid);
        return encodedKeyBundle;
    }

    async getDecodedEncryptionKeyBundle(uid) {

        const self = this;

        const encodedKeyBundle = await self.signalService.getEncryptionKeyBundle(uid);

        const mainKey = await self.cryptoService.importKey(encodedKeyBundle.main.key);
        const main ={...encodedKeyBundle.main, key: mainKey};
        const parties = {}
        for(let attID in encodedKeyBundle.parties) {
            const encodedAttKey = encodedKeyBundle.parties[attID];
            const attKey = await self.cryptoService.importKey(encodedAttKey.key);
            const decodedAttKey = {...encodedAttKey, key: attKey};
            parties[attID] = decodedAttKey;
        }
        const decodedKeyBundle = {
            main,
            parties
        };
        return decodedKeyBundle;
    }

    async decryptEncryptionKeyBundle(envelope, key) {

        key = (key.constructor === CryptoKey) ? key : ((await this.cryptoService.importKey(key.key || key)));

        const encodedEncryptedKeyBundle = JSON.parse(envelope.salt);
        const encryptedKeyBundle = {
            iv: ethers.decodeBase64(encodedEncryptedKeyBundle.iv).buffer,
            ciphertext: ethers.decodeBase64(encodedEncryptedKeyBundle.ciphertext).buffer
        };
        
        const keyBundleJSONString = await this.cryptoService.decryptMailPart(key, encryptedKeyBundle, true);
        const keyBundle = JSON.parse(keyBundleJSONString);
        return keyBundle;
    }

    async processPasswordMessage(envelope, password) {


        if (this.currentFolder === SENT_FOLDER) {
            const mainKey = await this.generateKeyV2(envelope.mid);
            await this.processEncryptedHeader(mainKey, envelope);
            return;
        }
        
        const encryptionKeyBundle = await this.signalService.getEncryptionKeyBundle(envelope.uid);
        if (encryptionKeyBundle) {
            if (!envelope.decryptedHeader) {
                await this.processEncryptedHeader(encryptionKeyBundle.main.key, envelope);
            }
            return;
        }

        let salt = JSON.parse(envelope.salt).salt;
        if (salt) {
            salt = ethers.decodeBase64(salt);
        }
        const key = await this.cryptoService.createEncryptionKey(0, 0, 0, password, salt);
        const keyBundle = await this.decryptEncryptionKeyBundle(envelope, key.key);
        keyBundle.pwd = password;
        
        await this.signalService.saveEncryptionKeyBundle(envelope.uid, keyBundle);

        if (!envelope.decryptedHeader) {
            await this.processEncryptedHeader(keyBundle.main.key, envelope);
        }
    }

    async processSignalMessage(message, fromMe = false) {

        const self = this;

        const encryptionKeyBundle = await self.signalService.getEncryptionKeyBundle(message.uid);
        if (encryptionKeyBundle) {
            if (!message.decryptedHeader) {
                await self.processEncryptedHeader(encryptionKeyBundle.main.key, message);
            }
            return;
        }

        if (fromMe || self.currentFolder === SENT_FOLDER) {
            const mainKey = await self.generateKeyV2(message.mid);
            await self.processEncryptedHeader(mainKey, message);
            return;
        }

        if (self.messageIsDecrypted(message.uid)) {
            throw ClientError.invalidParameterError('Message was decrypted, but the encryption key is miss.');
        }
        console.log('processSignalMessage: ', message);

        const address = message.signal.fromAddress; // || '0x6775a090b20f56578d30fd751e55ddf91f8be89c';
        const deviceId = message.signal.fromDeviceId;//  || 1;
        const signalIdentity = mailAddressToSignalIdentity(address);
        const remoteAddress = new SignalProtocolAddress(signalIdentity, deviceId);
        const ciphertext = message.signal.ciphertext;
        const myAccount = message.signal.toAddress;
        // TODO: Multi-Account
        const addr = signalIdentityToMailAddress(myAccount);
        const addrObj = MailAddressUtils.parseOneAddress(addr);
        const plaintextBytes = await self.signalServiceFor(addrObj.local).decryptEnvelope(remoteAddress, ciphertext);
        const exportedKeyJSONString = (new TextDecoder()).decode(plaintextBytes);
        const exportedKeyBundle = JSON.parse(exportedKeyJSONString);
        console.log('processSignalMessage keyBundle: ', exportedKeyBundle);
        await self.signalService.saveEncryptionKeyBundle(message.uid, exportedKeyBundle);

        if (!message.decryptedHeader) {
            await self.processEncryptedHeader(exportedKeyBundle.main.key, message);
        }
    }
    formatEnvelopes(envelopes) {
        for(const envelope of envelopes) {
            envelope.address = (envelope.address && envelope.address.length > 0) ? envelope.address[0] : null;
            envelope.deviceId = (envelope.deviceId && envelope.deviceId.length > 0) ? envelope.deviceId[0] : 0;
            envelope.salt = (envelope.salt && envelope.salt.length > 0) ? envelope.salt[0] : null;
            envelope.to = (envelope.to && envelope.to.length > 0) ? envelope.to[0] : [];
            envelope.ref = (envelope.ref && envelope.ref.length > 0) ? envelope.ref[0] : null;
            envelope.ct = (envelope.ct && envelope.ct.length > 0) ? envelope.ct[0] : null;
            
            envelope.sendDate = Number(envelope.sendDate || 0)
            envelope.expires = Number(envelope.expires || 0)
            if (envelope.type !== MailEncryptionType.password) {
                const signal = {
                    fromAddress: envelope.signal[0].fromAddress,
                    fromDeviceId: envelope.signal[0].fromDeviceId,
                    toAddress: envelope.signal[0].accounts[0].account,
                    toDeviceId: envelope.signal[0].accounts[0].devices[0].deviceId,
                    ciphertext: envelope.signal[0].accounts[0].devices[0].ciphertext,
                };
                envelope.signal = signal;
            }
        }
        /*
            address: ?Text;
            deviceId: ?SignalDeviceID;
            
            uid: Text;
            mid: Nat32;
            from: Text;
            type_: Text;
            salt: ?Text;
            // signal: ?SignalAccounts;
            signal: ?SignaHeader;
            to: ?[Text];
            sendDate: TimeInMs; // ms
            ref: ?Text;
            expires: TimeInMs; // default 0;
            encryptedHeader: Text;
            am: Nat8; // web3 access mode  Unset: 0 W3UI: 1 APIToken: 2
            ct: ?Text;
        */
        return envelopes;
    }

    async exportIndexedDB() {
        const store = window.appConfig.privacyLevelIsNormal ? new SecuritySignalProtocolStoreIndexedDB(this.selectedAccount, this.selectedAccount) : window.inMemoryDB;
        const indexedDBData = await store.exportAsJson(false);
        return indexedDBData;
    }
    async importIndexedDB(indexedDB) {
        
        const store = window.appConfig.privacyLevelIsNormal ? new SecuritySignalProtocolStoreIndexedDB(this.selectedAccount, this.selectedAccount) : window.inMemoryDB;
        await store.importFromJson(indexedDB);
    }
    async importLocalStorage(json) {
        Object.keys(json).forEach(function (key) {
            localStorage.setItem(key, json[key]);
        });
    }

    async exportMnemonic(selectedAccount, encoding='base64', encrypt=true) {
        if (selectedAccount) {
            selectedAccount = this.selectedAccount;
        }
        const key = window.appConfig.encryptedMnemonicKey;
        const mnemonicObject = {};
        mnemonicObject[key] = window.appConfig.encryptedMnemonic;
        if (!encrypt) {
            return mnemonicObject;
        }
        const localStorageData = JSON.stringify(mnemonicObject);
        const ciphertext = await this.encrypt(selectedAccount, localStorageData, encoding);
        return ciphertext;
    }

    async importMnemonic(selectedAccount, ciphertext, encrypt=true) {
        if (!selectedAccount) {
            selectedAccount = this.selectedAccount
        }
        let mnemonicObject = null;
        if (encrypt) {
            const plaintext = await this.decrypt(selectedAccount, ciphertext);
            mnemonicObject = JSON.parse(plaintext);
        } else {
            if (typeof ciphertext === 'string') {
                const encryptedMnemonic = await this.encrypt(selectedAccount, ciphertext);
                window.appConfig.encryptedMnemonic = encryptedMnemonic;
                return;
            }
            mnemonicObject = ciphertext;
        }

        Object.keys(mnemonicObject).forEach(function (key) {
            localStorage.setItem(key, mnemonicObject[key]);
        });

    }
    async exportConfig(selectedAccount, encoding='base64', encrypt=true) {
        if (selectedAccount) {
            selectedAccount = this.selectedAccount;
        }
        const localStorageData = window.appConfig.exportConfig();
        const plaintext = '{"indexedDB": null,"localStorage": ' + localStorageData + '}';
        if (encrypt) {
            const ciphertext = await this.encrypt(selectedAccount, plaintext, encoding);
            return ciphertext;
        }
        const configJSON = JSON.parse(plaintext);
        return configJSON;
    }

    async importConfig(selectedAccount, ciphertext, encrypt=true) {
        if (selectedAccount) {
            selectedAccount = this.selectedAccount;
        }
        let config = null;
        if (encrypt) {
            const plaintext = await this.decrypt(selectedAccount, ciphertext);
            config = JSON.parse(plaintext);
        } else {
            config = ciphertext;
        }

        Object.keys(config.localStorage).forEach(function (key) {
            localStorage.setItem(key, config.localStorage[key]);
        });
        const store = window.appConfig.privacyLevelIsNormal ? new SecuritySignalProtocolStoreIndexedDB(this.selectedAccount, this.selectedAccount) : window.inMemoryDB;
        await store.importFromJson(JSON.stringify(config.indexedDB));

        console.log('imported');
    }

    async doFlatEnterpriseContacts(node, contacts) {
        if (node.members && node.members.length > 0) {
            for(const member of node.members) {
                contacts.push({id: member.addr, value: {name: member.name, email: member.addr, uuid: member.addr}})
            }
        }

        if (node.children && node.children.length > 0) {
            for(const cn of node.children) {
                await this.doFlatEnterpriseContacts(cn, contacts);
            }
        }
    }

    async flatEnterpriseContacts(enterpriseContacts) {
        const contacts = [];
        for(const enterpriseContact of enterpriseContacts) {
            await this.doFlatEnterpriseContacts(enterpriseContact, contacts);
        }
        return contacts;
    }
    async loadContacts() {
        const self = this;
        const localContacts =  await this.signalService.getAllContacts();

        if ((self.selectedAccount &&(!localContacts || localContacts.length === 0))) {
            const contact = {email: self.selectedAccount + mailAddressSuffix(), name: CONTACT_NAME_ME};
            await this.saveContact(contact, false);
            localContacts.unshift({value: contact});
        }

        if (window.appConfig.enterpriseContactsIpnsName) {
            const enterpriseContacts = await this.getEnterpriseContactsWithName(window.appConfig.enterpriseContactsIpnsName);
            if (enterpriseContacts && enterpriseContacts.length > 0) {
                const ec = await this.flatEnterpriseContacts(enterpriseContacts);
                if (ec && ec.length > 0) {
                    if (localContacts && localContacts.length > 0) {
                        self.contacts = [...localContacts, ...ec];
                    } else {
                        self.contacts = ec;
                    }
                } else {
                    self.contacts = localContacts;
                }
            } else {
                self.contacts = localContacts;
            }
        } else {
            self.contacts = localContacts;
        }

    }
    contactNameSync(address, selectedAccount=null) {
        if (!address) {
            return '';
        }
        if (typeof address === 'object') {
            if (address.name && address.name.length > 0) {
                return address;
            }
            address = address.address;
        } 

        const addressObject = MailAddressUtils.parseOneAddress(address);
        if (!addressObject) {
            return '';
        }

        
        if (addressObject.local === selectedAccount) {
            return CONTACT_NAME_ME;
        }

        if (addressObject.name) {
            if (addressObject.name.trim().toLowerCase() !== CONTACT_NAME_ME_LOWERCASED ) {
                return addressObject.name;
            } else {
                addressObject.name = '';
            }
        }
        
        // if (selectedAccount) {
        //     contacts.unshift({email: selectedAccount + mailAddressSuffix(), name: CONTACT_NAME_ME, uuid: CONTACT_NAME_ME_LOWERCASED});
        // }

        const values = this.contacts.filter((contact) => {
            return contact.value.email === address
        });

        if (values && values.length > 0) {
            const contact = values[0].value;
            // return '"' + contact.name + '" <' + contact.email + '>';
            return contact.name;
        }
        return addressObject.address;
    }
    // contacts
    async contactIsExisted(address) {
        const contacts = await this.signalService.getAllContacts();
        const values = contacts.filter((contact) => {
            return contact.value.email === address
        });

        if (values && values.length > 0) {
            return true;
        }
        return false;
    }
    async getMyContactInfo(selectedAccount=null) {

        const contacts = await this.getAllContacts();

        if (!contacts || contacts.length === 0) {
            return null;
        }

        if (!selectedAccount) {
            selectedAccount = this.selectedAccount;
        }

        if (!selectedAccount) {
            return null;
        }

        selectedAccount = selectedAccount.toLowerCase() + '@';


        const filteredContacts = contacts.filter((contact) => {
            if (contact.email && contact.email.toLowerCase().startsWith(selectedAccount)) {
                if (contact.name.toLowerCase() !== CONTACT_NAME_ME_LOWERCASED) {
                    return true;
                }
            }
            return false;
        });

        if (!filteredContacts || filteredContacts.length <= 0) {
            return null;
        }

        return filteredContacts[0];
    }
    async contactName(address, selectedAccount=null) {
        const addressObject = MailAddressUtils.parseOneAddress(address);
        if (!addressObject) {
            return '';
        }

        
        if (addressObject.local === selectedAccount) {
            return CONTACT_NAME_ME;
        }

        if (addressObject.name) {
            if (addressObject.name.trim().toLowerCase() !== CONTACT_NAME_ME_LOWERCASED ) {
                return addressObject.name;
            } else {
                addressObject.name = '';
            }
        }


        const contacts = await this.signalService.getAllContacts();

        if (selectedAccount && (!contacts || contacts.length === 0)) {
            const contact = {email: selectedAccount + mailAddressSuffix(), name: CONTACT_NAME_ME};
            await this.saveContact(contact, false);
            contacts.unshift({value: contact});
        }
        
        const values = contacts.filter((contact) => {
            return contact.value.email === address
        });

        if (values && values.length > 0) {
            const contact = values[0].value;
            return '"' + contact.name + '" <' + contact.email + '>';
        }

        return addressObject.address;
    }
    async syncContactsIfNeeded() {

        if (this.shouldSyncContacts) {
            await this.syncFoldersFile();
            this.shouldSyncContacts = false;
        }
    }
    async saveContact(contact, syncImmediately=false) {
        const isNew = !contact.uuid;
        if (isNew) {
            contact.uuid = await this.cryptoService.generateUUID();
        }

        const namespace = (this.signalService && this.signalService.signalProtocolStore) ? ( this.signalService.signalProtocolStore.namespace || 'namespace') : 'namespace';
        if (isNew) {
            this.contacts.push({id: namespace + contact.uuid, value: contact});
        } else {
            for(let i=0; i<this.contacts.length; i++) {
                if (this.contacts[i].id === namespace + contact.uuid) {
                    this.contacts[i].value.name = contact.name;
                    this.contacts[i].value.email = contact.email;
                    break;
                }
            }
        }
        this.shouldSyncContacts = true;
        if (!syncImmediately) {
            return await this.signalService.saveContact(contact);
        }

        await this.signalService.saveContact(contact);
        await this.syncContactsIfNeeded();
    }

    async deleteContact(uuid) {
        // const namespace = (this.signalService && this.signalService.signalProtocolStore) ? ( this.signalService.signalProtocolStore.namespace || 'namespace') : 'namespace';
        this.contacts = this.contacts.filter(contact => {
            return contact.value.uuid !== uuid;
        })

        this.shouldSyncContacts = true;
        return await this.signalService.deleteContact(uuid);
    }

    async getAllContacts() {
        if (this.contacts && this.contacts.length > 0) {
            const values = this.contacts.map(contact => contact.value);
            return values;
        }
        
        const contacts = await this.signalService.getAllContacts();

        if ((this.selectedAccount &&(!contacts || contacts.length === 0))) {
            const contact = {email: this.selectedAccount + mailAddressSuffix(), name: CONTACT_NAME_ME};
            await this.saveContact(contact, false);
            contacts.unshift({value: contact});
        }

        const values = contacts.map(contact => contact.value)
        return values;
    }
    async getUser(addr) {
        addr = addr.toLowerCase();
        const user = await this.signalService.getUser(addr);
        return user;
    }

    async saveUserV1(user, syncImmediately=false) {
        const existedUser = await this.getUser(user.addr.toLowerCase());
        const isNew = !existedUser;

        const namespace = (this.signalService && this.signalService.signalProtocolStore) ? ( this.signalService.signalProtocolStore.namespace || 'namespace') : 'namespace';
        if (isNew) {
            this.users.push({id: namespace + user.addr.toLowerCase(), value: user});
        } else {
            for(let i=0; i<this.users.length; i++) {
                if (this.users[i].id === namespace + user.addr.toLowerCase()) {
                    this.users[i].value = user;
                    break;
                }
            }
        }
        this.shouldSyncContacts = true;
        if (!syncImmediately) {
            await this.signalService.saveUser(user);
            return;
        }

        await this.signalService.saveUser(user);
        await this.syncContactsIfNeeded();
    }

    async saveUser(user, syncImmediately=false) {
        // const existedUser = await this.getUser(user.addr.toLowerCase());
        // const isNew = !existedUser;

        // const namespace = (this.signalService && this.signalService.signalProtocolStore) ? ( this.signalService.signalProtocolStore.namespace || 'namespace') : 'namespace';
        // if (isNew) {
        //     this.users.push({id: namespace + user.addr.toLowerCase(), value: user});
        // } else {
        //     for(let i=0; i<this.users.length; i++) {
        //         if (this.users[i].id === namespace + user.addr.toLowerCase()) {
        //             this.users[i].value = user;
        //             break;
        //         }
        //     }
        // }

        // this.shouldSyncContacts = true;
        // if (!syncImmediately) {
        //     await this.signalService.saveUser(user);
        //     return;
        // }

        // await this.signalService.saveUser(user);

        const existedUser = await this.getUser(user.addr.toLowerCase());
        const isNew = !existedUser;

        const namespace = (this.signalService && this.signalService.signalProtocolStore) ? ( this.signalService.signalProtocolStore.namespace || 'namespace') : 'namespace';
        if (isNew) {
            this.users.push({id: namespace + user.addr.toLowerCase(), value: user});
        } else {
            for(let i=0; i<this.users.length; i++) {
                if (this.users[i].id === namespace + user.addr.toLowerCase()) {
                    this.users[i].value = user;
                    break;
                }
            }
        }
        this.shouldSyncContacts = true;
        if (!syncImmediately) {
            return await this.signalService.saveUser(user);
        }

        await this.signalService.saveUser(user);
        await this.syncContactsIfNeeded();
    }

    async deleteUser(addr) {
        addr = addr.toLowerCase();
        this.users = this.users.filter(contact => {
            return contact.value.addr !== addr;
        })

        this.shouldSyncContacts = true;
        return await this.signalService.deleteUser(addr);
    }

    async getAllUsers() {
        if (this.users && this.users.length > 0) {
            const values = this.users.map(contact => contact.value);
            return values;
        }
        
        const users = await this.signalService.getAllUsers();
        if (!users) {
            return [{
                addr: this.selectedAccount,
                name: 'Me',
                email: ''
            }];
        }
        const values = users.map(contact => contact.value)
        if (values) {
            values.unshift({
                addr: this.selectedAccount,
                name: 'Me',
                email: ''
            })
        }
        return values;
    }

    async getEnterpriseProfileV1() {
        if (this.enterpriseProfile) {
            return this.enterpriseProfile;
        }
        const profile = await this.signalService.getEnterpriseProfile();
        this.enterpriseProfile = profile;
        return profile;
    }
    async saveEnterpriseProfileV1(profile) {
        if (!profile) {
            return;
        }
        await this.signalService.saveEnterpriseProfile(mailAddressDomain(), profile);
        this.enterpriseProfile = profile;
    }

    async getEnterpriseProfile() {
        if (this.enterpriseProfile) {
            return this.enterpriseProfile;
        }
        
        const result = await window.plexiMailService.getBusinessEntity(window.appConfig.domainName);
        const profile = result ? result[0] : null;
        this.enterpriseProfile = profile;

        return profile;
    }

    async saveEnterpriseProfile(profile) {
        if (!profile) {
            return;
        }
        await window.plexiMailService.saveBusinessEntity(window.appConfig.domainName, profile);
        this.enterpriseProfile = profile;
    }

    async getNotifyTemplate() {
        if (this.notifyTemplate) {
            return this.notifyTemplate;
        }
        const template = await this.signalService.getNotifyTemplate();
        this.notifyTemplate = template;
        return template;
    }
    
    async saveNotifyTemplate(template) {
        if (!template) {
            return;
        }
        
        await this.signalService.saveNotifyTemplate(template);
        this.notifyTemplate = template;
    }

    async createEnterpriseContacts() {
        const key = await this.cryptoService.enterpriseContactIpnsSignKey(0);
        return key;
    }

    async publishEnterpriseContactsIpnsRecord(key, cid) {
        console.log('>> MailService - publishEnterpriseContactsIpnsRecord');
        return await this.__updateEnterpriseContacts(key, cid);
    }

    async resolveEnterpriseContactsIpnsRecord(name) {
        try {
            const cid = await this.__resolveEnterpriseContacts(name);
            return cid;
        } catch (e) {
            console.error(e);
            if (e.message && e.message.indexOf('record not found for key') !== -1) {
                return null;
            } else {
                throw e;
            }
        }
    }
    parseEmailAddress(address) {
        return MailAddressUtils.parseOneAddress(address);
    }
    async getEnterpriseContactsV1(progress=null) {
        // let name = window.appConfig.enterpriseContactsIpnsName;
        // if (!name || name.length === 0) {
        //     const key = await this.createEnterpriseContacts();
        //     const keyBuffer = keys.marshalPrivateKey(key);
        //     const nameObj = await Name.from(keyBuffer);
        //     name = nameObj.toString();
        // }
        
        // TODO:
        let name = window.appConfig.enterpriseContactsIpnsName;
        if (!name || name.length === 0) {
            const key = await this.createEnterpriseContacts();
            // const keyBuffer = keys.marshalPrivateKey(key);
            // const nameObj = await Name.from(keyBuffer);

            const digest = Digest.create(identity.code, key.public.bytes)
            const cid = CID.createV1(libp2pKeyCode, digest).toString(base36);
            name = cid.toString();
        }

        const cid = await this.resolveEnterpriseContactsIpnsRecord(name);
        if (!cid || cid.length === 0) {
            return null;
        }
        
        const contactsFile = await this.get(cid, 'contacts.json', window.appConfig.storageProvider, progress, 0, true);
        const content = await contactsFile.text() || '';
        const contacts = JSON.parse(content);
        return contacts;

    }

    async getContactsFromCanister(path) {
        let result = await window.plexiMailService.getContacts(window.appConfig.domainName, path);
        if (!result) {
            throw new Error('Not found');
        }
        if (result.code !== 0) {
            throw new ServerError(result.code, result.msg);
        }
        return result.data || [];
    }

    async doGetEnterpriseContacts(path, parent) {

        if (path.length === 0) {

            let root = await this.getContactsFromCanister(path);
            parent.root = root[0];
            const profile = await this.getEnterpriseProfile();
            parent.root.name = profile.name;
            await this.doGetEnterpriseContacts([root[0].id], parent.root);
        } else {

            let children = await this.getContactsFromCanister(path);
            if (!children || children.length === 0) {
                parent.children = [];
                return;
            }

            parent.children = [...children];
            for(const child of children) {
                try {
                    child.data = JSON.parse(child.data);
                } catch (e) {

                }
                await this.doGetEnterpriseContacts([...path, child.id], child);
            }
        }
    };

    async addContactNode(path, node) {
        let result = await window.plexiMailService.addContactNode(window.appConfig.domainName, path, node);
        return result;
    }

    async deleteContactNode(path) {
        let result = await window.plexiMailService.deleteContactNode(window.appConfig.domainName, path);
        return result;
    }

    async getEnterpriseContacts(progress=null) {
        const tree = {root: null};
        await this.doGetEnterpriseContacts([], tree);
        console.log('getEnterpriseContacts: ', tree);
        return tree;
    }
    
    async getEnterpriseContactsWithName(name, progress=null) {
        const cid = await this.resolveEnterpriseContactsIpnsRecord(name);
        const contactsFile = await this.get(cid, 'contacts.json', window.appConfig.storageProvider, progress, 0, true);
        const content = await contactsFile.text() || '';
        const contacts = JSON.parse(content);
        return contacts;
    }


    async putEnterpriseContacts(key, contacts) {
        const content = JSON.stringify(contacts);

        const files = [new File([content], 'contacts.json')];
        const cid = await this.web3StorageClient.put(files, {name: 'contacts.json'});
        const name = await this.publishEnterpriseContactsIpnsRecord(key, cid);
        window.appConfig.enterpriseContactsIpnsName = name;

    }

    // mapEncodedContact(address, selectedAddress) {
    //     const defaultAddressObject = MailAddressUtils.parseOneAddress(address);
    //     if (!defaultAddressObject) {
    //         throw ClientError.invalidParameterError('Sender address is invalid');
    //     }

    //     if (defaultAddressObject.name) {
    //         return MailAddressUtils.formatAddress(defaultAddressObject);
    //     }
    //     if ((defaultAddressObject.name == null || defaultAddressObject.name === '') && (defaultAddressObject.local === selectedAddress)) {
    //         const email = '"' + CONTACT_NAME_ME + '" <' + defaultAddressObject.address.toLowerCase() + '>';
    //         return email;
    //     }

    //     const filteredContacts = this.contacts.filter((contact) => {
    //         return contact.value.email === defaultAddressObject.address
    //     });

    //     if (filteredContacts && filteredContacts.length > 0) {
    //         const contact = filteredContacts[0].value;
    //         const email = '"' + contact.name + '" <' + contact.email.toLowerCase() + '>';
    //         return email;
    //     }

    //     return MailAddressUtils.formatAddress(defaultAddressObject);
    // }
    parseAddress(displayName, address) {
        const email = '"' + displayName + '" <' + address.toLowerCase() + '>';
        const addressObject = MailAddressUtils.parseOneAddress(email);
        return addressObject;
    }
    myPlexMailRFC822Address(selectedAddress, name=null) {
        if (name) {
            const email = '"' + name + '" <' + selectedAddress + mailAddressSuffix() + '>';
            const addressObject = MailAddressUtils.parseOneAddress(email);
            return addressObject
        }
        // const email = '"' + CONTACT_NAME_ME + '" <' + selectedAddress + mailAddressSuffix() + '>';
        // const addressObject = MailAddressUtils.parseOneAddress(email);
        // return addressObject;

        const addr = this.mapContact(selectedAddress + mailAddressSuffix(), null);
        return addr;
    }

    toDisplayAddress(address, selectedAddress = null) {
        if (!address) {
            return '*** Error: Unknown Moderator';
        }
        const contact = this.mapContact(address, selectedAddress || this.selectedAccount);
        const displayAddress = contact.name ? contact.name : contact.address;
        return displayAddress;
    }
    mapContact(address, selectedAddress) {
        
        const defaultAddressObject = MailAddressUtils.parseOneAddress(address);
        if (!defaultAddressObject) {
            throw ClientError.invalidParameterError('Sender address is invalid');
        }
        // console.log('mapContact: ', defaultAddressObject.name, defaultAddressObject.address, defaultAddressObject.local, defaultAddressObject.domain)
        if (defaultAddressObject.local === selectedAddress) {
            const email = '"' + CONTACT_NAME_ME + '" <' + defaultAddressObject.address.toLowerCase() + '>';
            const addressObject = MailAddressUtils.parseOneAddress(email);
            return addressObject;
        }

        if (defaultAddressObject.name && defaultAddressObject.name.toLowerCase() !== CONTACT_NAME_ME_LOWERCASED) {
            return defaultAddressObject;
        }
        
        const filteredContacts = this.contacts.filter((contact) => {
            return contact.value.email === defaultAddressObject.address
        });

        if (filteredContacts && filteredContacts.length > 0) {
            const contact = filteredContacts[0].value;
            const email = '"' + contact.name + '" <' + contact.email.toLowerCase() + '>';
            const addressObject = MailAddressUtils.parseOneAddress(email);
            return addressObject;
        }

        if (defaultAddressObject.name && defaultAddressObject.name.toLowerCase() === CONTACT_NAME_ME_LOWERCASED) {
            const addressObject = MailAddressUtils.parseOneAddress(defaultAddressObject.address);
            return addressObject;
        }

        return defaultAddressObject;
    }

    __flatFolders(folderEntry, path, ident, excludeFolder, result) {
        let prefix = '';
        for (let i=0; i<ident; i++) {
            prefix += '\u00a0\u00a0';//&nbsp;&nbsp;
        }
        if (excludeFolder && folderEntry[0] !== excludeFolder) {
            result.push({name: prefix + folderEntry[1].name, path: (path === '') ? folderEntry[0] : path + '/' + folderEntry[0], ident: ident, key: folderEntry[0]})
        }
        
        if (folderEntry[1].folders) {

            const folderEntries = Object.entries(folderEntry[1].folders);//

            for(const subFolderEntry of folderEntries) {
                if (path === '') {
                    path += (subFolderEntry[0])
                } else {
                    path += ('/' + subFolderEntry[0])
                }
                this.__flatFolders(subFolderEntry, path, ident + 1, excludeFolder, result);
            }
        } 
    }

    flatFolders(excludeFolder) {
        // excludeInbox=true
        const folders = {...this.folders};
        if (excludeFolder) {
            if (folders[excludeFolder]) {
                delete folders[excludeFolder];
            }
        }
        delete folders[SENT_FOLDER];
        delete folders[TRASH_FOLDER];
        
        const folderEntries = Object.entries(folders);

        const result = [];
        for(const folderEntry of folderEntries) {
            if (folderEntry[0] === BACKUP_FOLDER) {continue;}
            this.__flatFolders(folderEntry, '', 0, excludeFolder, result);
        }
        return result;
    }

    async encrypt(selectedAccount, data, encoding='base64') {
        return data;
        /*
        const keyB64 = await window.ethereum.request({
            method: 'eth_getEncryptionPublicKey',
            params: [selectedAccount],
        });

        const enc = encrypt({
            publicKey: keyB64.toString('base64'),
            data: data,
            version: 'x25519-xsalsa20-poly1305',
        });
        const buf = Buffer.concat([
            Buffer.from(enc.ephemPublicKey, 'base64'),
            Buffer.from(enc.nonce, 'base64'),
            Buffer.from(enc.ciphertext, 'base64'),
        ]);

        if (encoding === 'base64') {
            const b64Buf = buf.toString('base64');
            return b64Buf;
        }
        if (encoding === 'hex') {
            const hexBuf = buf.toString('hex');
            return hexBuf;
        }
        throw ClientError.invalidParameterError('encoding type is unsupported: ' + encoding);
        */
    }

    async decrypt(selectedAccount, b64data) {
        return b64data;

        /*
        const data = Buffer.from(b64data, 'base64');
        const structuredData = {
            version: 'x25519-xsalsa20-poly1305',
            ephemPublicKey: data.slice(0, 32).toString('base64'),
            nonce: data.slice(32, 56).toString('base64'),
            ciphertext: data.slice(56).toString('base64'),
        };
        // Convert data to hex string required by MetaMask
        const ciphertext = `0x${Buffer.from(JSON.stringify(structuredData), 'utf8').toString('hex')}`;
        // Send request to MetaMask to decrypt the ciphertext
        // Once again application must have acces to the account
        const decrypted = await window.ethereum.request({
            method: 'eth_decrypt',
            params: [ciphertext, selectedAccount]
        });
        // const textDecoder = new TextDecoder();
        // const str = textDecoder.decode(decrypted.buffer);
        return decrypted;
        */
    }

    async encryptMnemonic(password, mnemonic) {
        const encryptedMnemonic = await this.cryptoService.encryptMnemonic(password, mnemonic);
        return encryptedMnemonic;
    }

    async decryptMnemonic(password, encryptedMnemonic) {
        const mnemonic = await this.cryptoService.decryptMnemonic(password, encryptedMnemonic);
        return mnemonic;
    }

    async verifyPassword(password) {
        if (!password || password.length === 0) {
            throw ClientError.invalidParameterError('Password could not be empty');
        }
        if (window.wallet && window.wallet.asDefaultWallet) {
            await window.wallet.unlock(password);
        } else {
            const mnemonic = await this.cryptoService.decryptMnemonic(password, window.appConfig.encryptedMnemonic);
            if (mnemonic) {}
        }
    }

    async checkCryptonToken(salt, password) {
        return false;
    }

    async getCryptonToken(salt, password, tokenString=null) {
        return null;
    }

    async saveCryptonToken(salt, password, mnemonic, apiToken, force=false, local=false) {
    }


    async useCouponCode(code) {

        let resp = await fetch(PUSH_SERVER_ADDRESS + '/api/coupon-code/redeem/' + this.selectedAccount + '/' + code, {mode: "cors"}).then(resp => {
            if (resp.status === 200) {
                return resp.json();
            }
            return {code: (resp.status > 0 ? -resp.status : resp.status), msg: resp.statusText};
        })
        console.log(resp);
        if (resp.code !== 0) {
            throw ServerError.from(resp);
        }
        const result = resp.data;
        return result;
    }


    async waitFaucetTransactionReceiptMined(txn, interval=5000) {
        const signalService = new SignalService(null, null, null, null, null);
        const result = await signalService.waitTransactionReceiptMined(txn, interval);
        return result;
    }
    async waitVerifyTransactionReceiptMined(txn, interval=5000) {
        const signalService = new SignalService(null, null, null, null, null);
        const result = await signalService.waitTransactionReceiptMined(txn, interval);
        return result;
    }
    async storeIdentityKeyIntoSmartContract() {
        const result = await this.signalService.storeIdentityKeyIntoSmartContract();
        return result;
    }

    chainIdToNetwork(chainId) {
        if (chainId === EthereumChains.Goerli) {
          return 'goerli';
        } else if (chainId === EthereumChains.Sepolia) {
          return 'sepolia';
        } else if (chainId === EthereumChains.Mainnet) {
          return 'mainnet';
        } else if (chainId === EthereumChains.Fuji) {
          return 'fuji';
        } else if (chainId === EthereumChains.Avalanche) {
          return 'mainnet';
        }
        return 'mainnet';
    }

    async requestTestCoins(email, address) {
        const network = this.chainIdToNetwork(window.chainId);
        const form = {email, address, network};
        let resp = await fetch(PUSH_SERVER_ADDRESS + '/api/faucet/request', {
            method: "POST", 
            mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form) 
        }).then(resp => {
            if (resp.status === 200) {
                return resp.json();
            }
            return {code: (resp.status > 0 ? -resp.status : resp.status), msg: resp.statusText};
        });
        if (resp.code !== 0) {
            throw ServerError.from(resp);
        }
        return resp.data;
    }

    async getAddressTokenPassword() {
        const password = await this.signalService.signalProtocolStore.getAddressTokenPassword();
        return password;
    }
    
    async hmacVerify(pwd, signature, data=null) {
        const signature1 = await this.hmac(pwd, data)
        return signature1 === signature;
    }

    async hmac(pwd, data=null) {
        const enc = new TextEncoder("utf-8");
        const rawPwd = enc.encode(pwd);
        if (!data) {
            data = pwd;
        }
        const key = await window.crypto.subtle.importKey(
            "raw", // raw format of the key - should be Uint8Array
            rawPwd,
            { // algorithm details
                name: "HMAC",
                hash: {name: "SHA-512"}
            },
            false, // export = false
            ["sign", "verify"] // what this key can do
        );
        const signature = await window.crypto.subtle.sign(
            "HMAC",
            key,
            enc.encode(data)
        );

        const signatureBuffer = new Uint8Array(signature);
        const signatureHex = Array.prototype.map.call(signatureBuffer, x => x.toString(16).padStart(2, '0')).join("");

        return signatureHex;
    }
    async generatePassword(length, simple=true) {
        // const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const chars = simple ? '123456789abcdefghijkmnpqrstuvwxyz' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*_-=+';
        // const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*_-=+";
        let password = "";
        for (let i = 0; i < length; i++) {
            password += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return password;
    };
    generateDigitalPasscodeSync(length=6) {
        // const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const chars = '0123456789'
        let password = "";
        for (let i = 0; i < length; i++) {
            password += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return password;
    };

    async refreshAddressTokenPassword() {
        const password = await this.generatePassword(16, false);
        await this.signalService.signalProtocolStore.saveAddressTokenPassword(password);
        return password;
    }
    async base64Encode(arr) {
        const b64str = ethers.encodeBase64(arr);
        return b64str
    }
    async base64Decode(str) {
        const buf = ethers.decodeBase64(str);
        return buf;
    }
    base64EncodeSync(arr) {
        if (typeof arr === 'string') {
            arr = new TextEncoder().encode(arr);
        }
        const b64str = ethers.encodeBase64(arr);
        return b64str
    }
    base64DecodeSync(str, asString=true) {
        const buf = ethers.decodeBase64(str);
        if (asString) {
            const str = new TextDecoder().decode(buf);
            return str;
        }
        return buf;
    }
    async sha256(message) {
        const F = crypt.createHash('sha256').update(message);
        const hash = F.digest().toString('hex');
        return hash;
    }


    async archiveAttachment(cid, att, sp, keyBundle) {

        const self = this;
        let encryptionKey = keyBundle.parties[att.id] ? keyBundle.parties[att.id].key : null;
        if (!encryptionKey) {
            throw new Error('Attachment decryption key not found');
        }
        encryptionKey = await self.cryptoService.importKey(encryptionKey);

        const file = await this.get(att.cid, att.name, sp);

        let encryptedAttachment = await file.arrayBuffer();
        encryptedAttachment = new Uint8Array(encryptedAttachment);

        const encryptedAttachmentJSON = {
            iv: encryptedAttachment.slice(0, CryptoService.BYTES_12).buffer,
            ciphertext: encryptedAttachment.slice(CryptoService.BYTES_12).buffer
        };
        const res = await this.cryptoService.decryptMailPart(encryptionKey, encryptedAttachmentJSON);
        return {encrypted: encryptedAttachment, decrypted: res};
    }
    async downloadTest() {

        const zipWriter = new zip.ZipWriter(new zip.BlobWriter("application/zip"), { bufferedWrite: true, useCompressionStream: false });
        
        const blob = new Blob(["hello world"], {type: 'text/plain'});
        const blob2 = new Blob(["hello world2"], {type: 'text/plain'});
        const blobReader = new zip.BlobReader(blob)
        const blobReader2 = new zip.BlobReader(blob2)
        await zipWriter.add('folder1/file1.txt', blobReader, {});
        await zipWriter.add('folder2/file2.txt', blobReader2, {});
        const zipBlob = await zipWriter.close();
        await this.saveAttachment({name: "helloworld.zip"}, zipBlob);
   
    }

    toRegFileName(str) {
        if (!str || str.length === 0) {
            return 'Archive.zip';
        }
        str = str.replace(/[^a-z0-9]/gi, '_');

        const name = 'Archived-' + str + '.zip';
        return name;
    }
    async unarchiveContractThread(contractThread) {
        if (!contractThread) {
            return;
        }

        const thread = this.folders[CONTRACT_FOLDER]['archived'][contractThread.id];
        if (thread) {
            if (!this.folders[CONTRACT_FOLDER]['contracts']) {
                this.folders[CONTRACT_FOLDER]['contracts'] = {}
            }
            this.folders[CONTRACT_FOLDER]['contracts'][thread.id] = thread;
        }
        delete this.folders[CONTRACT_FOLDER]['archived'][contractThread.id];

        await this.syncFoldersFile()
    }
    async archiveContractThread(contractThread) {
        if (!contractThread) {
            return;
        }

        const thread = this.folders[CONTRACT_FOLDER]['contracts'][contractThread.id];
        if (thread) {
            if (!this.folders[CONTRACT_FOLDER]['archived']) {
                this.folders[CONTRACT_FOLDER]['archived'] = {}
            }
            this.folders[CONTRACT_FOLDER]['archived'][thread.id] = thread;
        }
        delete this.folders[CONTRACT_FOLDER]['contracts'][contractThread.id];

        await this.syncFoldersFile()
    }

    async archiveContract(signatures, signatureRound, locally=true) {

        const keyBundle = await this.signalService.getEncryptionKeyBundle(signatureRound.contentOfMessage.header.uid);
        
        const zipWriter = new zip.ZipWriter(new zip.BlobWriter("application/zip"), { bufferedWrite: true, useCompressionStream: false });
        
        const keyBundleBlob = new Blob([JSON.stringify(keyBundle)], {type: 'application/json'});
        const keyBundleReader = new zip.BlobReader(keyBundleBlob)
        await zipWriter.add('key.bundle', keyBundleReader, {});
        
        // signatureRound = {envelope: signatureRoundEnvelope, message: signatureRoundMessage, encryptedMessage: encryptedSignatureRoundMessage, decryptedMessage: signatureRoundMessage.decryptedMessageString}
        const encryptedMessage = signatureRound.encryptedMessage;
        const encryptedMessageBlob = new Blob([encryptedMessage], {type: 'application/octet-stream'});
        const encryptedMessageReader = new zip.BlobReader(encryptedMessageBlob);
        await zipWriter.add('encrypted/message', encryptedMessageReader, {});

        const decryptedMessage = signatureRound.decryptedMessage;
        const decryptedMessageBlob = new Blob([decryptedMessage], {type: 'text/plain'});
        const decryptedMessageReader = new zip.BlobReader(decryptedMessageBlob);
        await zipWriter.add('decrypted/message', decryptedMessageReader, {});

        const signaturesBlob = new Blob([signatures], {type: 'application/json'});
        const signaturesReader = new zip.BlobReader(signaturesBlob);
        await zipWriter.add('decrypted/signatures', signaturesReader, {});
        
        const attachments = signatureRound.contentOfMessage.header.attachments;
        
        for(const att of attachments) {
            const {encrypted, decrypted} = await this.archiveAttachment(att.cid, att, signatureRound.contentOfMessage.header.sp, keyBundle);
        
            let attBlob = new Blob([encrypted], {type: 'application/octet-stream'});
            let attReader = new zip.BlobReader(attBlob);
            
            await zipWriter.add(`encrypted/attachments/${att.id}-${att.name}.enc`, attReader, {});

            attBlob = new Blob([decrypted], {type: 'application/octet-stream'});
            attReader = new zip.BlobReader(attBlob);
            await zipWriter.add(`decrypted/attachments/${att.id}-${att.name}`, attReader, {});
        }
        const zipBlob = await zipWriter.close();
        if (locally) {
            await this.saveArchivedContract({name: this.toRegFileName(signatureRound.contentOfMessage.header.subject)}, zipBlob);
        } else {
            return zipBlob;
        }
    }

    async saveArchivedContract(att, blob) {
        let url = URL.createObjectURL(blob);
        let a = window.document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", att.name);
        window.document.body.append(a);
        a.click();
        window.window.URL.revokeObjectURL(url);
        a.remove();
    }

    async downloadArchivedContract(meta, progress) {
        const zipFile = await this.get(meta.cid, 'a.zip', StorageProvider.LightHouse, progress);
        const blob = new Blob([new Uint8Array(await zipFile.arrayBuffer())], {type: zipFile.type });
        await this.saveArchivedContract({name: this.toRegFileName(meta.name)}, blob);
    }
    /**
     * {
     *  activedFolder:
     *  editorIsOpened:
     *  threadContext:
     * } = plexiMailState
     */
    
    async savePlexiMailUIState(state) {
        this.uiState = state;
        if (this.signalService && this.signalService.signalProtocolStore) {
            await this.signalService.signalProtocolStore.savePlexiMailUIState(state)
        }
    }

    async getPlexiMailUIState() {
        if (this.uiState) {
            return this.uiState;
        }

        if (this.signalService && this.signalService.signalProtocolStore) {
            return await this.signalService.signalProtocolStore.getPlexiMailUIState()
        }
        return null;
    }

    async removePlexiMailUIState() {
        this.uiState = null;
        if (this.signalService && this.signalService.signalProtocolStore) {
            await this.signalService.signalProtocolStore.removePlexiMailUIState()
        }
    }

    async saveContractTemplate(template) {
        if (this.signalService && this.signalService.signalProtocolStore) {
            await this.signalService.signalProtocolStore.saveContractTemplate(template)
        }   
    }

    async getContractTemplates() {
        if (this.signalService && this.signalService.signalProtocolStore) {
            return await this.signalService.signalProtocolStore.getContractTemplates()
        }
        return null;
    }

    async getDefaultContract() {
        const result = await this.getContractTemplates();
        if (!result) {
            return null;
        }

        if (!result.templates) {
            return null;
        }

        if (result.default && result.default.length > 0) {
            const template = result.templates[result.default];
            if (template && template.content) {
                return template.content;
            } 
        }

        const templates = Object.values(result.templates);
        if (!templates || templates.length === 0) {
            return null;
        }
        return templates[0].content;
    }

    async removeContractTemplate(id) {
        if (this.signalService && this.signalService.signalProtocolStore) {
            await this.signalService.signalProtocolStore.removeContractTemplate(id)
        }
    }

    _stringIsMatch(str, substr) {
        if (!substr || substr.length === 0) {
            return false;
        }
        if (!str || str.length === 0) {
            return false;
        }
        substr = substr.toLowerCase();
        str = str.toLowerCase();
        
        const idx = str.indexOf(substr);
        const isMatch = (idx !== -1);
        return isMatch;
    }
    _dateIsMatch(date, after, before) {
        if (!date) {
            return false;
        }
        if (!after && !before) {
            return false;
        }

        let isAfter = after ? (date >= after) : true;
        let isBefore = before ? (date <= before) : true;
        let dateIsMatch = (isAfter && isBefore);
        return dateIsMatch;
    }
    async searchMessagesInFolder(folderID, conditions) {
        const self = this;
        const messages = Object.values(this.folders[folderID].messages).sort((a, b) => { 
        // return ((new Date(b.sendDate)).getTime() - (new Date(a.sendDate)).getTime());
            return (b.sendDate - a.sendDate);
        }).filter((message) => {
            const isDeleted = self.messageIsDeleted(message.uid);
            if (isDeleted) {
                return false;
            }
            const fromIsMatch = self._stringIsMatch(message.from, conditions.from);
            if (fromIsMatch) {
                return true;
            }

            const subjectIsMatch = message.decryptedHeader ? self._stringIsMatch(message.decryptedHeader.subject, conditions.subject) : false;
            if (subjectIsMatch) {
                return true;
            }
            let sendDateIsMatch = self._dateIsMatch(message.sendDate, conditions.after, conditions.before);
            return sendDateIsMatch;
        }).map(message => {
            return {type: 'message', message};
        });
        return messages;
    }   

    /**
     * 
     * @param {*} conditions {from, to, subject, before, after, contract}
     */
    async searchMessagesInContactsFolder(conditions) {
        const contractFolder = this.folders[CONTRACT_FOLDER];
        if (!contractFolder || !contractFolder.contracts || Object.keys(contractFolder.contracts).length === 0) {
            return [];
        }

        const contracts = Object.values(contractFolder.contracts).sort((a, b) => {
          // return ((new Date(b.sendDate)).getTime() - (new Date(a.sendDate)).getTime());
          return b.created - a.created;
        });

        const messages = [];
        for(const contract of contracts) {
            const searchResult = await this.searchMessagesInContract(contract, conditions);
            if (searchResult && searchResult.length > 0) {
                for(const item of searchResult) {
                    messages.push(item);
                }
            }
        }
        return messages;
    }
    
    _contractIsMatch(contract, conditions) {

        const self = this;
        const moderatorIsMatch = self._stringIsMatch(contract.moderator, conditions.from);
        if (moderatorIsMatch) {
            return true;
        }

        const titleIsMatch = self._stringIsMatch(contract.title, conditions.subject);
        if (titleIsMatch) {
            return true;
        }
        let createDateIsMatch = self._dateIsMatch(contract.created, conditions.after, conditions.before);
        return createDateIsMatch;
    }

    async searchMessagesInContract(contract, conditions) {

        const self = this;
        const contractIsMatch = self._contractIsMatch(contract, conditions);
        const result = [];
        if (contractIsMatch) {
            result.push({type: 'contract', contract});
        }

        const signatureRounds = await self.searchMessagesInSignatureRounds(contract.signatureRounds, conditions);
        for(const signatureRound of signatureRounds) {
            result.push({type: 'signatureRound', contract, signatureRound})
        }

        const discussions = await self.searchMessagesInDiscussions(contract.discussions, conditions);
        for(const discussion of discussions) {
            result.push({type: 'discussion', contract, discussion})
        }
        return result;
    }

    async searchMessagesInDiscussions(discussions, conditions) {
        const self = this;
        discussions = Object.values(discussions);
        const result = []
        for(const discussion of discussions) {

            const fromIsMatch = self._stringIsMatch(discussion.from, conditions.from);
            if (fromIsMatch) {
                result.push(discussion);
                continue;
            }

            const subjectIsMatch = discussion.decryptedHeader ? self._stringIsMatch(discussion.decryptedHeader.subject, conditions.subject) : false;
            if (subjectIsMatch) {
                result.push(discussion);
                continue;
            }

            let sendDateIsMatch = self._dateIsMatch(discussion.sendDate, conditions.after, conditions.before);
            if (sendDateIsMatch) {
                result.push(discussion);
            }
        }
        return result;
    }
    _signatureRoundIsMatch(signatureRound, conditions) {

        const self = this;
        const moderatorIsMatch = self._stringIsMatch(signatureRound.moderator, conditions.from);
        if (moderatorIsMatch) {
            return true;
        }

        const titleIsMatch = self._stringIsMatch(signatureRound.title, conditions.subject);
        if (titleIsMatch) {
            return true;
        }
        let createDateIsMatch = self._dateIsMatch(signatureRound.created, conditions.after, conditions.before);
        return createDateIsMatch;
    }

    _timelineIsMatch(signatureRound, conditions) {
        const timeline = Object.values(signatureRound.timeline);
        const self = this;
        const createSignatureRoundMessages = timeline.filter(o => {
            return (o && o.ct && o.ct.action === ContractConstants.Actions.CreateSignatureRound)
        });
        if (!createSignatureRoundMessages || createSignatureRoundMessages.length === 0) {
            return false;
        }
        const message = createSignatureRoundMessages[0];
        
        const fromIsMatch = self._stringIsMatch(message.from, conditions.from);
        if (fromIsMatch) {
            return true;
        }

        const subjectIsMatch = message.decryptedHeader ? self._stringIsMatch(message.decryptedHeader.subject, conditions.subject) : false;
        if (subjectIsMatch) {
            return true;
        }
        const sendDateIsMatch = self._dateIsMatch(message.sendDate, conditions.after, conditions.before);
        return sendDateIsMatch
    }

    async searchMessagesInSignatureRounds(signatureRounds, conditions) {
        // ['id', 'timeline', 'moderator', 'created', 'title']
        const self = this;
        signatureRounds = Object.values(signatureRounds);
        const result = []
        for(const signatureRound of signatureRounds) {

            if (self._signatureRoundIsMatch(signatureRound, conditions) 
            || self._timelineIsMatch(signatureRound, conditions)) {
                result.push(signatureRound)
            }
        }
        return result;
    }

    async searchMessages(conditions) {
        const folder = this.currentFolder;
        if (folder === CONTRACT_FOLDER) {
            return await this.searchMessagesInContactsFolder(conditions);
        } else {
            return await this.searchMessagesInFolder(folder, conditions);
        }
    }
    #addressesDB = null
    async generateAddress() {

    }

    async getAddressIndex(address) {
        const addressDB = await this.getAddresses();
        const opts = addressDB.addresses[address];
        if (!opts) {
            throw new Error('wallet index not found');
        }
        return opts.path;
    }
    async getOrderedAddresses(withLabel=false) {
        if (!this.#addressesDB) {
            const result = await this.signalService.signalProtocolStore.getAddresses(false);
            this.#addressesDB = result;
        }

        if (withLabel) {
            const mainOpts = this.#addressesDB.addresses[ window.appConfig.recentActiveAccount];
            const mainOptObj = (typeof mainOpts !== 'object') ? {label: typeof mainOpts === 'string' ? mainOpts : null, path: typeof mainOpts === 'number' ? mainOpts : 0, } : mainOpts;
            const addresses = [{address: window.appConfig.recentActiveAccount, ...mainOptObj}];

            for(const address in this.#addressesDB.addresses) {
                const opts = this.#addressesDB.addresses[address];
                const optObj = (typeof opts !== 'object') ? {label: typeof opts === 'string' ? opts : null, path: typeof opts === 'number' ? opts : 0, } : opts;
                if (address !== window.appConfig.recentActiveAccount) {
                    addresses.push({address, ...optObj});
                }
            }
            return addresses;
        }
        
        return [window.appConfig.recentActiveAccount, ...Object.keys(this.#addressesDB.addresses).filter(k => { return k !== window.appConfig.recentActiveAccount; })]
    }

    async getAddresses(asArray) {
        if (!this.#addressesDB) {
            const result = await this.signalService.signalProtocolStore.getAddresses(false);
            this.#addressesDB = result;
        }

        if (asArray) {
            const addresses = [];
            for(const key in this.#addressesDB.addresses) {
                const opts = this.#addressesDB.addresses[key];
                const optObj = (typeof opts !== 'object') ? {label: typeof opts === 'string' ? opts : null, path: typeof opts === 'number' ? opts : 0, } : opts;
                addresses.push({address: key, ...optObj});
            }

            return {lastIndex: this.#addressesDB.lastIndex, addresses};
        }
        return this.#addressesDB;
    }

    async saveAddress(path, address, label) {
        const opt = {
            label,
            path,
        };
        await window.plexiMailService.addAddress(address, opt);
        await this.signalService.signalProtocolStore.saveAddress(address, opt);
        if (!this.#addressesDB || !this.#addressesDB.addresses) {
            await this.getAddresses();
        }
        this.#addressesDB.addresses[address] = opt;
    }

    async removeAddress(address) {
        if (!this.#addressesDB || !this.#addressesDB.addresses) {
            await this.getAddresses();
        }

        await window.plexiMailService.removeAddress(address);
        await this.signalService.signalProtocolStore.removeAddress(address);
        delete this.#addressesDB.addresses[address];
    }

    async getStorageUsage() {
        try {
            return await this.web3StorageClient.getStorageUsage();
        } catch (e) {
            console.error(e);
            return {dataLimit: -1, dataUsed: -1}
        }
    }
}
// const mailService = new MailService();
// const mailService = new MailService()
// export default MailService;
export default MailService;