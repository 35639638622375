import {
  Button,
  // Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import Strings from "../config/Strings";

export default function InstallAppAlert({ disclosure }) {
  const installApp = () => {
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          window.appConfig.showInstallAlert = false;
          console.log("User accepted the PlexiMail installation prompt");
        } else {
          console.log("User dismissed the PlexiMail installation prompt");
        }
        window.deferredPrompt = null;
      });
    }
    disclosure.onClose();
  };
  const S = Strings.main.dialog.pwa;
  return (
    <Modal
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {S.title}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Text>{S.content.paragraph1}</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            onClick={() => {
              window.appConfig.showInstallAlert = false;
              disclosure.onClose();
            }}
            mr={4}
          >
            {S.button.cancel}
          </Button>

          <Button colorScheme="green" onClick={installApp}>
            {S.button.install}
          </Button>
          {/* <Tooltip label="Sent Mail"></Tooltip> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
