import * as rfc5322 from "email-addresses";
import Web3 from "web3";
// import * as rfc2822 from 'address-rfc2822';

const parseOneAddress = (address) => {
  if (!address) {
    return null;
  }

  const addr = rfc5322.parseOneAddress(address);
  return addr;
};

const parseAddressArray = (addresses) => {
  if (!addresses || addresses.length === 0) {
    return [];
  }

  const addressArray = addresses.map((address) => {
    const addr = rfc5322.parseOneAddress(address);
    return addr;
  });

  return addressArray;
};

const parseAddressList = (addresses) => {
  if (!addresses || addresses.length === 0) {
    return [];
  }
  const addressArray = rfc5322.parseAddressList(addresses);
  return addressArray;
};

const validateEmail = (email) => {
  // return email.match(
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // );
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

const isValidEthereumAddress = (address) => {
  return Web3.utils.isAddress(address);
};

const isValidEmailAddress = (address, onlyPlexi = true) => {
  if (!address) {
    return false;
  }

  if (typeof address === "string") {
    const addr = rfc5322.parseOneAddress(address);
    if (!addr) {
      return false;
    }
    if (onlyPlexi) {
      if (!Web3.utils.isAddress(addr.local)) {
        return false;
      }
    }
    return validateEmail(addr.address);
  }
  if (!address.address) {
    return false;
  }

  if (onlyPlexi) {
    if (!Web3.utils.isAddress(address.local)) {
      return false;
    }
  }
  return validateEmail(address.address);
};
const formatAddress = (address) => {
  if (!address.name || address.name.length === 0) {
    return address.address;
  }
  return '"' + address.name + '" <' + address.address + ">";
};

const formatAddresses = (addresses) => {
  const formatedAddresses = addresses.map((address) => {
    return formatAddress(address);
  });

  // const str = formatedAddresses.join(', ');
  return formatedAddresses;
};

const partiesToRFC822Addresses = (parties) => {
  const addresses = [];
  for (const party of parties) {
    if (party.title && party.title.length > 0) {
      const titleInBase64 = window.mailService.base64EncodeSync(party.title);
      const address = `"${party.name}##${titleInBase64}" <${party.email}>`;
      addresses.push(address);
    } else {
      const address = `"${party.name}" <${party.email}>`;
      addresses.push(address);
    }
  }
  return addresses;
};

const partiesToRFC822AddressObjects = (parties) => {
  const addresses = [];
  for (const party of parties) {
    if (!isValidEmailAddress(party.email, false)) {
      continue;
    }
    if (party.title && party.title.length > 0) {
      const titleInBase64 = window.mailService.base64EncodeSync(party.title);
      const address = `"${party.name}##${titleInBase64}" <${party.email}>`;
      const obj = parseOneAddress(address);
      addresses.push(obj);
    } else {
      const address = `"${party.name}" <${party.email}>`;
      const obj = parseOneAddress(address);
      addresses.push(obj);
    }
  }
  return addresses;
};

const rfc822AddressesToParties = (addresses) => {
  const parties = [];
  for (const address of addresses) {
    const components = address.name.split("##");

    const party = {
      // id: parties && parties.length,
      id: window.mailService.generateUUIDSync(),
      name: components[0],
      email: address.address,
      title: "",
    };
    if (components.length === 2) {
      party.title = window.mailService.base64DecodeSync(components[1]);
    }
    parties.push(party);
  }
  return parties;
};

const MailAddressUtils = {
  validateEmail,
  formatAddress,
  formatAddresses,
  parseOneAddress,
  parseAddressArray,
  parseAddressList,
  isValidEmailAddress,
  isValidEthereumAddress,
  partiesToRFC822Addresses,
  partiesToRFC822AddressObjects,
  rfc822AddressesToParties,
};

export default MailAddressUtils;
