// import './App.css';
import React, { useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";

function ClosePlexiMailAlert({ disclosure }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Delete My PlexiMail Account
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>All data produced by this account will be permanently erased from the canisters.</Text>
            {isLoading && <HStack><Spinner size={"sm"} /> <Text>Deleting...</Text></HStack>}
            {errorMessage && <Text color={"red"}>{`${errorMessage}. Please try again later.`}</Text>}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme={"gray"}
              mr={4}
              onClick={() => {
                disclosure.onClose();
              }}
            >
              {"Cancel"}
            </Button>
            <Button
              colorScheme={"red"}
              isLoading={isLoading}
              onClick={() => {
                setErrorMessage(null);
                setLoading(true);
                window.plexiMailService.closeAccount().then((res) => {
                  if (!res) {
                    throw new Error('Failed to close account');
                  }
                  return window.mailService.reset();
                }).then(() => {
                  return window.wallet.reset();
                }).then(() => {
                  setLoading(false);
                  window.forceQuit = true;
                  setTimeout(function () {
                    window.location.reload(true);
                  });
                }).catch(e => {
                  console.error(e);
                  setLoading(false);
                  setErrorMessage(e.message || e.msg || e);
                })
              }}
            >
              {"Close"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
export default ClosePlexiMailAlert;
