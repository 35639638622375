import {
    Text,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    // CheckboxGroup,
    Input,
    FormControl,
    FormLabel,
    Select,
    // TableContainer,
    // IconButton,
  } from "@chakra-ui/react";
  
  import React, { useEffect, useState } from "react";
  
  export default function LLMConfigAlert({ disclosure }) {
    const [apiKey, setAPIKey] = useState(null);
    
    useEffect(() => {
        if (disclosure.isOpen) {
            setAPIKey(localStorage.getItem('ai_api_key'));
        } else {
            setAPIKey("");
        }
    }, [disclosure.isOpen]);
    return (
      <AlertDialog
        // size={""}
        isOpen={disclosure.isOpen}
        onClose={() => {
            setAPIKey("");
            disclosure.onClose()
        }}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                AI Assistant Configuration
            </AlertDialogHeader>
  
            <AlertDialogBody>
              <FormControl mt={4}>
                <FormLabel>Model</FormLabel>
                <Select value={"3"} isDisabled={true}>
                    <option value={"1"}>ChatGPT 4o</option>
                    <option  value={"2"}>Gemini 2.0</option>
                    <option  value={"3"}>DeepSeek V3</option>
                </Select>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>API Key</FormLabel>
                <Input
                  placeholder={"sk-..." }
                  value={apiKey}
                  onChange={(e) => {
                    setAPIKey(e.target.value ? e.target.value : null);
                  }}
                  onKeyDown={(e) => {
  
                    if (e.code === 'Enter') {
                        if (apiKey && apiKey.length > 0) {
                            localStorage.setItem('ai_api_key', apiKey);
                        } else {
                            localStorage.removeItem('ai_api_key');
                        }
                        disclosure.onClose();
                    }
                  }}
                />
              </FormControl>
            </AlertDialogBody>
  
            <AlertDialogFooter>
              <Button
                mr={4}
                colorScheme={"red"}
                onClick={() => {
                    disclosure.onClose();
                }}
              >
                Cancel
              </Button>
  
              <Button
                mr={4}
                colorScheme={"green"}
                onClick={() => {
                    if (apiKey && apiKey.length > 0) {
                        localStorage.setItem('ai_api_key', apiKey);
                    } else {
                        localStorage.removeItem('ai_api_key');
                    }
                    disclosure.onClose();
                }}
              >
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }
  