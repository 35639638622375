/**
 * Networks
 * https://sepolia.dev/
 * 
 * Resources
 * https://www.npmjs.com/package/react-loading-skeleton
 * https://www.npmjs.com/package/react-content-loader
 * https://www.npmjs.com/package/react-spinners 
 * https://www.davidhu.io/react-spinners/
 * https://www.npmjs.com/package/@deskpro/token-field
 * https://www.npmjs.com/package/react-loadable
 * https://www.npmjs.com/package/react-loading
 * https://www.npmjs.com/package/react-loading-icons
 * https://ckeditor.com/ckeditor-5/online-builder/
 * https://github.com/instructure-react/react-tokeninput
 * https://github.com/seawind543/react-token-input
 * https://www.npmjs.com/package/react-customize-token-input
 * https://fkhadra.github.io/react-toastify/addons/use-notification-center
 * 
 * Web Cryptography API Examples
 * https://gist.github.com/pedrouid/b4056fd1f754918ddae86b32cf7d803e
 * 
 * https://wallet-docs.brave.com/ethereum/rpc-api/methods
 * w3 storage path
 * /user_home/Library/Preferences/w3-nodejs
 * https://github.com/sindresorhus/conf
 * https://dnslink.io/#how-does-it-work
 * https://docs.ipfs.tech/concepts/ipfs-gateway/#gateway-providers
 * https://docs.ipfs.tech/concepts/dnslink/
 * https://docs.ipfs.tech/how-to/dnslink-companion/#dns-txt-lookup-policies
 * https://onesignal.com/
 * https://dexie.org/docs/ExportImport/dexie-export-import
 * 
 * Service Worker
 * https://github.com/GoogleChrome/samples/tree/gh-pages/service-worker
 * https://github.com/mdn/serviceworker-cookbook/tree/master/push-simple
 * https://zhuanlan.zhihu.com/p/29932160
 * https://jonny-huang.github.io/angular/training/21_pwa3/
 * 
 * Token Input
 * https://github.com/hc-oss/react-tag-input-component
 * https://github.com/haraldlons/react-tag-input#Demo
 * https://github.com/i-like-robots/react-tags
 * https://github.com/dbslone/react-token
 * https://github.com/amgradetech/react-token-input
 * https://github.com/smclab/react-faceted-token-input 
 * 
 * Watermark
 * http://brianium.github.io/watermarkjs/
 * 
 * Files UI
 * https://www.files-ui.com/
 * 
 *
 * bower install react-tageditor --save
 * packages
 * react-toast-notifications
 * eth-sign-util
 * 
 */
  /*"browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },*/

// import './App.css';
import React, { useState, useEffect/*, useContext, lazy*/} from 'react';

import {
  Box,
  Button, ChakraProvider, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack,
  HStack, Spinner,
  Flex,
  Heading
} from '@chakra-ui/react';


// import MailList from './components/MailList';
import MessageContainer from './components/MessageContainer';

// import HomeScreen from './components/HomeScreen';
import SetupContainer from './components/SetupContainer';
import SidebarWithHeader from './components/SidebarWithHeader';
import Web3StorageConfigScreen from './components/Web3StorageConfigScreen';
import MailPreviewer from './components/MailPreviewer';
import AppConfig from './config/AppConfig';
import MailService from './services/MailService';

import { toast, ToastContainer } from 'react-toastify';
import { ClientError, ServerError, showError/*, showInfo*/ } from './common/errors';
import 'react-toastify/dist/ReactToastify.css';
import {EthereumChains, EthereumFaucets, SetupWizardState, SignalInitStep, PlexiMailStatus} from './utils/Types';
import InitialSignalContextScreen from './components/InitialSignalContextScreen';
import { BACKUP_FOLDER, INBOX_FOLDER, CONTRACT_FOLDER } from './common/constants';
import ExportConfigScreen from './components/ExportConfigScreen';

// import {ReactComponent as BraveLogo} from './components/brave.svg';
import { ChevronDownIcon, CloseIcon, WarningTwoIcon } from '@chakra-ui/icons';
import ConfirmationButton, { ConfirmationMenuItem, ConfirmationModalCloseButton } from './components/ConfirmationButton';
import Strings from './config/Strings'

import WaitForTransactionReceiptScreen from './components/WaitForTransactionReceiptScreen';
import Web3Helper from './common/Web3Helper';
import MasterKeyConfigScreen from './components/MasterKeyConfigScreen';
import SetupMethodSelectorScreen from './components/SetupMethodSelectorScreen';
import MnemonicScreen from './components/MnemonicScreen';

import CopyToClipboard from 'react-copy-to-clipboard';
import BackupMethodSelectorScreen from './components/BackupMethodSelectorScreen';
import RestoreMethodSelectorScreen from './components/RestoreMethodSelectorScreen';
import OneClickResumeScreen from './components/OneClickResumeScreen'
import OneClickCreateScreen from './components/OneClickCreateScreen'
import CouponCodeAlert from './components/CouponCodeAlert';
import BuyEthModal from './components/BuyEthModal';
import { FaEthereum, FaGift, FaGlobeAmericas, FaStore/*, FaWallet*/ } from 'react-icons/fa';
// import AFWallet, { AFWalletProvider } from './wallet/AFWallet';
import AFWallet from './wallet/AFWalletV2';
import WalletUI, { WalletUIStatus } from './wallet/AFWalletUI';
import KickOutAlert from './components/KickOutAlert';
import { W3UpAuthenticateProvider } from './components/w3ui/W3UpAuthenticator';
import { /*AFFaucetButton,*/ AFFaucetMenuItem } from './components/AFFaucetAlert';
import { AiFillMail } from 'react-icons/ai';
// import IndexedDBUnsupportedAlert from './components/IndexedDBUnsupportedAlert'
import W3upStoreIndexedDB from './components/w3ui/W3upStoreIndexedDB';

// import('./services/MailService').then(res => {
//   console.log(res);
// })
import AccountManager from './components/enterprise/AccountManager'
import SearchModal from './components/SearchModal';
import ICPAuthProvider, { useAuth } from './icp/authContext';
const ENABLE_COUPON_CODE = false;
const ENABLE_MULTIPLE_ACCOUNTS = true;
// import parseAddress from './utils/AddressUtils';

// import * as libsignal from '@privacyresearch/libsignal-protocol-typescript';

// import {ToastProvider} from 'react-toast-notifications';

// , {EDIT_MODE_NEW, EDIT_MODE_REPLY, EDIT_MODE_REPLY_ALL, EDIT_MODE_FORWARD}


// import SignalService from './services/SignalService';
// contract address: 0x47560b9C9288B7c967b4dED5d468B69C6eFb12b5
/*
class App extends Component {

  constructor(props) {
    super(props);
    // this.mailService = new MailService();
    this.state = {isConnected: true}
    console.log('App constructor');
  }

  componentDidMount() {
    console.log('App componentDidMount');
  }
  onclick() {
    this.setState({isConnected: false})
  }
  render() {
    return (<Box><Button onClick={() => {this.onclick()}}>Change State</Button> {this.state.isConnected && <Text>Connected</Text>}</Box>)
  }
}
*/
/*
class App extends Component {

    mailService = new MailService();
    constructor() {
      console.log('>> App - constructor')
      super();
      this.state = {
        appUIState: APP_UI_STATES.CONNECT_WALLET,
        accounts: null,
        folders: null,
        messages: null,
        message: null,
      };
    }

    componentDidMount() {
      console.log('>> App - componentDidMount')
    }
    componentDidUpdate() {
      console.log('>> App - componentDidUpdate')
    }
    
    componentWillUnmount() {
      console.log('>> App - componentWillUnmount')
    }
    changeState(state) {
      this.setState(state);
    }
    connectWalletClicked() {

    }
    
    configWeb3StorageClicked() {

    }

    importWeb3StorageConfigClicked() {

    }
    
    render() {
      return (
        <ChakraProvider>
          {(this.state.appUIState === APP_UI_STATES.CONNECT_WALLET) &&
            <HomeScreen appUIState={appUIState} setUIStatue  />
          }
        </ChakraProvider>)
    }
}
*/
const notificationIsEnabled = true;
const activeAccount = localStorage['app.recent-active-account'];
if (activeAccount && activeAccount.length > 0) {
  const chainId = localStorage[`${activeAccount}.app.chain-id`];
  if (!chainId || chainId.length === 0) {
    window.chainId = EthereumChains.Default;
  } else {
    window.chainId = chainId;
  }
} else {
  const chainId = localStorage[`app.recent-chain-id`];
  if (!chainId || chainId.length === 0) {
    window.chainId = EthereumChains.Default;
  } else {
    window.chainId = chainId;
  }
}
if (!window.W3upStoreIndexedDB) {
  window.W3upStoreIndexedDB = W3upStoreIndexedDB;
}
function initialNetwork(newChainId) {
  if (newChainId === EthereumChains.Goerli) {
    Web3Helper.init('goerli');
  } else if (newChainId === EthereumChains.Mainnet) {
    Web3Helper.init('mainnet');
  } else if (newChainId === EthereumChains.Fuji){
    Web3Helper.init('fuji');
  } else if (newChainId === EthereumChains.Avalanche) {
    Web3Helper.init('avalanche');
  } else if (newChainId === EthereumChains.Sepolia) {

    // window.web3Helper.init('sepolia');

    const sepOpts = {
      // rpcUrl: 'https://rpc.sepolia.org', // your ethereum, polygon, or optimism mainnet/testnet rpc URL
      // rpcUrl: 'https://polygon-mumbai-infura.wallet.coinbase.com?targetName=ethereum-sepolia',
      rpcUrl: 'https://eth-sepolia.g.alchemy.com/v2/tApGyKU4AgHcem9l6xFsuMCdVe6mah6P',
      // rpcUrl: 'https://sepolia.rpc.thirdweb.com',
      chainId: 11155111
    }
    Web3Helper.init(sepOpts);
  } else {
    Web3Helper.init('fuji');
  }
  window.web3Helper = Web3Helper
}
// initialNetwork(window.chainId);
// Web3Helper.init(chainId);
// window.web3Helper = Web3Helper
function WalletFaucetModal({disclosure, setupWizardState, selectedAccount, buyDisclosure}) {
  
  const couponDisclosure = useDisclosure();
  const {logout} = useAuth();
  // const [isShowWallet, setShowWallet] = useState(false);
  const buttonTitle = (window.appConfig && window.appConfig.chainId === EthereumChains.Mainnet) ? Strings.app.dialog.faucet.button.buy : Strings.app.dialog.faucet.button.get;
  
  useEffect(() => {
    // setShowWallet(false);
window.accountManager = new AccountManager()
    
  });// eslint-disable-line react-hooks/exhaustive-deps
  
  function reset() {
    window.mailService.reset().then(() => {
      if (logout) {
        return logout().then(() => {
          return window.wallet.reset();
        });
      }
      return window.wallet.reset();
    }).then(() => {
      window.forceQuit = true;
      // window.location.reload();
      setTimeout(function(){
        window.location.reload(true);
      });
    }).catch((e) => {
      console.error(e);
      showError(e);
    });
  }

  return (<Modal size={"xl"} isOpen={disclosure.isOpen} onClose={disclosure.onClose} closeOnOverlayClick={false} scrollBehavior={'inside'}>
  <ModalOverlay />
    <ModalContent>


    <ModalHeader>{Strings.app.dialog.faucet.title}</ModalHeader>
      {/* <ModalCloseButton onClick={(e) => {
        e.preventDefault();
        disclosure.onClose();
        // reset();
      }} /> */}
      {setupWizardState === SetupWizardState.Completed &&
      <ModalCloseButton />
      }

      {setupWizardState !== SetupWizardState.Completed &&
      <ConfirmationModalCloseButton onClick={(e) => {
        disclosure.onClose();
        reset();
      }} />
      }
    <ModalBody>
      <Box align="center"><WarningTwoIcon boxSize={'50px'} color={'orange.300'} /></Box>
      <Text mt={3} fontSize={{ base: 'sm', lg: 'sm' }}>
      {Strings.app.dialog.faucet.content.paragraph1}
      </Text>
      {Strings.app.dialog.faucet.content.paragraph2}
        
      <VStack align="center" mt={3} mb={4}>
        <Text fontSize={{ base: 'sm', lg: 'sm' }}>{selectedAccount}</Text>

      {(window.appConfig.chainId === EthereumChains.Mainnet) &&
        <CopyToClipboard text={selectedAccount} onCopy={() => {
                              toast('Copied');
                          }}>
          <Link fontSize={{ base: 'sm', lg: 'sm' }} alignSelf={"center"} color="#00aaff">{'Click to copy wallet address above'}</Link>
        </CopyToClipboard>
      }
      </VStack>

      <HStack justifyContent={"center"}>
        <Spinner size={"sm"} color={"orange"} />
        <Text color={"orange"}>
        {Strings.common.message.wait_funds}
        </Text>
      </HStack>

      <Text p={1} mt={4} backgroundColor={"gray.100"} fontSize={{ base: 'sm', lg: 'sm' }} color="gray.600">
      <i>{Strings.app.dialog.faucet.content.paragraph3}</i>
      </Text>
    </ModalBody>

    <ModalFooter>
      {/* {window.chainId !== EthereumChains.Sepolia && */}

      {/* } */}

      {/* {(ENABLE_COUPON_CODE && (window.appConfig.chainId === EthereumChains.Goerli)) &&
      <Button mr={3} colorScheme={"orange"} onClick={() => {
        couponDisclosure.onToggle();
      }}>Redeem a Promo Code</Button>
      } */}

    {(ENABLE_COUPON_CODE) &&
      <CouponCodeAlert disclosure={couponDisclosure} onComplete={() => {
        // window.location.reload();
        setTimeout(function(){
          window.location.reload(true);
        });
      }} />
    }
      {/* <Button mr={3} colorScheme={"green"} onClick={() => {
        buyDisclosure.onToggle();
      }}>{buttonTitle}</Button> */}
      {/* <ConfirmationButton buttonTitle={buttonTitle} title={buttonTitle} message={Strings.app.dialog.faucet.message.back} onClick={() => {// ghost
        const url = EthereumFaucets[window.appConfig.chainId];
        toast('Wallet address is copied to the clipboard.');
        
        window.open(url, '_blank');
      }} /> */}
      {window.appConfig.mailAddressNeedToBeVerified &&
        <ConfirmationButton 
          mr={3} 
          cancelable={true} 
          buttonTitle={Strings.app.dialog.address_verify_popup.warning.trigger} 
          enableCheckbox={true} 
          checkboxTitle={Strings.app.dialog.address_verify_popup.warning.confirmation} 
          title={Strings.app.dialog.address_verify_popup.warning.title} 
          message={Strings.app.dialog.address_verify_popup.warning.message} 
          colorScheme={"red"} onClick={() => {
          window.appConfig.mailAddressNeedToBeVerified = false;
          disclosure.onClose();
        }} />
      }
      {(window.appConfig.chainId === EthereumChains.Mainnet) &&

      <>
      {/*
      <Button leftIcon={<FaWallet />} isLoading={isShowWallet} mr={3} onClick={() => {
        setShowWallet(true);
        const type = window.wallet.rpcProviderType();
        if (type === AFWallet.RPCProviderType.AFWallet) {
          window.wallet.showUI().then(() => {
            setShowWallet(false);
          }).catch(e => {
            setShowWallet(false);
            console.error(e);
            if (e.code !== -32603) {
              showError(e);
            }
          });
        } else {
          // if (window.chainId !== EthereumChains.Sepolia) {
          //   return window.web3Helper.magic.wallet.showUI()
          // }
          const err = new Error('Unsupported')
          showError(err);
        }
        // window.web3Helper.magic.wallet.showUI().then(() => {
        // // window.web3Helper.magic.connect.showWallet().then(() => {
        //   setShowWallet(false);
        // }).catch(e => {
        //   setShowWallet(false);
        //   console.error(e);
        //   if (e.code !== -32603) {
        //     showError(e);
        //   }
        // })
      }}>{Strings.app.dialog.faucet.button.show_wallet}</Button>
      */}

      <Menu>
        <MenuButton leftIcon={<FaEthereum />} colorScheme={"green"} as={Button} rightIcon={<ChevronDownIcon />}>
          {Strings.app.dialog.faucet.button.get_coins}
        </MenuButton>
        <MenuList>

        {(ENABLE_COUPON_CODE) &&
          <MenuItem icon={<FaGift />} onClick={() => {
            couponDisclosure.onToggle();
          }}>{Strings.app.dialog.faucet.button.redeem_promo_code}</MenuItem>
        }

        <MenuItem icon={<FaStore />} onClick={() => {
          window.wallet.isBackup().then(isBackup => {
            if (isBackup) {
              buyDisclosure.onToggle();
            } else {
              window.wallet.showBackup(Strings.common.message.wallet_is_backup).then(() => {
                buyDisclosure.onToggle();
              }).catch(e => {
                console.error(e);
                if (e.code !== 4001) {
                  showError(e);
                }
              });
            }
          }).catch(e => {
            console.error(e);
            showError(e);
          })
        }}>{Strings.app.dialog.faucet.button.buy_with_ecash}</MenuItem>

        <ConfirmationMenuItem icon={<FaGlobeAmericas />} cancelable={true} buttonTitle={buttonTitle} title={buttonTitle} message={Strings.app.dialog.faucet.message.back} onClick={() => {
          const gotoExchange = () => {

            const url = EthereumFaucets[window.appConfig.chainId];
            toast('Wallet address is copied to the clipboard.');
            // window.open(url, '_blank');

            let a = document.getElementById('open-eth-exchange-link')
            if (!a) {
              a = window.document.createElement("a");
              a.setAttribute("href", url);
              a.setAttribute("target", '_blank');
              // `PlexiMailPlusPlusConfig_${selectedAccount}.cfg`
              window.document.body.append(a);
            }
            a.click();
          };


          window.wallet.isBackup().then(isBackup => {
            if (isBackup) {
              gotoExchange();
            } else {
              window.wallet.showBackup(Strings.common.message.wallet_is_backup).then(() => {
                gotoExchange();
              }).catch(e => {
                console.error(e);
                showError(e);
              });
            }
          }).catch(e => {
            console.error(e);
            showError(e);
          })

        }}/>

        </MenuList>
      </Menu>
      </>
      }
      {(window.appConfig.chainId !== EthereumChains.Mainnet) &&
      <>
      <Menu>
        <MenuButton leftIcon={<FaEthereum />} colorScheme={"green"} as={Button} rightIcon={<ChevronDownIcon />}>
          {Strings.app.dialog.faucet.button.get_coins}
        </MenuButton>
        <MenuList>
        <MenuItem icon={<FaStore />} onClick={() => {
          window.wallet.isBackup().then(isBackup => {
            if (isBackup) {
              buyDisclosure.onToggle();
            } else {
              window.wallet.showBackup(Strings.common.message.wallet_is_backup).then(() => {
                buyDisclosure.onToggle();
              }).catch(e => {
                console.error(e);
                if (e.code !== 4001) {
                  showError(e);
                }
              });
            }
          }).catch(e => {
            console.error(e);
            showError(e);
          })
        }}>{Strings.app.dialog.faucet.button.buy_with_ecash}</MenuItem>

        <AFFaucetMenuItem icon={<AiFillMail />} title={Strings.app.dialog.faucet.button.email} selectedAccount={selectedAccount} />

        </MenuList>
      </Menu>
      </>
      // <AFFaucetButton icon={<AiFillMail />}  title={buttonTitle} selectedAccount={selectedAccount} />
      }
    </ModalFooter>
    </ModalContent>
  </Modal>)
}

/*
const BraveSwitchModal = ({braveDisclosure}) => {

  return (<Modal isOpen={braveDisclosure.isOpen} onClose={braveDisclosure.onClose} closeOnOverlayClick={false}>
  <ModalOverlay />
    <ModalContent>
    <ModalHeader>Access IPFS Storage Natively</ModalHeader>
    <ModalBody>
        <BraveLogo />
        You are currently using the Brave browser. You can switch to a website customized for Brave browser for a better experience.
    </ModalBody>

    <ModalFooter>
      <Button
        bgColor={"gray.200"}
// bgGradient="linear(to-r, red.400,pink.400)"
        color={'black'} mr={3} onClick={() => {
          braveDisclosure.onClose()
        }}>Cancel</Button>

        <Button colorScheme='green' onClick={() => {
          window.location.href = ('ipns://ai-fi.cc');
        }}>Switch</Button>
    </ModalFooter>
    </ModalContent>
  </Modal>);
};
*/
function Error({title, content}) {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={'red.500'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign="center">
          <CloseIcon boxSize={'20px'} color={'white'} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
      {title}
      </Heading>
      <Text color={'gray.500'} mb={6}>
        {content}
      </Text>


      <Button
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
        variant="solid"
        onClick={() => {
          window.location.reload();
        }}>
        Refresh
      </Button>
    </Box>
  )
}

let kStopCheckingBalancePeriodically = false;
function App() {
  
  const {logout} = useAuth();
  const toastId = React.useRef(null);
  const [accounts, setAccounts] = useState()
  const [selectedAccount, setSelectedAccount] = useState()
  const [setupWizardState, setSetupWizardState] = useState(SetupWizardState.ConnectWallet);
  const [folders, setFolders] = useState(null);
  // const [currentFolder, setCurrentFolder] = useState(INBOX_FOLDER);
  const [messages, setMessages] = useState();
  const [message, setMessage] = useState();
  const [isGettingFolders, setGettingFolders] = useState(false);
  // const [isLocked, setLocked] = useState(true);
  const [isVerified, setVerified] = useState(window.appConfig.mailAddressNeedToBeVerified)
  const [folderVersion, setFolderVersion] = useState(1);

  const buyDisclosure = useDisclosure();
  const faucetDisclosure = useDisclosure({defaultIsOpen: false, onOpen: () => {
    if (setupWizardState !== SetupWizardState.Completed) {
     
      startCheckingBalance();
    }
    // showBackupIfNeeded();
  }, onClose: () => {
    stopCheckingBalance();
  }});
  const kickOutDisclosure = useDisclosure();
  const searchDisclosure = useDisclosure();

  const checkBalance = async () => {
    
    const web3 = window.web3Helper.web3;
    const balance = await web3.eth.getBalance(window.wallet.address);
    console.log('balance: ', balance)
    return balance;
  };

  /*
  function showBackupIfNeeded() {
    if (window.wallet && window.wallet.asDefaultWallet) {
      window.wallet.isBackup().then(isBackup => {
        if (!isBackup) {
          window.wallet.showUI(WalletUIStatus.Backup).then(() => {
            
          }).catch(e => {
            console.error(e);
            showError(e);
          });
        } else {
        }
      }).catch(e => {
        console.error(e);
        showError(e);
      })
    }
  }
  */
  
  const stopCheckingBalance = () => {
    kStopCheckingBalancePeriodically = true;
  };
  const startCheckingBalance = () => {
    kStopCheckingBalancePeriodically = false; 
    checkBalancePeriodically();
  };
  const checkBalancePeriodically = () => {
    const MinimumBalanceRequirement = localStorage["MinimumBalanceRequirement"] || '1000000000000000';
    const MinimalRequiredBalance = parseInt(MinimumBalanceRequirement) || 1000000000000000;
    
    checkBalance().then((balance) => {
      
      if (!isVerified) {
        faucetDisclosure.onClose();
        return;
      }
      if (balance > MinimalRequiredBalance) {
        if (!kStopCheckingBalancePeriodically) {
          faucetDisclosure.onClose();

          // if (window.wallet) {
          //   window.wallet.isBackup().then(isBackup => {
          //     if (isBackup) {
          //       faucetDisclosure.onClose();
          //     } else {
          //       window.wallet.showUI(WalletUIStatus.Backup).then(() => {
          //         faucetDisclosure.onClose();
                  
          //       }).catch(e => {
          //         console.error(e);
          //         showError(e);
          //       });
          //     }
          //   }).catch(e => {
          //     console.error(e);
          //     showError(e);
          //   })
          // } else {
          //   faucetDisclosure.onClose();
          // }
        }
      } else {
        if (!kStopCheckingBalancePeriodically) {
          setTimeout(() => {
            checkBalancePeriodically();
          }, 10000);
        }
      }

    }).catch(e => {
      console.error(e);
      if (!kStopCheckingBalancePeriodically) {
        setTimeout(() => {
          checkBalancePeriodically();
        }, 10000);
      }
    });
  };

  const walletDisclosure = useDisclosure({defaultIsOpen: false});
  // const indexedDBDisclosure = useDisclosure({defaultIsOpen: (!window.torInfo || !window.torInfo.isIndexedDBSupported) });
  const [walletStatus, setWalletStatus] = useState(WalletUIStatus.Choose);
  const [walletVersion, setWalletVersion] = useState(0);

  if (!window.AFWallet) {
    window.AFWallet = AFWallet;
  }

  // if (!window.AFWalletV2) {
  //   window.AFWalletV2 = AFWalletV2.default;
  // }
  if (!window.wallet) {
    // const wallet = new AFWallet(walletDisclosure, setWalletStatus, setWalletVersion);
    const wallet = new AFWallet(walletDisclosure, setWalletStatus, setWalletVersion);
    window.wallet = wallet;
    initialNetwork(window.chainId);

    if (ENABLE_MULTIPLE_ACCOUNTS && window.wallet.rpcProvider) {
      window.wallet.rpcProvider.on('accountsChanged', function (accounts) {
        // const newSelectedAddress = (window.ethereum && window.ethereum.selectedAddress) ? window.ethereum.selectedAddress.toLowerCase() : '';
        const newSelectedAddress = (accounts.length>0 ? accounts[0] : '').toLowerCase();
        console.log('switched to: ', newSelectedAddress, ', from: ', window.appConfig.recentActiveAccount);
        if (!window.appConfig.recentActiveAccount || window.appConfig.recentActiveAccount === '' || newSelectedAddress === '') {
          return;
        }
        if (newSelectedAddress !== window.appConfig.recentActiveAccount) {
          window.appConfig.recentActiveAccount = newSelectedAddress;
          // window.location.reload();

          setTimeout(function(){
            const idx = window.location.href.indexOf('?');
            if (idx !== -1) {
              window.location.href = window.location.href.substring(0, idx);
            } else {
              window.location.reload(true);
            }
          });
        }
        // setSelectedAccount(selectedAddress);
      });
    }
  } else {
    window.wallet.disclosure = walletDisclosure;
    window.wallet.setStatus = setWalletStatus;
    window.wallet.setVersion = setWalletVersion;
  }

  const setCurrentFolder = (folder) => {
    console.log('switch folder to ', folder);
    window.mailService.currentFolder = folder;
    if (folder === CONTRACT_FOLDER) {
      updateContractFolder();
    }
    // setCurrentFolder(folder);
  }
  
  const getCurrentFolder = () => {
    return window.mailService ? window.mailService.currentFolder || INBOX_FOLDER : INBOX_FOLDER;
  }

  if (!window.appConfig) {
    window.appConfig = new AppConfig();

    // Web3Helper.init().then(() => {

      
    // });


    window.addEventListener('focus', (e) => {
      // changeNetwork().then(() => {
      //   // console.log('network changed');
      // }).catch(e => {
      //   console.error(e);
      //   showError(e);
      // });
    });

    window.addEventListener('blur', (e) => {
    });
    document.addEventListener("visibilitychange", (e) => {
      //console.log('document.visibilityState', document.visibilityState, e);
    });
  }

  if (!window.mailService) {
    window.mailService = new MailService();
    // parseAddress();
  }

  const setSetupWizardStateWrapper = (state) => {
    window.appConfig.setupWizardState = state;
    setSetupWizardState(state);
  }

  useEffect(() => {

    console.log('App - componentDidMount', Strings.title)
    // Your code here
    console.log(' appUIState:', setupWizardState);

    if (window.appConfig.setupWizardState === SetupWizardState.ConnectWallet) {

    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    console.log('App - componentDidUpdate', setupWizardState, selectedAccount)
    
    if (setupWizardState === SetupWizardState.ConnectWallet) {
      const isConnected = Boolean(accounts);
      // console.log(' isConnected:', isConnected);
      //const isConnected = window.appConfig.isPushApiTokenConfigured;
      if (isConnected) {
        // if (window.appConfig.setupWizardState === SetupWizardState.ConnectWallet) {
        //   setSetupWizardStateWrapper(SetupWizardState.ConfigWeb3Storage);
        // } else {
        //   setSetupWizardState(window.appConfig.setupWizardState);
        // }
      }
    } else if (setupWizardState === SetupWizardState.ConfigWeb3Storage) {
  
    } else if (setupWizardState === SetupWizardState.ConfigSignal) {
      
    } else if (setupWizardState === SetupWizardState.Completed) {
      if (!window.appConfig.privacyLevelIsNormal) {
        window.forceQuit = false;
      }
      if (window.wallet && window.wallet.asDefaultWallet) {
        window.wallet.isBackup().then(isBackup => {
          if (!isBackup) {
            return window.wallet.showUI(WalletUIStatus.Backup);
          }
        }).then(() => {
        }).catch(e => {
          console.error(e);
          showError(e);
        })
      }
      if (!isGettingFolders && !folders) {
        setGettingFolders(true);
        getFolders().then(()=>{
          if (notificationIsEnabled && typeof Notification !== 'undefined') {
            Notification.requestPermission(function (permission) {
              console.log(permission);
            });
          }
          setGettingFolders(false);
        });
      } else {
        console.log('**************** ')
      }

      /*
      const isConnected = Boolean(accounts);
      if (isConnected) {
        if (!isGettingFolders && !folders) {
          setGettingFolders(true);
          getFolders().then(()=>{
            Notification.requestPermission(function (permission) {
              console.log(permission);
            });
            setGettingFolders(false);
          });
        } else {
          console.log('**************** ')
        }
      } else {
        setSetupWizardState(SetupWizardState.ConnectWallet);
      }
      */
    }
  }, [accounts, setupWizardState]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      console.log('App - componentWillUnmount')
    }
    // Your code here
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  
  function showNotification() {

    if (!notificationIsEnabled || typeof Notification === 'undefined') {
      return;
    }

    Notification.requestPermission().then((result) => {
      if (result === "granted") {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(Strings.app.message.new_message, {
            body: "",
            // icon: "../images/touch/chrome-touch-icon-192x192.png",
            // vibrate: [200, 100, 200, 100, 200, 100, 200],
            // tag: "vibration-sample",
          });
        });
      }
    });
  }
  
  // function showNotification() {
  //   if (!notificationIsEnabled) {
  //     return;
  //   }
  //   if (typeof Notification === 'undefined') {
  //     return;
  //   }
  //   var instance = new Notification(
  //     Strings.app.message.new_message, 
  //     {
  //       body: ""
  //     }
  //   );
    
  //   instance.onclick = function () {
  //   // Something to do
  //   };
  //   instance.onerror = function () {
  //   // Something to do
  //   };
  //   instance.onshow = function () {
  //   // Something to do
  //   };
  //   instance.onclose = function () {
  //   // Something to do
  //   };
  // }

  function kickOut() {
    kickOutDisclosure.onOpen();
  }
  function setFolderVersionWithDebug(version) {
    setFolderVersion((prev) => {
      return prev + 1;
    })
    console.log('**** updateContractFolder: old=', folderVersion, ', new=', version)
    // setFolderVersion(version);
  }
  function updateContractFolder() {
    let newVersion = folderVersion + 1
    setFolderVersionWithDebug(newVersion);
  }
  const [criticalError, setCriticalError] = useState(null);

  function handleNetworkError(e) {
    setCriticalError({title: "Network Error", content: "There seems to be a connection problem. Please check your network and try refreshing the page to continue."})
    
  }
  async function getFolders(retries = 5) {
    try {
      /*
      const registrationId = libsignal.KeyHelper.generateRegistrationId();
      const identityKeyPair = await libsignal.KeyHelper.generateIdentityKeyPair();
      const preKey = await libsignal.KeyHelper.generatePreKey(1);
      const signedPreKey = await libsignal.KeyHelper.generateSignedPreKey(identityKeyPair, 2);
      console.log('registrationId: ', registrationId, ', identityKeyPair: ', identityKeyPair);
      */
     if (!toastId.current) {
        const toastID = toast.loading(Strings.app.message.prepare_signal, {
          position: "bottom-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          pauseOnFocusLoss: false,
          style: {backgroundColor: '#ddd', color: '#000'}
        });
        toastId.current = toastID;
      }
      await window.mailService.initSignalService(selectedAccount, (step) => {
          switch(step) {
            case SignalInitStep.CheckPreKeyCount:
              if (toastId.current) {
                toast.update(toastId.current, {
                  type: 'info',
                  render: Strings.app.message.check_prekeys
                });
              }
              // `One of: 'info', 'success', 'warning', 'error', 'default'`
              break;
            case SignalInitStep.GenerateKeys:
              if (toastId.current) {
                toast.update(toastId.current, {
                  type: 'info',
                  render: Strings.app.message.generate_prekeys
                });
              }
              break;
            case SignalInitStep.SavePreKeyBundle:
              if (toastId.current) {
                toast.update(toastId.current, {
                  type: 'info',
                  render: Strings.app.message.save_prekeys
                });
              }
              break;
            case SignalInitStep.SaveIdentityKey:
              break;
            // case SignalInitStep.WaitTransactionReceipt:
            //   break;
            case SignalInitStep.SaveSignalProtocolStore:
              break;  
            case SignalInitStep.Completed:
              setVerified(window.appConfig.mailAddressNeedToBeVerified);
              break;
            default:
              break;
        }
      });
      if (toastId.current) {
        toast.update(toastId.current, {
          type: 'info',
          render: Strings.app.message.load_contact
        });
      }
      
      await window.mailService.loadContacts();

      setVerified(window.appConfig.mailAddressNeedToBeVerified);
      if (toastId.current) {
        toast.update(toastId.current, {
          type: 'info',
          render: Strings.app.message.load_messages
        });
      }
      let existedFolders = await window.mailService.getFolders();
      const uiState = await window.mailService.getPlexiMailUIState();
      if (uiState) {
        setCurrentFolder(uiState.currentFolder);
      }
      existedFolders = {...existedFolders};
      delete existedFolders[BACKUP_FOLDER];
      console.log(existedFolders);
      setFolders(existedFolders);

      setMessages([]);
      if (getCurrentFolder() === CONTRACT_FOLDER) {
        setTimeout(() => {
          updateContractFolder();
        }, 10);
      } else {
        const messages = Object.values(existedFolders[getCurrentFolder()].messages).sort((a, b) => { 
          // return ((new Date(b.sendDate)).getTime() - (new Date(a.sendDate)).getTime());
          return (b.sendDate - a.sendDate);
        }).filter((message) => {
          return !window.mailService.messageIsDeleted(message.uid);
        });
        // setMessages(existedFolders[getCurrentFolder()].messages);
        setTimeout(() => {
          setMessages([...messages]);
        }, 10);
      }
      // console.log(globalThis);
      if (toastId.current) {
        toast.update(toastId.current, {
          type: 'success',
          style: null,
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          isLoading: false,
          render: Strings.app.message.ready
        });
        const toastID = toastId.current;
        setTimeout(()=> {
          toast.dismiss(toastID); 
        }, 3000);
      }

      // if (toastID) {
      //   setTimeout(()=> {
      //     toast.dismiss(toastID); 
      //   }, 3000);
      // }

      window.mailService.startNewMessageListener(selectedAccount, (folders, error) => {
        if (error) {
          showError(error);
          return;
        }

        // if (folders) {
        //   console.log('new message coming...')
        //   console.log(folders);
        //   setFolders(folders);
        // }

        // if (getCurrentFolder() === INBOX_FOLDER) {
          if (folders) {
            // toast.success(Strings.app.message.new_message, {
            //   position: "bottom-right",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });

            showNotification();
            console.log(getCurrentFolder(), ': new message coming...')
            // console.log(folders);
            folders = {...folders};
            delete folders[BACKUP_FOLDER];
            setFolders(folders);

            // const keys1 = Object.keys(existedFolders)
            // let keys2 = Object.keys(folders);
            // if (keys1.length === keys2.length) {
            //   for(const k1 of keys1) {
            //     keys2 = keys2.filter(k2 => {
            //       return k2 !== k1
            //     })
            //   }

            //   if (keys2.length !== 0) {
            //     setFolders(folders);
            //   }
            // } else {
            //   setFolders(folders);
            // }

            // setMessages([]);

            // //setFolders([...folders]);
            
            setMessages([]);

            if (getCurrentFolder() === CONTRACT_FOLDER) {
              // setTimeout(() => {
              //   updateContractFolder();
              // }, 1000);
              updateContractFolder();
            } else {
              const messages = Object.values(existedFolders[getCurrentFolder()].messages).sort((a, b) => { 
                // return ((new Date(b.sendDate)).getTime() - (new Date(a.sendDate)).getTime());
                return (b.sendDate - a.sendDate);
              }).filter((message) => {
                return !window.mailService.messageIsDeleted(message.uid);
              });
              // console.log('filtered: ', messages);
              setTimeout(() => {
                setMessages([...messages]);
              }, 10);
            }
            // setMessages([...folders[INBOX_FOLDER].messages]);
          }
        // }
      }, () => {
        window.mailService.reset(true).then(() => {
          if (logout) {
            return logout().then(() => {
              return window.wallet.reset();
            });
          }

          return window.wallet.reset(true);
        }).then(() => {
          kickOut();
        }).catch((e) => {
          console.error(e);
          showError(e);
        });
      }, (e) => {
        // showError(e);
        console.error('Network Error: ', e);
        if (e && e.response && e.response.status === 400) {
          alert('Authentication is expired!');
          window.location.reload();
          return;
        }
      });
      toastId.current = null;
    } catch(e) {
      console.error('Failed to initialize mailService', e);
      if (e && e.constructor === ClientError && e.code === ClientError.CODE_NETWORK) {
        if (--retries === 0) {
          if (toastId.current) {
            toast.dismiss(toastId.current)
          }
          handleNetworkError(e);
          return;
        }
        const {promise, resolve} = Promise.withResolvers();
        setTimeout(() => {
          resolve();
        }, 1000)
        await promise;
        await getFolders(retries);
        return;
      }

      toastId.current = null;
      if (e && e.code === ServerError.CODE_NOT_FOUND) {
        
        window.mailService.reset(true).then(() => {
          if (logout) {
            return logout().then(() => {
              return window.wallet.reset();
            });
          }

          return window.wallet.reset(true);
        }).then(() => {
          kickOut();
        }).catch((e) => {
          console.error(e);
          showError(e);
        });
      } else {
        showError(e);
      }
    }
  }
  
  // const { isOpen, onOpen, onClose } = useDisclosure()
  // const btnRef = React.useRef()

  return (
    // <ToastProvider>
    // </ToastProvider>
    <ChakraProvider>
    {/* <ICPAuthProvider> */}
      {/* <AFWalletProvider value={window.wallet}> */}
      {/* {(!window.torInfo || !window.torInfo.isIndexedDBSupported) &&
        <IndexedDBUnsupportedAlert disclosure={indexedDBDisclosure} />
      } */}
      {/* {window.torInfo && window.torInfo.isIndexedDBSupported && */}
      {criticalError &&
        <Error title={criticalError && criticalError.title} content={criticalError && criticalError.content} />
      }
      <BuyEthModal selectedAccount={selectedAccount} disclosure={buyDisclosure} />
      <WalletUI disclosure={walletDisclosure} version={walletVersion} status={walletStatus} onBuy={() => {
        buyDisclosure.onOpen();
      }} />
      <ToastContainer theme='colored' />

      <WalletFaucetModal setupWizardState={setupWizardState} disclosure={faucetDisclosure} buyDisclosure={buyDisclosure} selectedAccount={selectedAccount} />
      <KickOutAlert disclosure={kickOutDisclosure} />
      {(setupWizardState === SetupWizardState.ConnectWallet) && 
        <SetupContainer faucetDisclosure={faucetDisclosure} setSetupWizardState={setSetupWizardState} setAccounts={setAccounts} setSelectedAccount={setSelectedAccount} />
        // <HomeScreen faucetDisclosure={faucetDisclosure} setSetupWizardState={setSetupWizardState} setAccounts={setAccounts} setSelectedAccount={setSelectedAccount} />
      }
      {(setupWizardState === SetupWizardState.SelectSetupMethod) &&
        <>
        {window.appConfig.plexiMailStatus === PlexiMailStatus.WaitForOneClickRecovery &&
          <OneClickResumeScreen selectedAccount={selectedAccount} setSetupWizardState={setSetupWizardStateWrapper} />
        }
        {window.appConfig.plexiMailStatus === PlexiMailStatus.WaitForOneClickNew &&
        <OneClickCreateScreen setSetupWizardState={setSetupWizardStateWrapper} setupWizardState={setupWizardState} selectedAccount={selectedAccount} setAccounts={setAccounts} setSelectedAccount={setSelectedAccount} />
        }

        {(window.appConfig.plexiMailStatus === PlexiMailStatus.WaitForRecovery || window.appConfig.plexiMailStatus === PlexiMailStatus.WaitForNew) &&
        <SetupMethodSelectorScreen setSetupWizardState={setSetupWizardStateWrapper} />
        }
        {/* {(!window.isOneClickResume) &&
          <SetupMethodSelectorScreen setSetupWizardState={setSetupWizardStateWrapper} />
        } */}
        </>
      }
      {(setupWizardState === SetupWizardState.SelectBackupMethod) &&
        <BackupMethodSelectorScreen setSetupWizardState={setSetupWizardStateWrapper} />
      }
      {(setupWizardState === SetupWizardState.SelectRecoveryMethod) &&
        <RestoreMethodSelectorScreen setSetupWizardState={setSetupWizardStateWrapper} />
      }



      {((setupWizardState === SetupWizardState.ConfigPassword) || (setupWizardState === SetupWizardState.EnterPassword)) &&
        <MasterKeyConfigScreen setSetupWizardState={setSetupWizardStateWrapper} setupWizardState={setupWizardState} selectedAccount={selectedAccount} />
      }

      {((setupWizardState === SetupWizardState.GenerateMnemonic) || (setupWizardState === SetupWizardState.RestoreMnemonic)) &&
        <MnemonicScreen setSetupWizardState={setSetupWizardStateWrapper} setupWizardState={setupWizardState} selectedAccount={selectedAccount} />
      }
      
      {((setupWizardState === SetupWizardState.ConfigWeb3Storage) || (setupWizardState === SetupWizardState.RestoreWeb3Storage)) && 
      <>
      {window.appConfig.plexiMailStatus === PlexiMailStatus.WaitForOneClickRecovery &&
        (<OneClickResumeScreen setSetupWizardState={setSetupWizardStateWrapper} setupWizardState={setupWizardState} selectedAccount={selectedAccount} />)
      }
      {window.appConfig.plexiMailStatus === PlexiMailStatus.WaitForOneClickNew &&
        (<OneClickCreateScreen setSetupWizardState={setSetupWizardStateWrapper} setupWizardState={setupWizardState} selectedAccount={selectedAccount} setAccounts={setAccounts} setSelectedAccount={setSelectedAccount} />)
      }

      {(window.appConfig.plexiMailStatus === PlexiMailStatus.WaitForRecovery || window.appConfig.plexiMailStatus === PlexiMailStatus.WaitForNew) &&
        (<Web3StorageConfigScreen setSetupWizardState={setSetupWizardStateWrapper} setupWizardState={setupWizardState} folders={folders} setFolders={setFolders} selectedAccount={selectedAccount} />)
      }
      </>
      }
      
      {(setupWizardState === SetupWizardState.ConfigSignal) &&

      // <MyAgentLoader>
        <W3UpAuthenticateProvider>
          <InitialSignalContextScreen setSetupWizardState={setSetupWizardStateWrapper} selectedAccount={selectedAccount} faucetDisclosure={faucetDisclosure} />
        </W3UpAuthenticateProvider>

      // </MyAgentLoader>
      }
      
      {(setupWizardState === SetupWizardState.ExportConfig) &&
        <ExportConfigScreen setSetupWizardState={setSetupWizardStateWrapper} selectedAccount={selectedAccount} />
      }
      {(setupWizardState === SetupWizardState.WaitForTransactionReceipt) &&
        <WaitForTransactionReceiptScreen setSetupWizardState={setSetupWizardStateWrapper} selectedAccount={selectedAccount} />
      }
      
      {/* {(setupWizardState === SetupWizardState.Completed && isLocked) &&
        <LockScreen onUnlock={() => {
          setLocked(false);
        }} />
      } */}
      {(setupWizardState === SetupWizardState.Completed) &&

      <W3UpAuthenticateProvider>
        <SearchModal disclosure={searchDisclosure} />
        {folders &&
        <SidebarWithHeader folderVersion={folderVersion} setSetupWizardState={setSetupWizardState} verified={isVerified} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} buyDisclosure={buyDisclosure} folders={folders} setFolders={setFolders} setCurrentFolder={setCurrentFolder} setMessages={setMessages} setMessage={setMessage} >{/* faucetDisclosure={faucetDisclosure} composeDisclosure={composeDisclosure} */}
            {/* <MailEditor accounts={accounts} setFolders={setFolders} /> composeDisclosure={composeDisclosure} } */}
          <MailPreviewer selectedAccount={selectedAccount} setFolders={setFolders} setMessages={setMessages} message={message} setMessage={setMessage} /> {/*composeDisclosure={composeDisclosure}  */}
          <MessageContainer searchDisclosure={searchDisclosure} folderVersion={folderVersion} setFolderVersion={setFolderVersionWithDebug} selectedAccount={selectedAccount} currentFolder={getCurrentFolder()} folders={folders} setFolders={setFolders} messages={messages} message={message} setMessage={setMessage} />

        </SidebarWithHeader>
        }
      </W3UpAuthenticateProvider>
      }
      {/* <BraveSwitchModal braveDisclosure={braveDisclosure} /> */}
      {/* </AFWalletProvider> */}
      {/* } */}
      {/* </ICPAuthProvider> */}
    </ChakraProvider>
  )
}


export default App;
