// import './App.css';

import React, { useEffect } from "react";
import {
  Avatar,
  HStack,
  VStack,
  Text,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Switch,
  Button,
  Center,
  Heading,
  // Switch
} from "@chakra-ui/react";

// import appConfig from '../config/AppConfig';
import { showError, showSuccess } from "../common/errors";
import { FiLock, FiTrash, FiDatabase, FiPocket, FiX } from "react-icons/fi";
import Strings from "../config/Strings";
import { MnemonicDisplayListItem } from "./Mnemonic";
import { PrivateKeyDisplayListItem } from "./PrivateKey";
import CouponCodeAlert from "./CouponCodeAlert";
import { WalletUIStatus } from "../wallet/AFWalletUI";
import { UpgradeListItem } from "./RunModeSwitcherModal";
import { GoUnverified, GoVerified, GoLock } from "react-icons/go";
import SharedStorageManagementModal from "./SharedStorageManagementModal";
import ClosePlexiMailAlert from './ClosePlexiMailAlert';
import LLMConfigAlert from "./LLMConfigAlert";

// import { useToasts } from 'react-toast-notifications'

const SettingList = ({ selectedAccount, mode, buyDisclosure }) => {
  const couponDisclosure = useDisclosure();
  const sharedStorageDisclosure = useDisclosure();
  const closeAccountDisclosure = useDisclosure();
  const llmConfigDisclosure = useDisclosure();
  
  const S = Strings.settings.list;
  /*
  const saveConfig = (configJsonString) => {
    let blob=new Blob([configJsonString]); 
    let url = URL.createObjectURL(blob);
    let a = window.document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", filename());// `PlexiMailPlusPlusConfig_${selectedAccount}.cfg`
    window.document.body.append(a);
    a.click();
    window.window.URL.revokeObjectURL(url);
    a.remove();
  }

  const filename = () => {
    const now = new Date();
    let timeString = now.getYear() + 1900 + '-';
    if (now.getMonth()>=9) { 
        timeString += (1 + now.getMonth());
    } else { 
        timeString += ('0' + (1 + now.getMonth()));
    }
    timeString += ('-' + ((now.getDate() > 9) ? now.getDate() : ('0' + now.getDate())));
    timeString += ('_' + ((now.getHours() > 9) ? now.getHours() : ('0' + now.getHours())));
    timeString += ('_' + ((now.getMinutes() > 9) ? now.getMinutes() : ('0' + now.getMinutes())));
    timeString += ('_' + ((now.getSeconds() > 9) ? now.getSeconds() : ('0' + now.getSeconds())));
    return `PMCFG_${selectedAccount}_${timeString}.pmcfg`;
  }
  
  async function getNewFileHandle() {
    const opts = {
      suggestedName: filename(),
      types: [{
        description: 'PlexiMail Config File',
        accept: {'text/plain': ['.pmcfg']},
      }],
    };
    return await window.showSaveFilePicker(opts);
  }
  const saveConfig1 = async (configJsonString) => {
      const fileHandler = await getNewFileHandle();
      const writable = await fileHandler.createWritable();
      await writable.write(configJsonString)
      await writable.close();
  }
  const exportConfig = async () => {
    try {
      const configJsonString = await window.mailService.exportConfig(selectedAccount);
      // const configJsonString = await window.mailService.exportMnemonic(selectedAccount);
      await saveConfig(configJsonString);
    } catch (e) {
      console.error(e);
      showError(e);
    }
  }

  const exportConfig1 = () => {
    window.mailService.exportConfig(selectedAccount).then((configJsonString) => {
    // window.mailService.exportMnemonic(selectedAccount).then((configJsonString) => {
      saveConfig(configJsonString);
    }).catch(e => {
      console.error(e);
      showError(e);
    });
  }

  const showMnemonic = () => {
    
  }
  */

  const clearCacheStorage = () => {
    window.mailService
      .clearCacheStorage()
      .then((success) => {
        showSuccess(Strings.settings.message.cache_cleard);
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };
  const changeWeb3StorageAPIToken = () => {
    window.wallet
      .showUI(WalletUIStatus.ChangeWeb3StorageAPIToken)
      .then(() => {})
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };
  // const redeemPromoCode = () => {
  //   couponDisclosure.onToggle();
  // }
  const changePINCode = () => {
    // changePINCodeDisclosure.onOpen();
    window.wallet
      .showUI(WalletUIStatus.ChangePINCode)
      .then(() => {})
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };

  const manageShareStorage = () => {
    sharedStorageDisclosure.onOpen();
  };
  // const backupWallet = () => {

  //   window.mailService.resetSignalService().then(() => {
  //     return window.mailService.initSignalService(selectedAccount, null);
  //   }).then(() => {
  //     return window.wallet.showBackup(Strings.common.message.wallet_is_backup);
  //   }).catch(e => {
  //     console.error(e);
  //     showError(e);
  //   });;
  // }

  const closePlexiMail = () => {
    closeAccountDisclosure.onOpen();
  }
  const setAPIKey = () => {
    llmConfigDisclosure.onOpen();
  };
  return (
    <>
      {/* <ChangePINCodeAlert disclosure={changePINCodeDisclosure} /> */}
      <CouponCodeAlert disclosure={couponDisclosure} />
      <SharedStorageManagementModal disclosure={sharedStorageDisclosure} />
      <ClosePlexiMailAlert disclosure={closeAccountDisclosure} />
      <LLMConfigAlert disclosure={llmConfigDisclosure} />
      <List>
        {(mode === 0 || mode === 2) && (
          <>
            <MnemonicDisplayListItem
              title={S.mnemonic.title}
              description={S.mnemonic.description}
            />
            <PrivateKeyDisplayListItem
              title={S.privateKeys.title}
              description={S.privateKeys.description}
            />
            <ListItem
              hidden={true}
              onClick={changeWeb3StorageAPIToken}
              cursor={"pointer"}
            >
              <HStack
                borderBottom={"1px solid lightGray"}
                pt={"4px"}
                pb={"4px"}
              >
                <Avatar
                  icon={<FiDatabase />}
                  background={"none"}
                  textColor={"gray"}
                />
                <VStack align={"left"}>
                  <Text fontSize={14} color="black">
                    {S.change_w3s_api_token.title}
                  </Text>
                  {S.change_w3s_api_token.description &&
                    S.change_w3s_api_token.description.length > 0 && (
                      <Text fontSize={10}>
                        {S.change_w3s_api_token.description}
                      </Text>
                    )}
                </VStack>
              </HStack>
            </ListItem>

            <ListItem cursor={"pointer"}>
              <HStack
                borderBottom={"1px solid lightGray"}
                pt={"4px"}
                pb={"4px"}
              >
                <Avatar
                  icon={<FiPocket />}
                  background={"none"}
                  textColor={"gray"}
                />
                <VStack align={"left"}>
                  {/* <Text fontWeight={"bold"}>Export Config</Text> */}
                  <Text fontSize={14} color="black">
                    {S.verify_sender.title}
                  </Text>
                  {S.verify_sender.description &&
                    S.verify_sender.description.length > 0 && (
                      <Text fontSize={10}>{S.verify_sender.description}</Text>
                    )}
                </VStack>

                <Switch
                  size="sm"
                  defaultChecked={window.appConfig.mailSenderNeedToBeVerified}
                  onChange={(e) => {
                    console.log(e);
                    window.appConfig.mailSenderNeedToBeVerified =
                      e.target.checked || false;
                  }}
                />
              </HStack>
            </ListItem>

            {!window.appConfig.mailAddressNeedToBeVerified && (
              <UpgradeListItem
                onChainIdChanged={(chainId) => {
                  // setChainId(chainId)
                }}
                selectedAccount={selectedAccount}
                buyDisclosure={buyDisclosure}
              >
                <HStack
                  borderBottom={"1px solid lightGray"}
                  pt={"4px"}
                  pb={"4px"}
                >
                  <Avatar
                    icon={<FiLock />}
                    background={"none"}
                    textColor={"gray"}
                  />
                  <VStack align={"left"}>
                    {/* <Text fontWeight={"bold"}>Export Config</Text> */}
                    <Text fontSize={14} color="black">
                      {S.upgrade.title}
                    </Text>
                    {S.upgrade.description &&
                      S.upgrade.description.length > 0 && (
                        <Text fontSize={10}>{S.upgrade.description}</Text>
                      )}
                  </VStack>
                </HStack>
              </UpgradeListItem>
            )}
          </>
        )}
        {(mode === 1 || mode === 2) && (
          <>
            {/* <ListItem hidden={true} onClick={changeWeb3StorageAPIToken}  cursor={'pointer'}> */}

            {/* <ListItem cursor={"pointer"}>
              <HStack
                borderBottom={"1px solid lightGray"}
                pt={"4px"}
                pb={"4px"}
              >
                <Avatar
                  icon={<FiDatabase />}
                  background={"none"}
                  textColor={"gray"}
                />
                <VStack align={"left"}>
                  <Text fontSize={14} color="black">
                    {"Web3 Storage Account"}
                  </Text>
                  <Text fontSize={10}>
                    {window.appConfig && window.appConfig.defaultWeb3Storage}
                  </Text>
                </VStack>
              </HStack>
            </ListItem>

            <ListItem onClick={manageShareStorage} cursor={"pointer"}>
              <HStack
                borderBottom={"1px solid lightGray"}
                pt={"4px"}
                pb={"4px"}
              >
                <Avatar
                  icon={<FiDatabase />}
                  background={"none"}
                  textColor={"gray"}
                />
                <VStack align={"left"}>
                  <Text fontSize={14} color="black">
                    {"Shared Storage Management"}
                  </Text>
                  <Text fontSize={10}>
                    {"Revoke authorization for access to your web.storage"}
                  </Text>
                </VStack>
              </HStack>
            </ListItem> */}

            <ListItem onClick={setAPIKey} cursor={"pointer"}>
              <HStack
                borderBottom={"1px solid lightGray"}
                pt={"4px"}
                pb={"4px"}
              >
                <Avatar
                  icon={<FiTrash />}
                  background={"none"}
                  textColor={"gray"}
                />
                <VStack align={"left"}>
                  {/* <Text fontWeight={"bold"}>Export Config</Text> */}
                  <Text fontSize={14} color="black">
                    {"Setup AI Assistant"}
                  </Text>
                  <Text fontSize={10}>{"Can help you draft contracts and emails"}</Text>
                </VStack>
              </HStack>
            </ListItem>

            <ListItem onClick={clearCacheStorage} cursor={"pointer"}>
              <HStack
                borderBottom={"1px solid lightGray"}
                pt={"4px"}
                pb={"4px"}
              >
                <Avatar
                  icon={<FiTrash />}
                  background={"none"}
                  textColor={"gray"}
                />
                <VStack align={"left"}>
                  {/* <Text fontWeight={"bold"}>Export Config</Text> */}
                  <Text fontSize={14} color="black">
                    {S.clear.title}
                  </Text>
                  {S.clear.description && S.clear.description.length > 0 && (
                    <Text fontSize={10}>{S.clear.description}</Text>
                  )}
                </VStack>
              </HStack>
            </ListItem>

            {window.wallet &&
              window.wallet.asDefaultWallet &&
              window.appConfig.privacyLevelIsNormal && (
                <ListItem onClick={changePINCode} cursor={"pointer"}>
                  <HStack
                    borderBottom={"1px solid lightGray"}
                    pt={"4px"}
                    pb={"4px"}
                  >
                    <Avatar
                      icon={<FiLock />}
                      background={"none"}
                      textColor={"gray"}
                    />
                    <VStack align={"left"}>
                      {/* <Text fontWeight={"bold"}>Export Config</Text> */}
                      <Text fontSize={14} color="black">
                        {S.change_pin.title}
                      </Text>
                      {S.change_pin.description &&
                        S.change_pin.description.length > 0 && (
                          <Text fontSize={10}>{S.change_pin.description}</Text>
                        )}
                    </VStack>
                  </HStack>
                </ListItem>
              )}

              <ListItem onClick={closePlexiMail} color={'red.600'} fontWeight={'bold'} cursor={"pointer"}>
                <HStack
                  borderBottom={"1px solid lightGray"}
                  pt={"4px"}
                  pb={"4px"}
                >
                  <Avatar
                    icon={<FiX />}
                    background={"none"}
                    textColor={"red.600"}
                  />
                  <VStack align={"left"}>
                    {/* <Text fontWeight={"bold"}>Export Config</Text> */}
                    <Text fontSize={14}>
                      {S.close.title}
                    </Text>
                    {S.close.description && S.close.description.length > 0 && (
                      <Text fontSize={10}>{S.close.description}</Text>
                    )}
                  </VStack>
                </HStack>
              </ListItem>
          </>
        )}
        {/* <ListItem onClick={backupWallet}  cursor={'pointer'}>
        <HStack borderBottom={"1px solid lightGray"} pt={"4px"} pb={"4px"}>
            <Avatar icon={<FiUpload />} background={"none"} textColor={"gray"} />
            <VStack align={"left"}>
                <Text fontSize={14} color="black">{S.backup_wallet.title}</Text>
                {(S.backup_wallet.description && S.backup_wallet.description.length > 0) &&
                <Text fontSize={10}>{S.backup_wallet.description}</Text>
                }
            </VStack>
        </HStack>
      </ListItem> */}
        {/* <ListItem onClick={() => {
        redeemPromoCode();
      }}  cursor={'pointer'}>
        <HStack borderBottom={"1px solid lightGray"} pt={"4px"} pb={"4px"}>
            <Avatar icon={<FiGift />} background={"none"} textColor={"gray"} />
            <VStack align={"left"}>
                <Text fontSize={14} color="black">{"Redeem Promo Code"}</Text>
                {(S.clear.description && S.clear.description.length > 0) &&
                <Text fontSize={10}>{S.clear.description}</Text>
                }
            </VStack>
        </HStack>
      </ListItem> */}

        {/* <ListItem cursor={'pointer'}>
        <HStack borderBottom={"1px solid lightGray"} pt={"4px"} pb={"4px"}>
            <Avatar icon={<FiTrash />} background={"none"} textColor={"gray"} />
            <VStack align={"left"}>
                <ImportModalButton mt={0}  title={"Import"} selectedAccount={selectedAccount} onCompleted={() => {
                  console.log("imported");
                }} />
            </VStack>
        </HStack>
      </ListItem> */}

        {/* {chainId !== EthereumChains.Avalanche && */}
      </List>
    </>
  );
};

const SettingsModal = ({
  selectedAccount,
  mode, // 0 user, 1 global
  verified,
  buyDisclosure,
  composeDisclosure,
}) => {
  // const { addToast } = useToasts()
  useEffect(() => {
    console.log("ContactModal useEffect with empty");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log("ContactModal useEffect without param");
  }); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {}, [composeDisclosure]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      size={"xl"}
      isOpen={composeDisclosure.isOpen}
      onClose={composeDisclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 0 ? Strings.settings.account_title : Strings.settings.title}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <VStack>
            <Heading>PlexiMail</Heading>
            <Text>1.0.3 (2025012001)</Text>
          </VStack>
          <HStack
            height={10}
            mb={5}
            mt={4}
            border={"1px solid orange"}
            borderRadius={5}
            justify={"space-around"}
          >
            {/* <Text>Unverified</Text> */}
            {mode === 0 && (
              <>
                {!verified && (
                  <Button
                    h={"20px"}
                    p={2}
                    border={"none"}
                    variant="outline"
                    leftIcon={<GoUnverified size={12} />}
                    fontSize={12}
                    colorScheme={"orange"}
                  >
                    {Strings.app.mode.unverified}
                  </Button>
                )}
                {verified && (
                  <Button
                    h={"20px"}
                    p={2}
                    border={"none"}
                    variant="outline"
                    leftIcon={<GoVerified size={12} />}
                    fontSize={12}
                    colorScheme={"green"}
                  >
                    {Strings.app.mode.verified}
                  </Button>
                )}
              </>
            )}
            {mode === 1 && (
              <>
                {!window.appConfig.privacyLevelIsNormal && (
                  <Button
                    h={"20px"}
                    p={2}
                    border={"none"}
                    variant="outline"
                    leftIcon={<GoLock size={12} />}
                    fontSize={12}
                    colorScheme={"green"}
                  >
                    {Strings.app.privacy_level.high}
                  </Button>
                )}
                {window.appConfig.privacyLevelIsNormal && (
                  <Button
                    h={"20px"}
                    p={2}
                    border={"none"}
                    variant="outline"
                    leftIcon={<GoLock size={12} />}
                    fontSize={12}
                    colorScheme={"orange"}
                  >
                    {Strings.app.privacy_level.standard}
                  </Button>
                )}
              </>
            )}
          </HStack>

          <SettingList
            mode={mode}
            selectedAccount={selectedAccount}
            buyDisclosure={buyDisclosure}
          />
        </ModalBody>

        <ModalFooter>
          {/* <Button colorScheme='red' mr={3} onClick={composeDisclosure.onClose}>Close</Button> */}
          {/* <Button colorScheme='green' isLoading={isSending} onClick={sendMessage} disabled={isUploading}>Send</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;
