import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Link,
  Spinner,
  HStack,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import {
  RecoveryState,
  SetupWizardState,
  PlexiMailStatus,
  StorageProvider,
} from "../utils/Types";
import Strings from "../config/Strings";
// import { HELP_DOC_URL } from '../common/constants';
// import { TutorialModalLink } from './TutorialModalTrigger';
import { showError } from "../common/errors";

// import MailAddressUtils from '../utils/MailAddressUtils';
// import { ConfirmationCloseButton } from './ConfirmationButton';
// import jwt from 'jsonwebtoken';

import { BACKUP_FOLDER } from "../common/constants";

export default function OneClickResumeScreen({
  selectedAccount,
  setupWizardState,
  setSetupWizardState,
}) {
  const [recoveryState, setRecoveryState] = useState(
    RecoveryState.ReadyToRecovery
  );
  // const [isRunning, setRunning] = useState(false);
  const [isAuthenticating, setAuthenticating] = useState(false);
  const [resendMessage, setResentMessage] = useState(null);

  const [isInitializing, setInitializing] = useState(false);
  const [createSpaceStep, setCreateSpaceStep] = useState(1);

  useEffect(() => {
    resume();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resume = async () => {
    if (window.appConfig.storageProvider === StorageProvider.LightHouse) {
      await resumeWithLighthouse();
    } else {
      await resumeWithW3UI();
    }
  };

  const resumeWithLighthouse = async () => {

    if (!window.mailService.isRecovering) {
      try {
        window.mailService.isRecovering = true;
        await lighthouseAuthenticate();
        await lighthouseResumeWeb3StorageConfig();
      } catch (e) {
        console.error(e);
        //   console.error(e);
        //   showError(e);
        //   setInitializing(false);
        //   console.error(e);
        showError(e);
      } finally {
        window.mailService.isRecovering = false;
        setAuthenticating(false);
        setInitializing(false);
        setRecoveryState(RecoveryState.ReadyToRecovery);
      }
    }
  };

  async function lighthouseAuthenticate() {
    
    setAuthenticating(true);

    setResentMessage('Authenticating...');
    const client = await window.mailService.web3StorageClient.loadAgent();
    console.log("w3up client:", client);


    await window.mailService.web3StorageClient.authenticate(
      window.appConfig.web3StorageApiToken
    );
    
    setAuthenticating(false);

    // try {
    //   setAuthenticating(true);

    //   setResentMessage('Authenticating...');
    //   const client = await window.mailService.web3StorageClient.loadAgent();
    //   console.log("w3up client:", client);


    //   await window.mailService.web3StorageClient.authenticate(
    //     window.appConfig.web3StorageApiToken
    //   );
      
    //   setAuthenticating(false);

    // } catch (err) {
    //   console.error(err);
    //   console.log("failed to register: ", err.message || err);
    //   throw err;
    // } finally {
    //   setAuthenticating(false);
    // }
  }

  async function lighthouseResumeWeb3StorageConfig() {
    await doW3UIResumeWeb3StorageConfig();
    // try {
    //   await doW3UIResumeWeb3StorageConfig();
    // } catch (e) {
    //   console.error(e);
    //   showError(e);
    //   setInitializing(false);
    //   console.error(e);
    // }
  }




  const resumeWithW3UI = async () => {
    if (!window.mailService.isRecovering) {
      try {
        window.mailService.isRecovering = true;
        await w3UIAuthenticate();
        await w3UIResumeWeb3StorageConfig();
      } catch (e) {
        showError(e);
      } finally {
        window.mailService.isRecovering = false;
        setAuthenticating(false);
        setInitializing(false);
      }
    }
  };

  async function doW3UIResumeWeb3StorageConfig() {
    setInitializing(true);
    await window.mailService.initWeb3StorageClient(
      window.appConfig.web3StorageApiToken
    );
    setRecoveryState(RecoveryState.RetrieveFolders);
    const folders = await window.mailService.getFolders();

    setRecoveryState(RecoveryState.RecoverySignal);
    const backup = folders[BACKUP_FOLDER];
    Object.keys(backup.localStorage).forEach(function (key) {
      if (key.lastIndexOf("app.private-level") !== -1) {
        return;
      }
      if (!window.appConfig.privacyLevelIsNormal) {
        if (key.lastIndexOf(".app.setup-wizard-state") !== -1) {
          return;
        }
      }
      localStorage.setItem(key, backup.localStorage[key]);
    });

    if (!window.appConfig.privacyLevelIsNormal) {
      window.appConfig.setupWizardState =
        backup.localStorage[
          window.appConfig.recentActiveAccount + ".app.setup-wizard-state"
        ];
    }

    await window.mailService.importIndexedDB(backup.indexedDB);

    await window.mailService.initSignalService(selectedAccount);
    setInitializing(false);
    setRecoveryState(RecoveryState.Completed);
    // setSetupWizardState(SetupWizardState.WaitForTransactionReceipt);
    // delete window.isOneClickResume;

    await gotoMain();
  }

  async function w3UIResumeWeb3StorageConfig() {
    try {
      await doW3UIResumeWeb3StorageConfig();
    } catch (e) {
      console.error(e);
      showError(e);
      setInitializing(false);
      console.error(e);
    }
  }

  async function w3UIAuthenticate() {
    let interval = -1;
    let counter = 0;
    try {
      setAuthenticating(true);
      setCreateSpaceStep(1);

      const client = await window.mailService.web3StorageClient.loadAgent();
      console.log("w3up client:", client);

      const isAuthenticated =
        await window.mailService.web3StorageClient.isAuthenticated();
      if (isAuthenticated) {
        const isSpaceRegistered =
          window.mailService.web3StorageClient.isSpaceRegistered();
        if (!isSpaceRegistered) {
          const spaces = window.mailService.web3StorageClient.spaces();
          if (!spaces || spaces.length === 0) {
            const space =
              await window.mailService.web3StorageClient.createSpace(
                selectedAccount,
                (step, msg) => {
                  if (step !== 1 && interval > 0) {
                    clearInterval(interval);
                    interval = -1;
                  }
                  setCreateSpaceStep(step);
                  setResentMessage(msg);
                }
              );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          } else {
            const space =
              window.mailService.web3StorageClient.getSpaceByName(
                selectedAccount
              ) || spaces[0];
            await window.mailService.web3StorageClient.checkSpace(
              space,
              (step, msg) => {
                if (step !== 1 && interval > 0) {
                  clearInterval(interval);
                  interval = -1;
                }
                setCreateSpaceStep(step);
                setResentMessage(msg);
              }
            );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          }
        }

        setAuthenticating(false);
        return;
      }

      setResentMessage(
        `${Strings.web3.w3ui.verify.message_3} ${60 - counter} ${
          Strings.web3.w3ui.verify.message_4
        }`
      );
      interval = setInterval(() => {
        counter += 5;
        if (counter >= 60) {
          clearInterval(interval);
          setResentMessage(
            <>
              <Link
                href="#"
                fontWeight={"bold"}
                color={"orange"}
                textDecoration={"underline"}
                onClick={() => {
                  resend();
                  return false;
                }}
              >
                {Strings.web3.w3ui.verify.message_5}
              </Link>
              &nbsp;{Strings.web3.w3ui.verify.message_6}
            </>
          );
        } else {
          setResentMessage(
            `${Strings.web3.w3ui.verify.message_3} ${60 - counter} ${
              Strings.web3.w3ui.verify.message_4
            }`
          );
        }
      }, 5000);

      await window.mailService.web3StorageClient.authenticate(
        window.appConfig.web3StorageApiToken
      );

      const isSpaceRegistered =
        window.mailService.web3StorageClient.isSpaceRegistered();
      if (!isSpaceRegistered) {
        const spaces = window.mailService.web3StorageClient.spaces();
        if (!spaces || spaces.length === 0) {
          const space = await window.mailService.web3StorageClient.createSpace(
            selectedAccount,
            (step, msg) => {
              if (step !== 1 && interval > 0) {
                clearInterval(interval);
                interval = -1;
              }
              setCreateSpaceStep(step);
              setResentMessage(msg);
            }
          );
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
        } else {
          const space =
            window.mailService.web3StorageClient.getSpaceByName(
              selectedAccount
            ) || spaces[0];
          await window.mailService.web3StorageClient.checkSpace(
            space,
            (step, msg) => {
              if (step !== 1 && interval > 0) {
                clearInterval(interval);
                interval = -1;
              }
              setCreateSpaceStep(step);
              setResentMessage(msg);
            }
          );
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
        }
      }
    } catch (err) {
      console.error(err);
      console.log("failed to register: ", err.message || err);
      throw err;
      // if (err.message === 'No space selected') {
      //     try {
      //         await actions.createSpace('pleximail')
      //         await actions.registerSpace(window.appConfig.web3StorageApiToken)
      //     } catch (err) {
      //         throw new Error('failed to register', { cause: err })
      //     }
      // } else if (err.message === 'Space already registered with web3.storage.') {
      //     console.log(err.message)
      // } else if (err.message.indexOf('no proofs available for resource') !== -1) {
      //     console.error(err);
      // } else {
      //     throw new Error('failed to register', { cause: err })
      // }
    } finally {
      if (interval !== -1) {
        clearTimeout(interval);
        interval = -1;
      }
      setAuthenticating(false);
    }
  }

  const reset = () => {
    window.mailService
      .reset(true)
      .then(() => {
        return window.wallet.reset(true);
      })
      .then(() => {
        setSetupWizardState(SetupWizardState.ConnectWallet);
        window.appConfig.setupWizardState = SetupWizardState.ConnectWallet;
        window.forceQuit = true;
        setTimeout(function () {
          window.location.reload(true);
        });
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };

  function backClicked() {
    reset();
  }

  const resend = () => {
    setResentMessage(null);
    setTimeout(() => {
      resumeWithW3UI()
        .then(() => {})
        .catch((e) => {
          console.error(e);
          showError(e);
        });
    }, 300);
  };

  const gotoMain = async () => {
    // if (window.appConfig.setupWizardState === SetupWizardState.Completed) {
    //     return;
    // }

    delete window.isOneClickResume;

    window.appConfig.removeTempMasterKey();
    window.appConfig.removeTempMnemonic();
    window.appConfig.removeTxHash();
    window.appConfig.removeCouponInfo();
    window.appConfig.removeTempCryptonInfo();
    window.appConfig.removeSetupFromRecovery();
    window.appConfig.isNewCreatedUser = false;
    window.appConfig.plexiMailStatus = PlexiMailStatus.Ready;

    window.wallet.removePinFromSessionStorageIfNeeded();

    setSetupWizardState(SetupWizardState.Completed);
    window.isUnlocked = true;

    setSetupWizardState(SetupWizardState.Completed);

    await window.mailService.syncFoldersFile();
  };
  // const retryClicked = () => {

  // }

  function progress() {
    switch (recoveryState) {
      case RecoveryState.ReadyToRecovery:
        return Strings.web3.import.message.ready;
      case RecoveryState.RecoveryWeb3Storage:
        return Strings.web3.import.message.recover_web3;
      case RecoveryState.RetrieveFolders:
        return Strings.web3.import.message.retrieve_folder;
      case RecoveryState.RecoverySignal:
        return Strings.web3.import.message.recover_signal;
      case RecoveryState.Completed:
        return Strings.web3.import.message.completed;
      default:
        return null;
    }
  }

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              {/* <Text
                            as={'span'}
                            bgGradient="linear(to-r, red.400, pink.400)"
                            bgClip="text">
                                {Strings.wait.default.step} &nbsp;
                        </Text>  */}
              {Strings.one_click_resume.title}
            </Heading>
            {/* <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                            {Strings.one_click_resume.description}
                        </Text>
                        <Text mt={5} color={'orange'} fontSize={{ base: 'sm', sm: 'md' }}>
                            {Strings.one_click_resume.note}
                        </Text> */}

            {recoveryState !== RecoveryState.ReadyToRecovery && (
              <HStack>
                <Spinner color={"orange"} size={"sm"} />
                <Text color={"orange"} fontSize={{ base: "sm", sm: "md" }}>
                  {progress()}
                </Text>
              </HStack>
            )}

            {isAuthenticating && (
              <>
                <HStack>
                  <Spinner color={"#00aaff"} size={"sm"} />
                  <Text
                    color={"#00aaff"}
                    fontWeight={"bold"}
                    fontSize={{ base: "lg", sm: "lg" }}
                  >
                    {Strings.web3.w3ui.verify.title}
                  </Text>
                </HStack>
                {createSpaceStep === 1 && (
                  <Text color={"#00aaff"} fontSize={{ base: "sm", sm: "sm" }}>
                    {Strings.web3.w3ui.verify.message_1}&nbsp;
                    {window.appConfig.web3StorageApiToken}&nbsp;
                    {Strings.web3.w3ui.verify.message_2}&nbsp;{resendMessage}
                  </Text>
                )}
                {createSpaceStep !== 1 && (
                  <Text color={"#00aaff"} fontSize={{ base: "sm", sm: "sm" }}>
                    {resendMessage}
                  </Text>
                )}
              </>
            )}
          </Stack>
          <Box as={"form"} mt={10}>
            {/* <Button
                            fontFamily={'heading'}
                            mt={8}
                            w={'full'}
                            bgGradient="linear(to-r, red.400,pink.400)"
                            color={'white'}
                            _hover={{
                                bgGradient: 'linear(to-r, red.400,pink.400)',
                                boxShadow: 'xl',
                            }}
                            disabled={isRunning}
                            onClick={retryClicked}>
                            Retry
                        </Button> */}

            <Button
              fontFamily={"heading"}
              mt={4}
              w={"full"}
              bgColor={"gray.200"}
              isDisabled={isAuthenticating || isInitializing}
              // bgGradient="linear(to-r, red.400,pink.400)"
              color={"black"}
              // _hover={{
              //     bgGradient: 'linear(to-r, red.400,pink.400)',
              //     boxShadow: 'xl',
              // }}
              onClick={() => {
                backClicked();
              }}
            >
              {Strings.one_click_resume.button.back}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
