import {
    Text,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    // CheckboxGroup,
    Input,
    FormControl,
    FormLabel,
    OrderedList,
    ListItem,
    UnorderedList,
    // TableContainer,
    // IconButton,
  } from "@chakra-ui/react";
  
  import React, { useEffect, useState } from "react";
  // import Strings from '../../config/Strings';
  
  
  export default function ContractIntroAlert({ onCompleted, disclosure }) {
    return (
      <AlertDialog
        // size={""}
        size={"lg"}
        
        isOpen={disclosure.isOpen}
        closeOnOverlayClick={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Signature Round
            </AlertDialogHeader>
  
            <AlertDialogBody fontSize={"sm"}>
              <Text fontWeight={"bold"}>
                Ready to initiate the Signature Round to execute the agreement:
              </Text>
  
              <Text>
              <br />
              {"This wizard simplifies initiating the signature round for your agreement with all participants. As the moderator, you can use the provided example cover letter to guide them through the signing process. Additionally, you can leverage the \"Templates\" button at the end to save, select, or create your own cover letter from your collections. The cover letter is customizable! Personalize it by filling in or removing the bracketed placeholders (e.g., {{Client Name}}) or merge fields as appropriate."}
              </Text>
  
              <Text fontWeight={"bold"}><br />Here's what you'll need to do:</Text><br />
              <OrderedList>
                <ListItem>
                  <Text>
                    <strong>Provide Participant Information: </strong>Since
                    everyone involved is currently unidentified, please enter the
                    necessary contact details for each participant. This will
                    include some Personally Identifiable Information (PII) like
                    names and email addresses.
                  </Text>
                </ListItem>
                <ListItem>
  
                <Text>
                    <strong>2-Factor Authentication (Optional): </strong>For added
                    security, PlexiSign can assist with sending 2FA codes to
                    participants' mobile phones or email addresses if you choose
                    to enable it.
                  </Text>
                </ListItem>
  
                <ListItem>
  
                <Text>
                    <strong>Attach all contract materials individually </strong> using the "Attach" button for their final review and signature.
                  </Text>
                </ListItem>
  
              </OrderedList>
  
              <Text fontWeight={"bold"}>
              <br />Benefits of Participant Information:
              </Text><br />
  
              <UnorderedList>
                <ListItem>
                  <Text>
                    <strong>Faster Signing Process: </strong>Having accurate
                    contact information ensures everyone receives the signing
                    notification promptly.
                  </Text>
                </ListItem>
                <ListItem>
  
                <Text>
                    <strong>Enhanced Security: </strong>2FA adds an extra layer of
                    protection during the signing process.
                  </Text>
                </ListItem>
              </UnorderedList>
  
              <Text fontWeight={"bold"}><br />Let's Get Started!</Text>
            </AlertDialogBody>
  
            <AlertDialogFooter>
              <Button
                mr={4}
                colorScheme={"green"}
                onClick={() => {
                  onCompleted(false);
                  disclosure.onClose();
                }}
              >
                Next
              </Button>
  
              <Button
                mr={4}
                colorScheme={"red"}
                onClick={() => {
                  onCompleted(true);
                  disclosure.onClose();
                }}
              >
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }
  